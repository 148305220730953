<template>
  <div class="onboarding-bar-dev-wrapper">
    <OnboardingBar :onBoardPercentage="onBoardPercentage"/>
  </div>

  <div class="row mt-4">
    <h2>{{onBoardPercentage}}</h2>
    <div class="col">
      <button class="btn btn-light me-4" @click="subtract10()">- 20</button>
      <button class="btn btn-light me-4" @click="add10()">+ 20</button>
    </div>
  </div>
</template>
<script>

import OnboardingBar from './OnboardingBar';

export default {
  name: 'OnboardingBarDev',
  components: { OnboardingBar },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      onBoardPercentage: 100,
    };
  },
  emits: ['click'],
  methods: {
    subtract10() {
      this.onBoardPercentage -= 20;
    },
    add10() {
      this.onBoardPercentage += 20;
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
