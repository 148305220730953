import {
  USER_POSITION_CONSULTANT,
  USER_POSITION_EU_PARTNER,
  USER_POSITION_EU_POLICY_OFFICER,
  USER_POSITION_NATIONAL_GOVERNMENT,
  USER_POSITION_OTHER,
  USER_POSITION_RESEARCHER,
  USER_POSITION_STUDENT,
  USER_POSITION_VC_ACTOR,
} from '@/enum/userEnums/UserPosition';
import {
  USER_ORIGIN_AFRICA,
  USER_ORIGIN_AMERICA,
  USER_ORIGIN_ASIA, USER_ORIGIN_EUROPE, USER_ORIGIN_OCEANIA,
} from '@/enum/userEnums/UserOrigin';
import {
  SCROLL_BACKGROUND_DEFAULT,
  SCROLL_BACKGROUND_PHASE_1,
  SCROLL_BACKGROUND_PHASE_2,
  SCROLL_BACKGROUND_PHASE_3,
  SCROLL_BACKGROUND_PHASE_4,
  SCROLL_BACKGROUND_PHASE_5,
} from '@/views/introViews/021-InfoScrollView';

import missionTextKeys from './missionTextKeys.json';

export default [
  {
    type: 'languageSelect',
    background: 'blue-gondwana',
    transition: 'short',
    data: {},
    bottomButtons: [],
  },
  {
    type: 'startView',
    background: 'gondwana',
    transition: 'short',
    data: {},
    bottomButtons: [
      {
        text: 'misc.start',
        type: 'progressFlow',
      },
    ],
  },
  {
    type: 'textInputView',
    background: 'blue-gondwana',
    conditionalContinue: true,
    transition: 'short',
    data: {
      title: 'introFlow.1.data.title',
      text: 'introFlow.1.data.text',
      extraText: 'introFlow.1.data.extraText',
      inputData: [
        {
          inputHint: 'introFlow.1.data.inputData.0.inputHint',
          inputPlaceholder: 'introFlow.1.data.inputData.0.inputPlaceholder',
          inputStoreId: 'playerName',
          required: true,
          class: 'col-4',
        },
        {
          inputHint: 'introFlow.1.data.inputData.1.inputHint',
          inputPlaceholder: 'introFlow.1.data.inputData.1.inputPlaceholder',
          inputStoreId: 'playerSurname',
          required: true,
          class: 'col-8',
        },
        {
          inputHint: 'introFlow.1.data.inputData.2.inputHint',
          inputPlaceholder: 'introFlow.1.data.inputData.1.inputPlaceholder',
          inputStoreId: 'playerPosition',
          required: true,
          class: 'col-12',
          options: [
            USER_POSITION_EU_POLICY_OFFICER,
            USER_POSITION_NATIONAL_GOVERNMENT,
            USER_POSITION_RESEARCHER,
            USER_POSITION_EU_PARTNER,
            USER_POSITION_VC_ACTOR,
            USER_POSITION_CONSULTANT,
            USER_POSITION_STUDENT,
            USER_POSITION_OTHER,
          ],
        },
        {
          inputHint: 'introFlow.1.data.inputData.3.inputHint',
          inputPlaceholder: 'introFlow.1.data.inputData.3.inputPlaceholder',
          inputStoreId: 'playerLocation',
          required: true,
          class: 'col-12',
          options: [
            USER_ORIGIN_AFRICA,
            USER_ORIGIN_AMERICA,
            USER_ORIGIN_ASIA,
            USER_ORIGIN_EUROPE,
            USER_ORIGIN_OCEANIA,
          ],
        },
      ],
    },
    bottomButtons: [
      {
        text: 'misc.continue',
        type: 'progressFlow',
        seriousAnalytics: 'startApp',
      },
    ],
  },
  {
    type: 'infoView',
    background: 'blue-gondwana',
    transition: 'short',
    data: {
      title: 'introFlow.2.data.title',
      text: 'introFlow.2.data.text',
    },
    bottomButtons: [
      {
        text: 'misc.continue',
        type: 'progressFlow',
      },
    ],
  },
  {
    type: 'infoView',
    background: 'blue-gondwana',
    transition: 'short',
    data: {
      title: 'introFlow.3.data.title',
      text: 'introFlow.3.data.text',
    },
    bottomButtons: [
      {
        text: 'introFlow.3.bottomButtons.0.text',
        type: 'document',
        data: 'VCA4DIntro',
      },
      {
        text: 'introFlow.3.bottomButtons.1.text',
        type: 'progressFlow',
      },
    ],
  },
  {
    type: 'infoScrollView',
    background: 'phase-1',
    transition: 'short',
    conditionalContinue: true,
    data: {
      content: [
        {
          title: missionTextKeys['0'].title,
          text: missionTextKeys['0'].text,
          icon: null,
          background: SCROLL_BACKGROUND_DEFAULT,
        },
        {
          title: missionTextKeys['1'].title,
          text: missionTextKeys['1'].text,
          icon: 'ui/document1',
          background: SCROLL_BACKGROUND_PHASE_1,
        },
        {
          title: missionTextKeys['2'].title,
          text: missionTextKeys['2'].text,
          icon: 'ui/dataToKnowledgeVault',
          background: SCROLL_BACKGROUND_PHASE_2,
        },
        {
          title: missionTextKeys['3'].title,
          text: missionTextKeys['3'].text,
          background: SCROLL_BACKGROUND_PHASE_3,
          icon: null,
        },
        {
          title: missionTextKeys['4'].title,
          text: missionTextKeys['4'].text,
          icon: 'ui/transformData',
          background: SCROLL_BACKGROUND_PHASE_4,
        },
        {
          title: missionTextKeys['5'].title,
          text: missionTextKeys['5'].text,
          icon: null,
          background: SCROLL_BACKGROUND_PHASE_5,
        },
        {
          title: missionTextKeys['6'].title,
          text: missionTextKeys['6'].text,
          icon: null,
          background: SCROLL_BACKGROUND_PHASE_5,
        },
      ],
    },
    bottomButtons: [
      {
        text: 'misc.continue',
        type: 'progressFlow',
      },
    ],
  },
  {
    type: 'infoView',
    background: 'dark-gondwana',
    transition: 'short',
    data: {
      title: 'introFlow.5.data.title',
      text: 'introFlow.5.data.text',
    },
    bottomButtons: [
      {
        text: 'misc.start',
        type: 'progressFlow',
      },
    ],
  },
  {
    type: 'blackTransitionView',
    background: 'dark-gondwana',
    transition: 'short',
    data: null,
    bottomButtons: [],
  },
  {
    type: 'situationView',
    background: 'plane',
    transition: 'short',
    data: {
      text: 'introFlow.7.data.text',
    },
    bottomButtons: [
      {
        text: 'introFlow.7.bottomButtons.0.text',
        type: 'progressFlow',
      },
    ],
  },
  {
    type: 'emailView',
    background: 'plane',
    transition: 'short',
    conditionalContinue: true,
    data: {
      title: 'introFlow.8.data.title',
      toFromField: {
        from: 'introFlow.8.data.toFromField.from',
        to: 'misc.emailToMe',
        when: 'misc.emailDate',
      },
      emailText: 'introFlow.8.data.emailText',
      senderInfo: 'introFlow.8.data.senderInfo',
      attachments: [
        {
          title: 'introFlow.8.data.attachments.0.title',
          documentId: 'newsFeed',
        },
        {
          title: 'introFlow.8.data.attachments.1.title',
          documentId: 'gondwanaPresentation',
        },
      ],
    },
    bottomButtons: [
      {
        text: 'introFlow.8.bottomButtons.0.text',
        type: 'progressFlow',
      },
    ],
  },
  {
    type: 'emailView',
    background: 'plane',
    transition: 'short',
    data: {
      title: 'introFlow.9.data.title',
      toFromField: {
        from: 'introFlow.9.data.toFromField.from',
        to: 'misc.emailToMe',
        when: 'misc.emailDate',
      },
      emailText: 'introFlow.9.data.emailText',
      senderInfo: 'introFlow.9.data.senderInfo',
      attachments: [],
    },
    bottomButtons: [
      {
        text: 'misc.continue',
        type: 'progressFlow',
      },
    ],
  },
  {
    type: 'blackTransitionView',
    background: 'plane',
    transition: 'short',
    data: null,
    bottomButtons: [],
  },
  {
    type: 'situationView',
    background: 'office',
    transition: 'short',
    data: {
      text: 'introFlow.11.data.text',
    },
    bottomButtons: [
      {
        text: 'misc.continue',
        type: 'progressFlow',
      },
    ],
  },
  {
    type: 'situationView',
    background: 'office',
    transition: 'short',
    data: {
      icon: 'incomingCall',
      text: 'introFlow.12.data.text',
    },
    bottomButtons: [
      {
        text: 'misc.pickUpPhone',
        type: 'progressFlow',
      },
    ],
  },
  {
    type: 'phoneConversationView',
    background: 'office',
    conditionalContinue: true,
    transition: 'black',
    data: {
      phoneBackground: 'phone_bg_chantall.png',
      conversationId: 'INTRO1',
    },
    bottomButtons: [
      {
        isHidden: true,
        text: 'misc.continue',
        type: 'progressFlow',
      },
    ],
  },
  {
    type: 'blackTransitionView',
    background: 'office',
    transition: 'short',
    data: null,
    bottomButtons: [],
  },
  {
    type: 'situationView',
    background: 'office',
    transition: 'short',
    data: {
      text: 'introFlow.15.data.text',
    },
    bottomButtons: [
      {
        text: 'misc.start',
        type: 'progressFlow',
      },
    ],
  },
];
