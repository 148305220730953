<template>
  <div class="email-view-container" :class="showEmail ? '' : 'hidden'">
    <EmailModule :title="title"
                 :email-text="emailText"
                 :is-news="isNews"
                 :to-from-data="toFromData"
                 :sender-info="senderInfo"
                 :attachments="attachments"
                 :attachments-seen="attachmentsOpened"
                 @openAttachment="openAttachment"/>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import EmailModule from '@/components/common/EmailModule';

export default {
  name: 'EmailView',
  components: { EmailModule },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      attachmentsOpened: {},
      pageCompletedSent: false,
      showEmail: false,
    };
  },
  emits: ['pageCompleted', 'openDocument'],
  mounted() {
    for (const attachment of this.attachments) {
      this.attachmentsOpened[attachment.documentId] = false;
    }
    setTimeout(() => {
      this.showEmail = true;
    }, 500);
  },
  computed: {
    ...mapState([
      'currentIntroPageIndex',
      'currentIntroUnlockPageIndex',
    ]),
    title() {
      return this.data?.title ? this.data.title : '';
    },
    emailText() {
      return this.data?.emailText ? this.data.emailText : '';
    },
    toFromData() {
      return this.data?.toFromField;
    },
    senderInfo() {
      return this.data?.senderInfo ? this.data.senderInfo : '';
    },
    attachments() {
      return this.data?.attachments ? this.data.attachments : [];
    },
    isNews() {
      return this.data?.isNews ? this.data.isNews : false;
    },
  },
  watch: {
    // Note: only simple paths. Expressions are not supported.
    currentIntroPageIndex(newValue) {
      this.attachmentsOpened = {};
      this.pageCompletedSent = false;
    },
  },
  methods: {
    openAttachment(attachmentTitle, attachmentId) {
      this.$emit('openDocument', attachmentId);

      if (!this.attachmentsOpened[attachmentId]) {
        // set opened to true
        this.attachmentsOpened[attachmentId] = true;

        const isAllAttachmentsOpened = this.isAllAttachmentsOpened();
        if (isAllAttachmentsOpened) {
          this.$emit('pageCompleted');
        }
      }
    },
    getImageSrc(pictureId) {
      const images = require.context('../../assets/img/newsImages');
      const imgSrc = images(`./${pictureId}.png`);
      return imgSrc;
    },
    completePage() {
      this.$emit('pageCompleted');
    },
    isAllAttachmentsOpened() {
      // console.log('isAllAttachmentsOpened', this.attachmentsOpened);
      for (const attachmentOpened in this.attachmentsOpened) {
        if (this.attachmentsOpened.hasOwnProperty(attachmentOpened)) {
          if (!this.attachmentsOpened[attachmentOpened]) {
            return false;
          }
        }
      }
      return true;
    },
  },
};

</script>

<style lang="scss" scoped>
$side_padding_outer: 25px;
$side_padding: 40px;
$title_size: 34px;

.email-view-container {
  position: relative;
  font-family: 'Fira Sans Condensed', 'Fira Sans', sans-serif;
  border-radius: 15px;
  width: 48%;
  min-width: 440px;
  float: right;
  padding-top: 35px;
  margin-right: 75px;
  overflow-y: hidden;
  height: 100vh;

  transition-property: transform;
  transition-duration: 950ms;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.175);

  &.hidden {
    transform: translateY(120%);
  }
}

</style>
