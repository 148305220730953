<template>
  <div class="document-vca4d-intro">
    <p v-show="currentPageIndex === 0" v-html="text0Html" />
    <p v-show="currentPageIndex === 1" v-html="text1Html" />
  </div>
</template>

<script>
import textToHtml from '@/utils/textToHtml';

export default {
  name: 'DocumentVCA4DIntro',
  components: {},
  props: {
    currentPageIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isMultiPage: true,
      numberOfPages: 2,
    };
  },
  computed: {
    text0Html() {
      const text = this.$t('document.vca4dIntro.text0');
      return textToHtml(text, this.$t);
    },
    text1Html() {
      const text = this.$t('document.vca4dIntro.text1');
      return textToHtml(text, this.$t);
    },
  },
};
</script>
<style lang="scss" scoped>
.document-vca4d-intro {
  min-height: 300px;
}

</style>
