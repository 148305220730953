import GameData from '@/data';
import { STATUS_AVAILABLE, STATUS_LOCKED, STATUS_UNAVAILABLE } from '@/enum/UnlockedStatus';
import store from '@/store';
import localizationExpertData from '@/data/expertData';
import { EVIDENCE_POINTS_FOR_DG_CHANGE_MIND } from '@/data/evaluationData/scoreConstants';

// TODO update these with the new data for dilemma IDs
export function getDilemmaFromId(dilemmas, dilemmaId) {
  return dilemmas?.[dilemmaId];
}

export function isConditionMet(value, conditionalValue, operator) {
  let isConditionalMet = false;

  if (operator === '===') {
    isConditionalMet = value === conditionalValue;
  } else if (operator === '>=') {
    isConditionalMet = value >= conditionalValue;
  } else if (operator === '<=') {
    isConditionalMet = value <= conditionalValue;
  } else if (operator === '>') {
    isConditionalMet = value > conditionalValue;
  } else if (operator === '<') {
    isConditionalMet = value < conditionalValue;
  }

  return isConditionalMet;
}

export function getDilemmaQuestions(smallFirstLetter = false) {
  const dilemmaQuestions = {};

  for (const key in GameData.dilemmaData) {
    let dilemmaQuestion = GameData.dilemmaData[key].description;
    if (smallFirstLetter) {
      dilemmaQuestion = dilemmaQuestion.charAt(0).toLowerCase() + dilemmaQuestion.slice(1);
    }
    dilemmaQuestions[key] = dilemmaQuestion;
  }

  return dilemmaQuestions;
}

export function createStakeholderOnboardStatusObject() {
  const stakeholderOnboardStatus = {};

  for (const key in GameData.dilemmaData) {
    const currentDilemma = GameData.dilemmaData[key];
    const dilemmaId = currentDilemma.dilemma;

    stakeholderOnboardStatus[dilemmaId] = {};

    stakeholderOnboardStatus[dilemmaId].person = [];

    const { person } = GameData.dilemmaData[key].sources;

    for (const policyKey in person) {
      stakeholderOnboardStatus[dilemmaId].person[policyKey] = {};
      stakeholderOnboardStatus[dilemmaId].person[policyKey].onBoardingBercentage = 0;
      stakeholderOnboardStatus[dilemmaId].person[policyKey].previousOnBoardingBercentage = 0;
    }
  }

  return stakeholderOnboardStatus;
}

export function createDilemmaUnlockedStatusObject() {
  const dilemmaUnlockedStatus = {};
  let counter = 0;

  for (const key in GameData.dilemmaData) {
    const currentDilemma = GameData.dilemmaData[key];
    const dilemmaId = currentDilemma.dilemma;

    dilemmaUnlockedStatus[dilemmaId] = {};

    dilemmaUnlockedStatus[dilemmaId].status = STATUS_UNAVAILABLE;
    if (counter === 0) {
      dilemmaUnlockedStatus[dilemmaId].status = STATUS_AVAILABLE;
    }

    dilemmaUnlockedStatus[dilemmaId].policies = {};
    const { policyUnlocks } = GameData.dilemmaData[key];

    for (const policyKey in policyUnlocks) {
      dilemmaUnlockedStatus[dilemmaId].policies[policyKey] = STATUS_LOCKED;
    }
    counter++;
  }
  return dilemmaUnlockedStatus;
}

export function createTutorialAssistantMessagesShownObject(trueValues = []) {
  const tutorialAssistantMessagesShown = {
    technicalInsightExplanation: false,
    knowledgeVaultExplanation: false,
    dilemma1IntroExplanation: false,
    dilemmaViewExplanation: false,
    onBoardingExplanation: false,
    sixPagerIconExplainer: false,
    dataBreakExplanation: false,
    dataIconExplanation: false,
    argumentExplanation: false,
    videoSeen: false,
    dilemmaExitButtonExplanation: false,
  };

  for (const valueKey of trueValues) {
    if (tutorialAssistantMessagesShown.hasOwnProperty(valueKey)) {
      tutorialAssistantMessagesShown[valueKey] = true;
    }
  }

  return tutorialAssistantMessagesShown;
}

export function createDilemmaStakeholderProgressionStatusArray() {
  const dilemmaStakeholderProgressionStatus = {};

  for (const key in GameData.dilemmaData) {
    const currentDilemma = GameData.dilemmaData[key];
    const dilemmaId = currentDilemma.dilemma;

    dilemmaStakeholderProgressionStatus[dilemmaId] = {};

    dilemmaStakeholderProgressionStatus[dilemmaId].person = [];
    dilemmaStakeholderProgressionStatus[dilemmaId].document = [];

    const { person, document } = GameData.dilemmaData[key].sources;

    for (const policyKey in person) {
      dilemmaStakeholderProgressionStatus[dilemmaId].person[policyKey] = {};
      dilemmaStakeholderProgressionStatus[dilemmaId].person[policyKey].currentIndex = 0;
      dilemmaStakeholderProgressionStatus[dilemmaId].person[policyKey].currentChoiceIndex = -1;
      dilemmaStakeholderProgressionStatus[dilemmaId].person[policyKey].isCurrentKnowledgeVaultItemSaved = false;
      dilemmaStakeholderProgressionStatus[dilemmaId].person[policyKey].storyMessageHistory = [];
      dilemmaStakeholderProgressionStatus[dilemmaId].person[policyKey].currentChoices = [];
    }
    for (const policyKey in document) {
      dilemmaStakeholderProgressionStatus[dilemmaId].document[policyKey] = {};
      dilemmaStakeholderProgressionStatus[dilemmaId].document[policyKey].savedKnowledgeVaultItemIndexes = [];
    }
  }

  return dilemmaStakeholderProgressionStatus;
}

export function getTextFromArgument(text, locale) {
  const isAnswer = text.includes('{DATA_ITEM_ANSWER');
  const argumentBossFightId = text.replace('{DATA_ITEM_', '').replace('ANSWER_', '').replace('}', '');

  const argumentsUsedInBossFight = store.getters?.argumentsUsedInBossFight;

  const argumentId = argumentsUsedInBossFight[argumentBossFightId];
  const argumentDataBreakData = localizationExpertData[locale].dataBreakData[argumentId];

  if (isAnswer) {
    return argumentDataBreakData.reactionOfDG;
  }
  return argumentDataBreakData.fireData;
}

export function getScenarioSuperPointSpecificText(text, selectedScenarioIndex, evidencePointsBossFight, translate) {
  let newText;

  if (selectedScenarioIndex === 0) {
    if (evidencePointsBossFight >= EVIDENCE_POINTS_FOR_DG_CHANGE_MIND) {
      newText = translate('dialogueView.bossFightResponse1');
    } else {
      newText = translate('dialogueView.bossFightResponse2');
    }
  } else if (selectedScenarioIndex === 1) {
    if (evidencePointsBossFight >= EVIDENCE_POINTS_FOR_DG_CHANGE_MIND) {
      newText = translate('dialogueView.bossFightResponse3');
    } else {
      newText = translate('dialogueView.bossFightResponse4');
    }
  } else if (evidencePointsBossFight >= EVIDENCE_POINTS_FOR_DG_CHANGE_MIND) {
    newText = translate('dialogueView.bossFightResponse5');
  } else {
    newText = translate('dialogueView.bossFightResponse6');
  }

  return newText;
}

export function getPolicyOptionTitle(dilemmaId, policyOptionId) {
  const policyUnlocks = GameData.dilemmaData[dilemmaId]?.policyUnlocks ?? {};

  if (policyUnlocks === {}) {
    console.error(`dilemma with id ${dilemmaId} does not contain key 'policyUnlocks'`);
  }

  const policyDescription = policyUnlocks[policyOptionId].title;

  return policyDescription;
}

function getPolicyTextFromScenarioAndOnboarding(selectedScenarioIndex, stakeholderOnboardStatus, translate) {
  let persistentPolicyOptionId = '0';
  let policyDilemmaOrder = [];

  let numberString = '';
  let policyNumberLimit = 2;

  if (stakeholderOnboardStatus >= 13) {
    numberString = translate('misc.four');
    policyNumberLimit = 4;
  } else if (stakeholderOnboardStatus >= 9) {
    numberString = translate('misc.three');
    policyNumberLimit = 3;
  } else {
    numberString = translate('misc.two');
    policyNumberLimit = 2;
  }

  if (selectedScenarioIndex === 0) {
    persistentPolicyOptionId = '1';
    policyDilemmaOrder = ['1', '4', '3', '2'];
  } else if (selectedScenarioIndex === 1) {
    persistentPolicyOptionId = '2';
    policyDilemmaOrder = ['3', '1', '2', '4'];
  } else {
    persistentPolicyOptionId = '3';
    policyDilemmaOrder = ['1', '3', '4', '2'];
  }

  let newText = translate('dialogueView.policiesIncluded');
  newText = newText.replace('/\\{numberString\\}/g', numberString);

  // `I suggest including these ${numberString} policies in our agreement. We'll discuss monitoring indicators at a later stage.\n\n`;
  let counter = 0;

  newText += '_--- ';
  for (const policyDilemmaId in policyDilemmaOrder) {
    if (counter !== 0) {
      newText += '--- ';
    }

    newText += translate(getPolicyOptionTitle(policyDilemmaOrder[policyDilemmaId], persistentPolicyOptionId));
    newText += ' \n';

    if (counter + 1 >= policyNumberLimit) {
      break;
    }

    counter++;
  }

  newText += '---_';

  return newText;
}

export function getScenarioSuperPointSpecificTextAndPolicies(text, selectedScenarioIndex, evidencePointsBossFight, stakeholderOnboardStatus, translate) {
  let newText;

  let onboardingPoints = 0;

  for (const stakeholder in stakeholderOnboardStatus) {
    if (stakeholderOnboardStatus?.[stakeholder]) {
      onboardingPoints += stakeholderOnboardStatus[stakeholder];
    }
  }

  if (selectedScenarioIndex === 0) {
    if (evidencePointsBossFight >= EVIDENCE_POINTS_FOR_DG_CHANGE_MIND) {
      newText = getPolicyTextFromScenarioAndOnboarding(selectedScenarioIndex, onboardingPoints, translate);
    } else {
      newText = getPolicyTextFromScenarioAndOnboarding(1, onboardingPoints, translate);
    }
  } else if (selectedScenarioIndex === 1) {
    if (evidencePointsBossFight >= EVIDENCE_POINTS_FOR_DG_CHANGE_MIND) {
      newText = getPolicyTextFromScenarioAndOnboarding(selectedScenarioIndex, onboardingPoints, translate);
    } else {
      newText = getPolicyTextFromScenarioAndOnboarding(0, onboardingPoints, translate);
    }
  } else if (evidencePointsBossFight >= EVIDENCE_POINTS_FOR_DG_CHANGE_MIND) {
    newText = getPolicyTextFromScenarioAndOnboarding(selectedScenarioIndex, onboardingPoints, translate);
  } else {
    newText = getPolicyTextFromScenarioAndOnboarding(1, onboardingPoints, translate);
  }

  return newText;
}
