/** *******************************************
 * USER
 ******************************************** */
// Store management
export const INITIALIZE_STORE = 'INITIALIZE_STORE';
export const RESET_GAME = 'RESET_GAME';
export const RESET_TO_BOSS_FIGHT = 'RESET_TO_BOSS_FIGHT';
export const SET_IS_GAME_REPLAY = 'SET_IS_GAME_REPLAY';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const CHANGE_IS_LANGUAGE_SELECT_PAGE = 'CHANGE_IS_LANGUAGE_SELECT_PAGE';

export const SET_DEAL_ACCEPTED = 'SET_DEAL_ACCEPTED';
export const SET_DILEMMA_UNLOCKED_STATUS = 'SET_DILEMMA_UNLOCKED_STATUS';
export const SET_BACKGROUND = 'SET_BACKGROUND';
export const SET_CURRENT_DILEMMA_ID = 'SET_CURRENT_DILEMMA_ID';
export const SET_SELECTED_SCENARIO_INDEX = 'SET_SELECTED_SCENARIO_INDEX';
export const SET_CURRENT_INTRO_PAGE_INDEX = 'SET_CURRENT_INTRO_PAGE_INDEX';
export const SET_INFORMAL_PD_PAGE_INDEX = 'SET_INFORMAL_PD_PAGE_INDEX';
export const SET_EVAL_AND_PD_PAGE_INDEX = 'SET_EVAL_AND_PD_PAGE_INDEX';
// export const SET_KNOWLEDGE_BANK = 'SET_KNOWLEDGE_BANK';
export const ADD_KNOWLEDGE_BANK_ITEM = 'ADD_KNOWLEDGE_BANK_ITEM';
export const ADD_ARGUMENT_PERSON_PROGRESSION = 'ADD_ARGUMENT';
export const SET_CURRENT_STAKEHOLDER_ID = 'SET_CURRENT_STAKEHOLDER_ID';
export const SET_DYNAMIC_STORE_VALUE = 'SET_DYNAMIC_STORE_VALUE';

export const SET_ALL_DILEMMA_UNLOCKED_STATUS = 'SET_ALL_DILEMMA_UNLOCKED_STATUS';
export const SET_POLICY_UNLOCKED_STATUS = 'SET_POLICY_UNLOCKED_STATUS';
export const SET_ALL_DILEMMA_STAKEHOLDER_PROGRESS_STATUS = 'SET_ALL_DILEMMA_STAKEHOLDER_PROGRESS_STATUS';
export const SET_DILEMMA_STAKEHOLDER_PERSON_PROGRESS_STATUS = 'SET_DILEMMA_STAKEHOLDER_PERSON_PROGRESS_STATUS';
export const SET_DILEMMA_STAKEHOLDER_DOCUMENT_PROGRESS_STATUS = 'SET_DILEMMA_STAKEHOLDER_DOCUMENT_PROGRESS_STATUS';
export const SET_STAKEHOLDER_DIALOGUE_HISTORY = 'SET_STAKEHOLDER_DIALOGUE_HISTORY';
export const UPDATE_ONBOARDING_STATUS = 'UPDATE_ONBOARDING_STATUS';
export const SET_CURRENT_DATA_BREAK_DATA = 'SET_CURRENT_DATA_BREAK_DATA';
export const SET_STUDY_BRIEF_TAB_INDEX = 'SET_STUDY_BRIEF_TAB_INDEX';

export const SET_ROUTER_CURRENT_PAGE = 'SET_ROUTER_CURRENT_PAGE';

// Conditional variables set from dialogue
export const SET_IDP_OPPORTUNITY_ACCEPTED = 'SET_IDP_OPPORTUNITY_ACCEPTED';
export const SET_VARIABLE_TYPE_SCENARIO_1_SEEN = 'SET_VARIABLE_TYPE_SCENARIO_1_SEEN';
export const SET_VARIABLE_TYPE_SCENARIO_2_SEEN = 'SET_VARIABLE_TYPE_SCENARIO_2_SEEN';
export const SET_VARIABLE_TYPE_SCENARIO_3_SEEN = 'SET_VARIABLE_TYPE_SCENARIO_3_SEEN';

// Evidence points
export const SET_EVIDENCE_HARVEST_POINTS = 'SET_EVIDENCE_HARVEST_POINTS';
export const SET_EVIDENCE_TRANSFORM_POINTS = 'SET_EVIDENCE_TRANSFORM_POINTS';
export const ADD_EVIDENCE_HARVEST_POINTS = 'ADD_EVIDENCE_HARVEST_POINTS';
export const ADD_EVIDENCE_TRANSFORM_POINTS = 'ADD_EVIDENCE_TRANSFORM_POINTS';
export const ADD_EVIDENCE_BOSS_FIGHT_POINTS = 'ADD_EVIDENCE_BOSS_FIGHT_POINTS';

// Tutorial
export const SET_TUTORIAL_STEP_INDEX = 'SET_TUTORIAL_STEP_INDEX';
export const UPDATE_TUTORIAL_ASSISTANT_STATUS = 'UPDATE_TUTORIAL_ASSISTANT_STATUS';
export const ORDER_VCA4D_MATERIAL = 'ORDER_VCA4D_MATERIAL';
export const FINISH_INFORMAL_PD = 'FINISH_INFORMAL_PD';
export const REPLAY_TUTORIAL = 'REPLAY_TUTORIAL';
export const FINISH_TUTORIAL = 'FINISH_TUTORIAL';

// Knowledge vault
export const SET_IS_KNOWLEDGE_VAULT_OPEN = 'SET_IS_KNOWLEDGE_VAULT_OPEN';
export const ADD_DATA_ID_KV = 'ADD_DATA_ID_KV';
export const SET_ARGUMENT_PER_POLICY_OBJECT = 'SET_ARGUMENT_PER_POLICY_OBJECT';
export const SET_KNOWLEDGE_VAULT_SAVED_IDS_OBJECT = 'SET_KNOWLEDGE_VAULT_SAVED_IDS_OBJECT';
export const USE_ARGUMENT = 'USE_ARGUMENT';
export const REMOVE_ARGUMENT = 'REMOVE_ARGUMENT';
export const ADD_ARGUMENT = 'ADD_ARGUMENT';

// Cheats
export const CHEAT_SKIP_INTRO = 'CHEAT_SKIP_INTRO';
export const CHEAT_SKIP_TUTORIAL = 'CHEAT_SKIP_TUTORIAL';
export const CHEAT_SKIP_TO_INFORMAL_PD = 'CHEAT_SKIP_TO_INFORMAL_PD';
export const CHEAT_FINISH_INFORMAL_PD = 'CHEAT_FINISH_INFORMAL_PD';
export const CHEAT_FINISH_ALL_DILEMMAS = 'CHEAT_FINISH_ALL_DILEMMAS';
export const CHEAT_SKIP_TO_BOSS_FIGHT = 'CHEAT_SKIP_TO_BOSS_FIGHT';
export const ENABLE_CHEATS = 'ENABLE_CHEATS';
