<template>
  <div class="choice-group container">
    <div class="row">
      <div class="col text-center small-padding" v-for="(choiceButton, i) of choiceButtons">
        <ChoiceButton
          v-if="choiceButton.btnText !== '{isDummy}'"
          :cost="choiceButton.cost"
          :btnText="choiceButton.btnText"
          :disabled="choiceButton.disabled"
          @click="onClickButton(choiceButton, i)">
        </ChoiceButton>
      </div>
    </div>
  </div>
</template>
<script>

import ChoiceButton from "./ChoiceButton";

export default {
  name: 'ChoiceButtonGroup',
  components: {ChoiceButton},
  props: {
    choiceButtons: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  methods: {
    getValueFromChoiceButton(choiceButton, i) {
      if (Object.keys(choiceButton).includes('value')) {
        return choiceButton.value;
      }

      return i;
    },
    onClickButton(choiceButton, i) {
      // console.log('choiceButton', choiceButton);
      const value = this.getValueFromChoiceButton(choiceButton, i);

      this.$emit('update:modelValue', value);
    },
  },
}
</script>

<style lang="scss" scoped>

.small-padding {
  padding: 0 5px !important;
}
</style>
