<template>
  <h1>StakeholderGroupDev</h1>

  <div class="row">
    <div class="stakeholder-container">
      <StakeholderGroup :stakeholders="stakeholders" :current-stakeholder-index="currentValue" :use-filter="useFilter" />
    </div>
  </div>

  <div class="row mt-4">
    <div class="col">
      <button class="btn" :class="useFilter ? 'btn-success' : 'btn-light'" @click="useFilter = true">Use filter: TRUE</button>
      <button class="btn" :class="!useFilter ? 'btn-success' : 'btn-light'" @click="useFilter = false">Use filter: FALSE</button>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col">
      <button class="btn btn-primary me-4" @click="mutateValue(-1)">--</button>
      <button class="btn" :class="currentValue === 0 ? 'btn-success' : 'btn-light'" @click="currentValue = 0">Active 0</button>
      <button class="btn" :class="currentValue === 1 ? 'btn-success' : 'btn-light'" @click="currentValue = 1">Active 1</button>
      <button class="btn" :class="currentValue === 2 ? 'btn-success' : 'btn-light'" @click="currentValue = 2">Active 2</button>
      <button class="btn btn-primary ms-4" @click="mutateValue(1)">++</button>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col">
      <button class="btn" :class="danceMode ? danceButtonClasses[danceButtonClassIndex] : 'btn-light'" @click="danceMode = !danceMode">Dance mode: {{ danceMode ? 'ENABLED' : 'DISABLED' }}</button>
    </div>
  </div>

  <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>

  <div class="row">
    <strong>Active value:</strong> {{ currentValue }}
  </div>
</template>

<script>
import EventTicks from "../DevComponent/EventTicks";
import EventTicksComponentMixin from "../DevComponent/EventTicksComponentMixin";
import DevComponentContainer from "../DevComponent/DevComponentContainer";
import StakeholderGroup from "./StakeholderGroup";

export default {
  name: 'StakeholderGroupDev',
  mixins: [EventTicksComponentMixin],

  components: {StakeholderGroup, EventTicks},
  extends: DevComponentContainer,
  watch: {
    currentValue() {
      this.triggerEvent('click');
    },
    danceMode() {
      if (this.danceMode) {
        this.danceTick = setInterval(() => {
          this.danceButtonClassIndex = (this.danceButtonClassIndex + 1) % this.danceButtonClasses.length;
          this.mutateValue(1);
        }, 200);
      } else {
        if (this.danceTick) {
          clearInterval(this.danceTick);
        }
      }
    },
  },
  data() {
    return {
      currentValue: 0,

      useFilter: true,
      danceMode: false,

      danceButtonClasses: ['btn-success', 'btn-primary', 'btn-warning', 'btn-danger'],
      danceButtonClassIndex: 0,

      stakeholders: [
        {
          pictureId: 'woman1',
          name: 'Amaro Dibuzi',
          role: 'Minister of Agriculture',
        },
        {
          pictureId: 'man1',
          name: 'Omari Debussy',
          role: 'President of Pop',
        },
        {
          pictureId: 'woman2',
          name: 'Imara Awolowo',
          role: 'Ruler of Rice',
        },
      ],

      events: ['click'],
    };
  },
  methods: {
    mutateValue(mutation) {
      this.currentValue += mutation;

      if (this.currentValue < 0) {
        this.currentValue = this.stakeholders.length - 1;
      } else if (this.currentValue >= this.stakeholders.length) {
        this.currentValue = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.stakeholder-container {
  width: 300px;
  height: 500px;
  background: white;
  padding: 10px;
}
</style>
