<template>
  <div class="assistant-banner-wrapper">
    <div class="assistant-tutorial-overlay">
      <TutorialHighlightOverlay :highlight-id="highlightId" :type="highlightType" :offset="highlightOffset"
                                :interaction-required="interactionRequired" @clickHighlight="highlightAreaClicked()" />
    </div>
    <div v-if="startDelayHasPassed" class="assistant-banner" :class="avatarClass">
      <div class="assistant-banner-content" :class="isFirstMessage ? 'assistant-banner-content-reveal' : ''">
        <div class="assistant-banner-avatar-wrapper">
          <div class="assistant-banner-avatar" />
        </div>
        <div class="assistant-banner-text" v-html="currentMessageHtml" />
        <div class="d-flex justify-content-end" :class="noCurrentChoices ? 'assistant-button-wrapper-no-choices' : 'assistant-button-wrapper'">
          <div v-for="(choice, i) in currentChoices" :key="i">
            <div class="onBoarding-bar">
              <AppButton :size="'fit'" :color="'grey'" @click="onClickChoiceButton(choice.response)">
                {{ $t(choice.choice) }}
              </AppButton>
            </div>
          </div>
          <AppButton v-if="showDefaultProgressionButton" :size="'medium'" :color="'light_grey'" @click="onClickButton()">
            {{ $t('misc.gotIt') }}
          </AppButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import AppButton from '@/components/common/AppButton';
import textToHtml from '@/utils/textToHtml';
import TutorialHighlightOverlay, { TYPE_SQUARE } from '@/components/TutorialHighlightOverlay/TutorialHighlightOverlay';
import { ASSISTANT_TYPE_BANNER, ASSISTANT_TYPE_BANNER_INSTANT } from '../../enum/AssistantTypes';

export default {
  name: 'AssistantBanner',
  components: {
    TutorialHighlightOverlay,
    AppButton,
  },
  data() {
    return {
      startDelayHasPassed: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.startDelayHasPassed = true;
    }, this.startDelay);
  },
  props: {
    startDelay: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isFirstMessage: {
      type: Boolean,
      default: false,
    },
    currentMessage: {
      type: String,
      default: '',
    },
    currentChoices: {
      type: Array,
      default: () => [],
    },
    assistantType: {
      type: String,
      required: true,
    },
    tutorialHighlight: {
      type: Object,
      default: () => {},
    },
    currentAssistantPlacement: {
      type: String,
      default: 'bottom',
    },
    interactionRequired: {
      type: Boolean,
      default: false,
    },
    interactionElementId: {
      type: String,
      default: 'bottom',
    },
  },
  emits: ['click', 'showAnswer'],
  computed: {
    avatarClass() {
      if (!this.startDelayHasPassed) {
        return [];
      }
      const classes = [];
      if (this.assistantType === ASSISTANT_TYPE_BANNER) {
        classes.push('assistant-banner-assistant');
      }
      if (this.assistantType === ASSISTANT_TYPE_BANNER_INSTANT) {
        classes.push('assistant-banner-instant');
      }
      if (this.isFirstMessage) {
        classes.push('assistant-banner-reveal');
      }

      classes.push(this.currentAssistantPlacement);

      return classes;

      // return ['assistant-avatar', 'assistant-avatar-' + this.mood];
    },
    currentMessageHtml() {
      return textToHtml(this.$t(this.currentMessage), this.$t);
    },
    noCurrentChoices() {
      return this.currentChoices.length < 1;
    },
    highlightId() {
      if (!this.startDelayHasPassed) {
        return null;
      }

      return this.tutorialHighlight?.highlightId || null;
    },
    highlightType() {
      return this.tutorialHighlight?.type || TYPE_SQUARE;
    },
    highlightOffset() {
      return this.tutorialHighlight?.offset || 0;
    },
    showDefaultProgressionButton() {
      if (this.interactionRequired) {
        return false;
      }

      if (!this.noCurrentChoices) {
        return false;
      }

      return true;
    },
  },
  methods: {
    onClickButton() {
      if (this.disabled) {
        return;
      }

      this.$emit('click');
    },
    onClickChoiceButton(response) {
      this.$emit('showAnswer', response);
    },
    highlightAreaClicked() {
      if (!this.interactionRequired) {
        return;
      }

      document.getElementById(this.interactionElementId).click();

      this.onClickButton();
    },
  },
};
</script>
<style lang="scss" scoped>

$bottomOffset: 20px;

  .assistant-banner-wrapper{
    width: 100%;
    position: absolute;
    bottom: 0;
    overflow: hidden;
    left: 0;
    height: 100%;
    z-index: 10000000;
    font-size: 1.2rem;

    .assistant-tutorial-overlay {
      position: absolute;
      z-index: 0;
    }

    .assistant-banner-reveal {
      animation-name: reveal;
      animation-duration: 600ms;
      animation-timing-function: ease;

      .assistant-banner-content-reveal {
        animation-name: reveal-content;
        animation-timing-function: ease;

        animation-duration: 1.5s;
      }
    }

    .assistant-banner {
      position: absolute;
      z-index: 1;
      width: 100%;
      bottom: $bottomOffset;
      min-height: 110px;
      height: fit-content;
      left: 0;
      padding: 15px;

      &.center {
        bottom: 50% !important;
        transform: translateY(50%);
      }

      .assistant-banner-content {
        opacity: 1;

        .assistant-banner-avatar-wrapper {
          position: absolute;
          bottom: 0;

          .assistant-banner-avatar {
            background-image: url("../../assets/img/assistant/assistant.png");
            position: absolute;
            width: 8rem;
            height: 8rem;
            // bottom: -$bottomOffset;
            bottom: -10px;
            left: 2px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          }
        }

        .assistant-banner-text {
          color: #fff;
          left: 18%;
          width: 60%;
          min-height: 60px;
          margin-left: 18%;
        }

        .assistant-button-wrapper {
          width: 80%;
          margin-left: 18%;
          margin-top: 15px;
          margin-bottom: 15px;
        }

        .assistant-button-wrapper-no-choices {
          width: 85%;
          right: 50px;
          position: absolute;
          bottom: 18px;
        }
      }

      &.assistant-banner-assistant {
        background: linear-gradient(90deg, #5799A8 0%, #395D60 70%);
        .assistant-banner-avatar-wrapper {

          .assistant-banner-avatar {
            background-image: url("../../assets/img/assistant/assistant.png");
            bottom: -$bottomOffset;
            width: 13rem;
            left: -20px;
            height: 11rem;
          }
        }
      }

      &.assistant-banner-instant {
        background: linear-gradient(90deg, #E17F47 0%, #874C2A 70%);
        .assistant-banner-avatar-wrapper {
          height: 100%;

          .assistant-banner-avatar {
            background-image: url("../../assets/img/assistant/instant_feedback.png");
            width: 10rem;
            height: 10rem;
            top: -40px;
          }
        }
      }
    }

    .assistant-avatar {
      background-image: url("../../assets/img/assistant/assistant.png");
    }

    .assistant-avatar-neutral {
      // background-image: url("../../assets/img/assistant/assistant-neutral.png");
    }

    @keyframes reveal
    {
      0% {width:0px; opacity:50;}
      10% {width:0px; opacity:50;}
      100% {width:100%;
        opacity:100;}
    }

    @keyframes reveal-content
    {
      0% {opacity:0;
      width: 100vw}
      60% {opacity:0;
        left: 18%;}
      70% {opacity:0;
        left: 18%;}
      100% {opacity:1;}
    }
  }

</style>
