<template>
  <div class="knowledge-vault-outer-wrapper">
    <div class="blackness" @click="onClickBackButton()"/>
    <div class="knowledge-vault-content">
      <div class="back-button" @click="onClickBackButton">
        ✖
      </div>
      <div class="knowledge-vault-title-wrapper">
        <h2>{{ $t('misc.knowledgeVault') }}</h2>
        <div class="persistent-assistant-wrapper">
          <PersistentAssistantButton assistant-message-id="persistentKnowledgeVaultMessage" />
        </div>
      </div>
      <div class="green-line" :class="greenLineClasses">
        <div v-if="hasScenario" class="knowledge-vault-scenario-title">
          <p>{{$t('misc.scenario')}}: {{ $t(scenarioTitle) }}</p>
        </div>
      </div>

      <KnowledgeVaultSidePanel v-if="showSidePanel" ref="knowledgeVaultSide" />

      <div class="knowledge-vault-full-panel-wrapper" :class="showSidePanel ? '' : 'no-side-panel'">
        <div id="knowledge-vault-list-wrapper" class="knowledge-vault-list-wrapper">
          <ul :ref="'knowledgeVaultFull'" class="knowledge-vault-items-wrapper d-flex flex-wrap justify-content-center align-self-stretch">
            <li v-for="(id, key) in knowledgeVaultSavedIdsCopy" :key="id"
                class="knowledge-vault-item draggable list-unstyled"
                :class="getDraggableClass(id)"
                :itemIndex="id">
              <KnowledgeVaultItem :data-break-id="id" @click="removeArgument(id)" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { mapState } from 'vuex';
import KnowledgeVaultItem from '@/components/KnowledgeVault/KnowledgeVaultItem';
import KnowledgeVaultSidePanel from '@/components/KnowledgeVault/KnowledgeVaultSidePanel';
import strategyData from '@/data/strategyData.json';
import { Draggable, Sortable, Plugins } from '@shopify/draggable';
import {
  REMOVE_ARGUMENT,
  SET_ARGUMENT_PER_POLICY_OBJECT,
  SET_KNOWLEDGE_VAULT_SAVED_IDS_OBJECT,
} from '@/store/mutation-types';
import PersistentAssistantButton from '@/components/PersistentAssistantButton/PersistentAssistantButton';

export default {
  name: 'KnowledgeVaultFullPanel',
  components: {
    PersistentAssistantButton,
    KnowledgeVaultSidePanel,
    KnowledgeVaultItem,
  },
  emits: ['click'],
  data() {
    return {
      knowledgeVaultSavedIdsCopy: [],
      clonedNode: null,
      isDragging: false,
      sourceContainer: null,
      hoverContainer: null,
      openDilemmaIds: [],
      containers: [],
    };
  },
  watch: {

  },
  methods: {
    /* updateData() {
      this.knowledgeVaultSavedIdsCopy = this.knowledgeVaultSavedIds;
    },
    /* clickDilemma(dilemmaId) {
      toggleArrayItemByValue(this.openDilemmaIds, dilemmaId);
      console.log('aloha means hi', this.$refs, this.$refs.knowledgeVaultFullDilemma1);
    }, */
    onClickBackButton() {
      if (this.disabled) {
        return;
      }

      // updating the entire state of the knowledge vault
      this.updateStateBasedOnContainers();

      this.$emit('click');
    },
    updateStateBasedOnContainers() {
      // update all lists if they have changes
      let newKnowledgeVaultSavedIds = [];
      const newArgumentIndexesPerPolicyIndex = {};

      for (let i = 0; i < this.containers.length; i++) {
        const container = this.containers[i];
        const elementsInContainer = container.getElementsByTagName("li");

        const tempIdList = [];

        for (const listItem of elementsInContainer) {
          const classList = listItem.className.split(' ');
          let idClassName = null;

          for (const className of classList) {
            if (className.includes('knowledge-item-is-draggable--item')) {
              idClassName = className;
            }
          }

          if (idClassName) {
            const id = parseInt(idClassName.replace('knowledge-item-is-draggable--item', ''), 10);
            if (!tempIdList.includes(id.toString())) {
              tempIdList.push(id.toString());
            }
          } else {
            console.error('id not recognized for classList: ', classList);
          }
        }
        if (i === 0) {
          // For the full container
          newKnowledgeVaultSavedIds = tempIdList;
        } else if (i < 6 || i > 0) {
          // For the full container
          newArgumentIndexesPerPolicyIndex[(i - 1)] = tempIdList;
        }
      }

      this.$store.commit(SET_KNOWLEDGE_VAULT_SAVED_IDS_OBJECT, newKnowledgeVaultSavedIds);
      if (this.showSidePanel) {
        this.$store.commit(SET_ARGUMENT_PER_POLICY_OBJECT, newArgumentIndexesPerPolicyIndex);
      }
    },
    getDraggableClass(i) {
      const classList = [];

      classList.push('knowledge-item-is-draggable');
      classList.push(`knowledge-item-is-draggable--item${i}`);

      return classList;
    },
    removeArgument(id) {
      this.$store.commit(REMOVE_ARGUMENT, id);
      this.$refs.knowledgeVaultSide.updateData();
    },
    initiateDraggables() {
      const fullContainer = this.$refs.knowledgeVaultFull;
      this.containers.push(fullContainer);

      if (this.showSidePanel) {
        const sideContainer0 = this.$refs.knowledgeVaultSide.$refs.knowledgeVaultSide0?.[0];
        this.containers.push(sideContainer0);

        const sideContainer1 = this.$refs.knowledgeVaultSide.$refs.knowledgeVaultSide1?.[0];
        this.containers.push(sideContainer1);

        const sideContainer2 = this.$refs.knowledgeVaultSide.$refs.knowledgeVaultSide2?.[0];
        this.containers.push(sideContainer2);

        const sideContainer3 = this.$refs.knowledgeVaultSide.$refs.knowledgeVaultSide3?.[0];
        this.containers.push(sideContainer3);
      }

      const argumentCapacity = 2;

      const sortable = new Sortable(this.containers, {
        draggable: 'li',
      });

      sortable.on('drag:start', (evt) => {
        const originalNode = evt.data.source;
        originalNode.classList.add('active-drag');
      });

      sortable.on('sortable:sort', (evt) => {

        this.hoverContainer = evt.dragEvent.overContainer;

        // if knowledge vault items are rearranged in the large container
        if (evt.dragEvent.sourceContainer === sortable.containers[0]) {
          // evt.cancel();
        }

        // stop the drag if argument containers have reached maximum capacity
        /* console.log("what is capacity");
        console.log(evt.dragEvent.overContainer !== sortable.containers[0]);
        console.log(evt.dragEvent.overContainer);
        console.log(sortable.getDraggableElementsForContainer(evt.dragEvent.overContainer).length);
        console.log(argumentCapacity); */

        if (evt.dragEvent.overContainer !== sortable.containers[0] && sortable.getDraggableElementsForContainer(evt.dragEvent.overContainer).length === argumentCapacity) {
          evt.cancel();
        }
      });

      // accordian

      sortable.on('drag:stop', (evt) => {
        // update all lists if they have changes
        setTimeout(() => {
          this.updateStateBasedOnContainers();
        }, 50);

        // this.$refs.knowledgeVaultSide.updateData();
      });
    },
  },
  computed: {
    ...mapState([
      'knowledgeVaultSavedIds',
      'dilemmas',
      'currentDilemmaId',
      'selectedScenarioIndex',
    ]),
    showSidePanel() {
      return this.currentDilemmaId === null || this.currentDilemmaId === 'null';
    },
    scenarioTitle() {
      return strategyData?.[this.selectedScenarioIndex]?.title || '';
    },
    hasScenario() {
      return this.scenarioTitle !== '';
    },
    greenLineClasses() {
      const containerClasses = [];
      if (!this.showSidePanel) {
        containerClasses.push('no-side-panel');
      }
      if (!this.hasScenario) {
        containerClasses.push('no-scenario');
      }
      return containerClasses;
    },
  },
  mounted() {
    this.knowledgeVaultSavedIdsCopy = this.knowledgeVaultSavedIds;

    // Using next tick as dynamic refs are undefined before then
    this.$nextTick(() => {
      // const sideContainer = this.$refs.knowledgeVaultSide.$refs.knowledgeVaultSide;
      // this.containers.push(sideContainer);

      this.initiateDraggables();
    });
  },
};
</script>
<style lang="scss" scoped>
$sidePanelWidth: 350px;

.knowledge-vault-outer-wrapper {
  padding: 50px 8% 0px 8%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  .knowledge-vault-content {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #3E7784;
    z-index: 100000;
    overflow-y: hidden;
    padding-top: 84px;

    .back-button {
      position: absolute;
      font-size: 1.5em;
      color: #263250;
      left: 20px;
      top: 10px;
      cursor: pointer;
      -webkit-text-stroke: 1px #fff; /* width and color */
      transition: 0.1s transform;

      &:hover {
        transform: scale(1.2);
      }
    }

    .knowledge-vault-title-wrapper {
      position: absolute;
      top: 50px;
      left: 50px;
      display: inline-flex;

      .persistent-assistant-wrapper {
        margin-left: 25px;
      }
    }

    .knowledge-vault-scenario-title {
      position: absolute;
      left: 25px;
      p {
        font-size: 0.9rem !important;
      }
    }

    .green-line {
      position: relative;
      background-color: #5799A8;
      height: 25px;
      width: calc(100% - #{$sidePanelWidth} - 25px * 2);
      margin: 25px 25px 40px;

      &.no-side-panel {
        width: calc(100% - 25px * 2);
      }

      &.no-scenario {
        background-color: unset;
      }
    }

    .knowledge-vault-full-panel-wrapper {
      width: calc(100% - #{$sidePanelWidth});
      padding: 25px 25px 0 25px;
      height: calc(100% - 180px);

      &.no-side-panel {
        width: 100% !important;
      }

      .knowledge-vault-list-wrapper {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: #5799A8;
        border-radius: 15px 15px 15px 0px;
        padding-bottom: 150px;

        .knowledge-vault-items-wrapper {
          width: 100%;
          padding: 15px;
          padding-bottom: 45px;
          column-gap: 10px;
          row-gap: 35px;
          min-height: 100%;
          overflow-x: hidden;
          overflow-y: hidden;

          .knowledge-vault-item {
            margin: 10px 0 25px 0;

          }
        }
      }
    }
  }
}

</style>
