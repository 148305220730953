<template>
  <div class="data-six-pager-button-wrapper">
    <div v-if="highlightButtonClass" class="highlight-ping">
      <div class="highlight-ping-inner temporary-ping" :class="highlightClasses"/>
    </div>
    <div :id="'data-icon-button-' + iconPageId" class="data-six-pager-button tooltip-target" :class="buttonClass" @click="toggleDataOpen">
      <div v-if="dataNotEmpty">
        <span class="tooltiptext">{{ dataItem.name }}</span>
      </div>
    </div>
    <div class="content-wrapper" :class="getDataClasses">
      <div class="data-break-content-container">
        <div v-if="dataNotEmpty">
          <DataBreakModule ref="DataBreakModule"
                           :data-key="dataKey"
                           :data-name="dataItem.name"
                           :data-passage="dataItem.passage"
                           :data-type="dataType"
                           :show-shadow="true"
                           :main-vocabulary-key="mainVocabularyKey"
                           :vocabulary-keys="dataItem.vocabularyKeys"
                           :keep-open="true"
                           :data-open="dataOpen"
                           @openVocabulary="openVocabularyModule"
          />
        </div>
      </div>
      <div class="vocabulary-module-wrapper" :class="vocabularyModuleOpen ? '' : 'no-pointer-events'">
        <VocabularyModule :vocabulary-module-open="vocabularyModuleOpen"
                          :vocabulary-key="currentVocabularyKey"
                          :vocabulary-name="currentVocabularyName"
                          :vocabulary-insight="currentVocabularyInsights"
                          :vocabulary-bonus-insight="currentVocabularyBonusInsights"
                          @toggle="closeVocabularyModule"
        />
      </div>
    </div>
  </div>
</template>
<script>

import { mapState } from 'vuex';
import { nextTick } from 'vue';
import { UPDATE_TUTORIAL_ASSISTANT_STATUS } from '@/store/mutation-types';
import {
  DATA_TYPE_INCLUSIVENESS,
  DATA_TYPE_ECONOMIC_GROWTH,
  DATA_TYPE_ENVIRONMENTAL_SUSTAINABILITY,
  DATA_TYPE_FUNCTIONAL_ANALYSIS,
  DATA_TYPE_SOCIAL_SUSTAINABILITY,
} from '@/enum/DataTypes';
import VocabularyModule from '../DataBreak/VocabularyModule';
import DataBreakModule from '../DataBreak/DataBreakModule';
import textToHtml from '../../utils/textToHtml';

export default {
  name: 'Data6PagerButton',
  components: { DataBreakModule, VocabularyModule },
  props: {
    dataKey: {
      type: String,
      required: true,
    },
    openLeft: {
      type: Boolean,
      default: false,
    },
    openTop: {
      type: Boolean,
      default: false,
    },
    iconPageId: {
      type: Number,
      default: 0,
    },
  },
  emits: ['click', 'scrollToSaveButton'],
  data() {
    return {
      vocabularyModuleOpen: false,
      dataOpen: false,
      currentVocabularyName: '',
      currentVocabularyKey: '',
      currentVocabularyInsights: '',
      currentVocabularyBonusInsights: '',
      highlightButtonClass: false,
      type: 'Economic growth',
    };
  },
  computed: {
    ...mapState([
      'tutorialAssistantMessagesShown',
      'knowledgeVaultSavedIds',
      'argumentIndexesPerPolicyIndex',
      'expertData',
    ]),
    dataHasBeenSaved() {
      // it both have to check if it's been saved but also if it's been used as an argument.
      for (const argumentArrayKey in this.argumentIndexesPerPolicyIndex) {
        if (this.argumentIndexesPerPolicyIndex.hasOwnProperty(argumentArrayKey)) {
          const hasDataKey = this.argumentIndexesPerPolicyIndex[argumentArrayKey].includes(this.dataKey);
          if (hasDataKey) {
            return true;
          }
        }
      }

      return this.knowledgeVaultSavedIds.includes(this.dataKey);
    },
    dataNotEmpty() {
      return this.dataBreakData.hasOwnProperty(this.dataKey);
    },
    vocabularyData() {
      return this.expertData ? this.expertData.vocabulary : [];
    },
    dataBreakData() {
      return this.expertData ? this.expertData.dataBreakData : {};
    },
    dataItem() {
      return this.dataBreakData?.[this.dataKey] ?? {};
    },
    dataType() {
      return this.dataItem?.dataType ?? 'none';
    },
    mainVocabularyKey() {
      return this.dataItem?.mainTechnicalInsight ?? '';
    },
    buttonClass() {
      const buttonClass = [];

      if (this.dataHasBeenSaved) {
        buttonClass.push('greyed-out');
      }

      if (this.dataType === DATA_TYPE_SOCIAL_SUSTAINABILITY) {
        buttonClass.push('data-type-social-sustain');
      } else if (this.dataType === DATA_TYPE_ECONOMIC_GROWTH) {
        buttonClass.push('data-type-economic-growth');
      } else if (this.dataType === DATA_TYPE_FUNCTIONAL_ANALYSIS) {
        buttonClass.push('data-type-functional-analysis');
      } else if (this.dataType === DATA_TYPE_ENVIRONMENTAL_SUSTAINABILITY) {
        buttonClass.push('data-type-environ-sustain');
      } else if (this.dataType === DATA_TYPE_INCLUSIVENESS) {
        buttonClass.push('data-type-inclusiveness');
      } else {
        buttonClass.push('data-type-none');
      }

      return buttonClass;
    },
    highlightClasses() {
      const buttonClass = [];

      if (this.dataType === DATA_TYPE_SOCIAL_SUSTAINABILITY) {
        buttonClass.push('data-type-social-sustain');
      } else if (this.dataType === DATA_TYPE_ECONOMIC_GROWTH) {
        buttonClass.push('data-type-economic-growth');
      } else if (this.dataType === DATA_TYPE_FUNCTIONAL_ANALYSIS) {
        buttonClass.push('data-type-functional-analysis');
      } else if (this.dataType === DATA_TYPE_ENVIRONMENTAL_SUSTAINABILITY) {
        buttonClass.push('data-type-environ-sustain');
      } else if (this.dataType === DATA_TYPE_INCLUSIVENESS) {
        buttonClass.push('data-type-inclusiveness');
      } else {
        buttonClass.push('data-type-none');
      }

      return buttonClass;
    },
    getDataClasses() {
      const dataClasses = [];
      let openClass = '';

      if (this.dataOpen) {
        openClass += 'open-';
      } else {
        openClass += 'closed-';
      }
      if (this.openLeft) {
        openClass += 'left';
      } else {
        openClass += 'right';
      }
      if (this.openTop) {
        openClass += '-top';
      }

      dataClasses.push(openClass);

      if (this.openLeft) {
        dataClasses.push('content-wrapper-open-left');
      }

      return dataClasses;
    },
  },
  methods: {
    closeVocabularyModule() {
      this.vocabularyModuleOpen = false;
      this.currentVocabularyKey = null;
    },
    dataTextToHtml(text) {
      return textToHtml(text, this.$t);
    },
    highlightButton() {
      this.highlightButtonClass = false;
      nextTick(() => {
        this.highlightButtonClass = true;
      });
    },
    openVocabularyModule(vocabularyKey) {
      if (!this.vocabularyData.hasOwnProperty(vocabularyKey)) {
        console.error('No vocabulary key: ', vocabularyKey);
      }

      const vocabularyItem = this.vocabularyData[vocabularyKey];
      this.currentVocabularyName = vocabularyKey;
      this.currentVocabularyKey = vocabularyKey;
      this.currentVocabularyInsights = vocabularyItem.technicalInsight;
      this.currentVocabularyBonusInsights = vocabularyItem.bonusTechnicalInsight;

      this.vocabularyModuleOpen = true;
      if (!this.tutorialAssistantMessagesShown.technicalInsightExplanation) {
        setTimeout(() => {
          this.$root.sendAssistantMessagesByKey('technicalInsightExplanation');
          this.$store.commit(UPDATE_TUTORIAL_ASSISTANT_STATUS, 'technicalInsightExplanation');
        }, 250);
      }
    },
    toggleDataOpen() {
      if (!this.dataOpen) {
        this.$emit('click', this.dataKey);
        if (!this.tutorialAssistantMessagesShown.dataIconExplanation) {
          const dataBreakModuleSaveButtonRefY = this.$refs.DataBreakModule.getSaveSourceButtonYPosition();
          this.$emit('scrollToSaveButton', dataBreakModuleSaveButtonRefY);
          setTimeout(() => {
            this.$root.sendAssistantMessagesByKey('dataIconExplanation');
            this.$store.commit(UPDATE_TUTORIAL_ASSISTANT_STATUS, 'dataIconExplanation');
          }, 250);
        }
      }
      this.vocabularyModuleOpen = false;
      this.dataOpen = !this.dataOpen;
    },
    closeDataObject() {
      this.vocabularyModuleOpen = false;
      this.dataOpen = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/sass/variables.scss";
$buttonSize: 2.1vw;
$borderSize: 0.15vw;

  .data-six-pager-button-wrapper {
    width: $buttonSize;
    height: $buttonSize;
    position: relative;

     .highlight-ping {
       position: absolute;
       pointer-events: none;
       left: 50%;
       top: 50%;
       transform: translate(-50%, -50%);
       .highlight-ping-inner {
         width: $buttonSize * 2;
         height: $buttonSize * 2;
         border-radius: 50%;

         &.data-type-social-sustain {
           background-color: $colorDataTypeSocSus;
         }
         &.data-type-economic-growth {
           background-color: $colorDataTypeEcoGrow;
         }
         &.data-type-functional-analysis {
           background-color: $colorDataTypeFuncAna;
         }
         &.data-type-environ-sustain {
           background-color: $colorDataTypeEnvSus;
         }
         &.data-type-inclusiveness {
           background-color: $colorDataTypeInclu;
         }
         &.data-type-none {
           background-color: #fff;
         }
       }
     }

    .data-six-pager-button {
      cursor: pointer;
      background-repeat: no-repeat;
      background-size: 85%;
      background-position: 45% 45%;
      background-color: #4E7FDE;
      border: #343D4F solid $borderSize;
      border-radius: 50%;
      width: $buttonSize;
      height: $buttonSize;
      position: absolute;

      &.data-type-social-sustain {

        background-image: url("../../assets/img/ui/icon_social_sustainable.png");
        background-color: $colorDataTypeSocSus;

        &:hover {
          background-color: darken($colorDataTypeSocSus, 15%);
        }

        &:active {
          background-color: darken($colorDataTypeSocSus, 23%);
        }
      }
      &.data-type-economic-growth {

        background-image: url("../../assets/img/ui/icon_economic_growth.png");
        background-color: $colorDataTypeEcoGrow;

        &:hover {
          background-color: darken($colorDataTypeEcoGrow, 15%);
        }

        &:active {
          background-color: darken($colorDataTypeEcoGrow, 23%);
        }
      }
      &.data-type-functional-analysis {

        background-image: url("../../assets/img/ui/icon_economic_inclusive.png");
        background-color: $colorDataTypeFuncAna;

        &:hover {
          background-color: darken($colorDataTypeFuncAna, 15%);
        }

        &:active {
          background-color: darken($colorDataTypeFuncAna, 23%);
        }
      }
      &.data-type-environ-sustain {

        background-image: url("../../assets/img/ui/icon_environmental_sustainable.png");
        background-color: $colorDataTypeEnvSus;

        &:hover {
          background-color: darken($colorDataTypeEnvSus, 15%);
        }

        &:active {
          background-color: darken($colorDataTypeEnvSus, 23%);
        }
      }
      &.data-type-inclusiveness {

        background-image: url("../../assets/img/ui/icon_inclusive.png");
        background-color: $colorDataTypeInclu;

        &:hover {
          background-color: darken($colorDataTypeInclu, 15%);
        }

        &:active {
          background-color: darken($colorDataTypeInclu, 23%);
        }
      }
      &.data-type-none {
        background-color: #fff;

        &:hover {
          background-color: darken(#fff, 15%);
        }

        &:active {
          background-color: darken(#fff, 23%);
        }
      }

      &.greyed-out {
        filter: grayscale(90%);
      }
    }

    .content-wrapper {
      position: absolute;
      top: calc(-10px - #{$buttonSize});
      left: calc(10px + #{$buttonSize});
      width: 25vw;
      max-width: 350px;
      // height: 250px;
      z-index: 9999999;

      transition-property: all;
      transition-duration: 500ms;
      transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.275);

      &.content-wrapper-open-left {
        left: calc(-#{$buttonSize} - 23vw);
      }

      &.open-right {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
        -webkit-transform-origin: left;
        transform-origin: left;
        opacity: 1;
      }

      &.closed-right  {
        pointer-events: none;
        -webkit-transform: rotateY(-100deg);
        transform: rotateY(-100deg);
        -webkit-transform-origin: left;
        transform-origin: left;
        opacity: 0;
      }

      &.open-right-top {
        -webkit-transform: translateY(-90%) rotateY(0);
        transform: translateY(-90%) rotateY(0);
        -webkit-transform-origin: left;
        transform-origin: left;
        opacity: 1;
      }

      &.closed-right-top  {
        pointer-events: none;
        -webkit-transform: translateY(-90%) rotateY(-100deg);
        transform: translateY(-90%) rotateY(-100deg);
        -webkit-transform-origin: left;
        transform-origin: left;
        opacity: 0;
      }
      &.open-left {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
        -webkit-transform-origin: right;
        transform-origin: right;
        opacity: 1;
      }

      &.open-left-top {
        -webkit-transform: translateY(-90%) rotateY(0);
        transform: translateY(-90%) rotateY(0);
        -webkit-transform-origin: right;
        transform-origin: right;
        opacity: 1;
      }
      &.open-top {
        //top: calc(-10px - 2.1vw - 90vh)!important;
        transform: translateY(-90%);
      }

      &.closed-left-top  {
        pointer-events: none;
        -webkit-transform: translateY(-90%) rotateY(-100deg);
        transform: translateY(-90%) rotateY(-100deg);
        -webkit-transform-origin: right;
        transform-origin: right;
        opacity: 0;
      }

      &.closed-left  {
        pointer-events: none;
        -webkit-transform: rotateY(-100deg);
        transform: rotateY(-100deg);
        -webkit-transform-origin: right;
        transform-origin: right;
        opacity: 0;
      }

      .data-break-content-container {
        z-index: 1;
        position: relative;
        width: 100%;
        height: 100%;
      }

      .data-wrapper {
        .data-title-container {
          color: #fff;
          background-color: #263250;
          border-radius: 7px 7px 0 0;
          font-size: 16px;
          line-height: 1.2;
          width: 100%;
          padding: 10px;
          text-align: center;
          font-family: 'Fira Sans Condensed','Fira Sans', sans-serif;

          p {
            margin: 0;
          }
        }

        .data-content-container {
          color: #5B5B5B;
          background-color: #F8F8F8;
          padding: 12px 10px 17px 10px;
          border-radius: 0 0 7px 7px;
          font-weight: 400;
          font-size: 18px;
          line-height: 1.2;
        }
      }

      .vocabulary-module-wrapper {
        position: absolute;
        z-index: 1;
        top: 65px;
        right: 105%;
        width: 100%;
      }
    }
  }

</style>
