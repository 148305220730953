<template>
  <div class="">
    <div class="dilemma-buttons-wrapper d-flex justify-content-center bd-highlight">
      <div class="dilemma-background-color"/>
      <div class="p-0 flex-fill bd-highlight" v-for="(dilemma, i) in dilemmas" :key="i">
        <DilemmaButton v-if="!isTutorialDilemma(i)" :dilemma-question="getSpecificDilemmaQuestion(i)" :state="getDilemmaState(dilemma.dilemma)"
                       :policies="getPolicies(dilemma.dilemma, dilemma.policyUnlocks)"
                       @click="onClickDilemmaButton(dilemma.dilemma)"
        >
          {{ getDilemmaTitle(dilemma.title, dilemma.dilemma) }}
        </DilemmaButton>
      </div>
    </div>
<p>{{testText}}</p>
    <div class="strategy-buttons-wrapper d-flex justify-content-center bd-highlight">
      <div v-for="(strategy, i) in strategies" :key="i" class="p-0 flex-fill bd-highlight">
        <DilemmaButton :state="getStrategyState(i)"
                       :policies="getPoliciesAndStates(strategy.data)"
                       :is-strategy-button="true"
                       :is-clickable="canPickScenarios"
                       @click="onClickStrategyButton(i)"
        >
          {{ strategy.title }}
        </DilemmaButton>
      </div>
    </div>
  </div>
</template>
<script>

import { mapState } from 'vuex';
import strategyData from '@/data/strategyData.json';
import DilemmaButton from './DilemmaButton';
import * as DataUtil from '@/utils/dataUtil';
import { REPLAY_TUTORIAL, SET_CURRENT_DILEMMA_ID, SET_SELECTED_SCENARIO_INDEX } from '../../store/mutation-types';
import {
  STATUS_LOCKED,
  STATUS_UNAVAILABLE,
  STATUS_COMPLETED,
  STATUS_AVAILABLE,
} from '@/enum/UnlockedStatus.js';
import { getScenarioSuperPointSpecificTextAndPolicies } from '../../utils/dataUtil';

export default {
  name: 'DilemmaButtonDev',
  props: {
  },
  components: {
    DilemmaButton,
  },
  emits: ['click'],
  data() {
    return {
      tutorialModalOpen: false,
      strategies: strategyData,
      hasClickedStrategy: false,
      selectedStrategy: 0,
    };
  },
  computed: {
    ...mapState([
      'dilemmas',
      'dilemmaUnlockedStatus',
      'hasPlayedInformalPD',
    ]),
    dilemmaQuestions() {
      return DataUtil.getDilemmaQuestions();
    },
    testText() {
      return DataUtil.getScenarioSuperPointSpecificTextAndPolicies('hi', 1, 6, 0, this.$t);
    },
  },
  methods: {
    isTutorialDilemma(dilemmaId) {
      return dilemmaId === 'INTRO2';
    },
    getSpecificDilemmaQuestion(key) {
      return this.dilemmaQuestions?.[key] ?? '';
    },
    onClickDilemmaButton(dilemmaId) {
      // console.log("hello you pushed a dilemma button");
    },
    getDilemmaState(dilemmaId) {
      //console.log('get dilemma state', dilemmaId, this.dilemmaUnlockedStatus?.[dilemmaId.toString()]?.status);
      return this.dilemmaUnlockedStatus?.[dilemmaId.toString()]?.status || STATUS_UNAVAILABLE;
    },
    getDilemmaTitle(dilemmaTitle, dilemmaId) {
      return this.getDilemmaState(dilemmaId) !== STATUS_UNAVAILABLE ? dilemmaTitle : 'Unknown';
    },
    getPolicies(dilemmaId, policies) {
      const policyArray = [];

      for (const key in policies) {
        const policyId = key.toString();
        const tempPolicy = policies[key];
        tempPolicy.state = this.dilemmaUnlockedStatus?.[dilemmaId.toString()]?.policies?.[policyId] || STATUS_LOCKED;
        policyArray.push(policies[key]);
      }

      return policyArray;
    },
    startStrategy() {
      this.$store.commit(SET_CURRENT_DILEMMA_ID, null);
      this.$router.push({ name: VIEW_EVAL_AND_PD });
    },
    openTutorialModal() {
      this.tutorialModalOpen = true;
    },
    goToTutorial() {
      this.$store.commit(REPLAY_TUTORIAL);
      this.$router.push({ name: VIEW_DILEMMA });
    },
    closeTutorialModal() {
      this.tutorialModalOpen = false;
    },
    getStrategyState(strategyIndex) {
      if (this.canPickScenarios && !this.hasClickedStrategy) {
        return STATUS_COMPLETED;
      }
      return this.selectedStrategy === strategyIndex ? STATUS_AVAILABLE : STATUS_COMPLETED;
    },
    onClickStrategyButton(i) {
      this.selectedStrategy = i;
    },
    getPoliciesAndStates(policyIdentification) {
      const policiesAndStates = [];

      for (const policyIdentifier of policyIdentification) {
        const tempPolicy = {};

        const dilemmaId = policyIdentifier.dilemmaId.toString();
        const policyId = policyIdentifier.policyId.toString();

        // find policyName
        console.log("the policy name");
        const policyTitle = DataUtil.getPolicyOptionTitle(dilemmaId, policyId);
        tempPolicy.title = policyTitle;

        // find policyState
        const policyState = this.dilemmaUnlockedStatus?.[dilemmaId].policies?.[policyId];
        tempPolicy.state = policyState;

        policiesAndStates.push(tempPolicy);
      }

      return policiesAndStates;
    },
  },
};
</script>
<style lang="scss" scoped>
.home-view-wrapper {
  color: #ffffff;
  padding: 70px;

  .tutorial-button-wrapper {
    position: absolute;
    top: 40px;
    right: 70px;
    width: 168px;
    height: 70px;
  }

  .reset-button {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .title-wrapper {
    padding-bottom: 50px;
  }

  .dilemma-buttons-wrapper {
    position: relative;
    width: 100%;
    height: 65vh;
    max-width: 1210px;
    padding-left: 10px;
    padding-top:10px;
    column-gap: 10px;
    margin-left: auto;
    margin-right: auto;
    overflow-x: auto;
    border-radius: 10px;

    .dilemma-background-color {
      background: linear-gradient(180deg, #839FA2 75.3%, rgba(131, 159, 162, 0) 100%);
      opacity: 0.52;
      position: absolute;
      border-radius: 10px;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 0;
    }
  }

  .bottom-button-wrapper {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 15px;

    .info-text {
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
</style>
