<template>
  <div class="tutorial-highlight-overlay-wrapper tutorial-blackness">
    <div ref="hole" class="hole" :style="highlightStyle" :class="highlightClasses" @click="clickTutorialHighlight"/>
  </div>
</template>
<script>

export const TYPE_SQUARE = 'square';
export const TYPE_SLIGHTLY_ROUNDED = 'slightly-rounded';
export const TYPE_ROUNDED = 'rounded';
export const TYPE_CIRCLE = 'circle';

export default {
  name: 'TutorialHighlightOverlay',
  props: {
    highlightId: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: TYPE_SQUARE,
    },
    offset: {
      type: Number,
      default: 0,
    },
    interactionRequired: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click', 'clickHighlight'],
  data() {
    return {
      lowestValue: 25,
      highestValue: 100,
      showAnimation: false,
      showInteractionAnimation: false,
      highlightStyle: {},
    };
  },
  computed: {
    highlightElement() {
      if (!this.highlightId) {
        return null;
      }
      const highlightElement = document.getElementById(this.highlightId);

      return highlightElement || null;
    },
    highlightClasses() {
      const highlightClasses = [];

      // whether it should be round, square or rounded
      highlightClasses.push(this.type);

      if (this.showInteractionAnimation) {
        highlightClasses.push('hole-interaction-animation');
      }

      if (this.showAnimation && !this.showInteractionAnimation) {
        highlightClasses.push('hole-animation');
      }

      if (this.interactionRequired) {
        highlightClasses.push('button-look');
      }

      return highlightClasses;
    },
    isOnboarded() {
      return this.onBoardPercentage >= 100;
    },
  },
  methods: {
    restartAnimation() {
      this.showAnimation = false;
      setTimeout(() => {
        this.showAnimation = true;
      }, 50);
    },

    calculateHighlightStyle() {
      const style = {};

      if (!this.highlightElement || !this.highlightId) {
        style.height = '0px';
        style.width = '0px';
        this.highlightStyle = style;
        return style;
      }

      const viewportOffset = this.highlightElement.getBoundingClientRect();

      let {
        top, left, width, height,
      } = viewportOffset;

      height += this.offset * 2;
      width += this.offset * 2;
      top -= this.offset;
      left -= this.offset;

      style.top = `${top}px`;
      style.left = `${left}px`;
      style.height = `${height}px`;
      style.width = `${width}px`;

      this.highlightStyle = style;
      return style;
    },
    clickTutorialHighlight() {
      if (!this.interactionRequired) {
        return;
      }
      this.$emit('clickHighlight');
    },
  },
  watch: {
    offset() {
      this.calculateHighlightStyle();
    },
    highlightId() {
      this.calculateHighlightStyle();
      this.restartAnimation();
    },
    interactionRequired() {
      this.showInteractionAnimation = this.interactionRequired;

      /* if (this.interactionRequired) {
        this.restartAnimation();
      }*/
    },
  },
  mounted() {
    this.calculateHighlightStyle();
    this.restartAnimation();
    window.addEventListener('resize', this.calculateHighlightStyle);
  },
  unmounted() {
    window.removeEventListener('resize', this.calculateHighlightStyle);
  },
};
</script>
<style lang="scss" scoped>

.tutorial-highlight-overlay-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;

  .square {
    border-radius: 0 !important;
  }

  .slightly-rounded {
    border-radius: 8px !important;
  }

  .rounded {
    border-radius: 12px !important;
  }

  .circle {
    border-radius: 50% !important;
  }
}

.tutorial-blackness {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: 10000;
  // background-color: rgba(0,0,0,0.7);

  .hole {
    position: absolute;
    width: 0;
    height: 0;
    box-shadow: 0 0 0 9999px rgba(0,0,0,0.7);
  }
  .hole-animation {
    -webkit-animation: jello-horizontal 0.9s 1 both;
    animation: jello-horizontal 0.9s 1 both;
  }
  .hole-interaction-animation {
    -webkit-animation: jello-horizontal 2s infinite both;
    animation: jello-horizontal 2s infinite both;
  }

  .button-look {
    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  .hole-study-brief {
    top: 180px;
    right: 0px;
    border-radius: 12px;
    width: 192px;
    height: 21vh;
    max-height: 192px;
  }

  .hole-technical-insights {
    top: calc(180px + 21vh + 5px);
    right: 0px;
    border-radius: 12px;
    width: 192px;
    height: 21vh;
    max-height: 192px;
  }

  .hole-context-info {
    // @include contextButtonTop;
    right: 0px;
    border-radius: 12px;
    width: 192px;
    height: 21vh;
    max-height: 192px;
  }

  .hole-time-points {
    top: 10px;
    right: 10px;
    border-radius: 50%;
    width: 135px;
    height: 135px;
  }
}

/**
 * ----------------------------------------
 * animation jello-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

</style>
