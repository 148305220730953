export const DATA_TYPE_ECONOMIC_GROWTH = 'Economic growth';
export const DATA_TYPE_SOCIAL_SUSTAINABILITY = 'Social sustainability';
export const DATA_TYPE_ENVIRONMENTAL_SUSTAINABILITY = 'Environmental sustainability';
export const DATA_TYPE_FUNCTIONAL_ANALYSIS = 'Functional analysis';
export const DATA_TYPE_INCLUSIVENESS = 'Inclusiveness';

export const DATA_TYPE_LOCALIZATION_KEY = {
  [DATA_TYPE_ECONOMIC_GROWTH]: 'socialSustain',
  [DATA_TYPE_SOCIAL_SUSTAINABILITY]: 'economicGrowth',
  [DATA_TYPE_ENVIRONMENTAL_SUSTAINABILITY]: 'functionalAnalysis',
  [DATA_TYPE_FUNCTIONAL_ANALYSIS]: 'environSustain',
  [DATA_TYPE_INCLUSIVENESS]: 'inclusiveness',
}
