<template>
  <div
    class="stakeholder-group"
    :class="[useFilter ? 'stakeholder-group-use-filter' : '', 'stakeholder-group-count-' + stakeholderPictureIds.length]"
    :style="{ backgroundColor: backgroundColor }"
  >
    <div v-for="(item, i) in stakeholderPictureIds"
         :key="i"
         class="stakeholder"
         :class="getStakeholderPositionClass(i)">
      <div class="on-boarding-bar" v-if="useFilter && shouldShowOnBoardBar(i)">
        <div class="on-boarding-bar-inner">
          <OnboardingBar :on-board-percentage="stakeHolderOnBoardingPercentages[i]" :stakeholder-id="i"/>
        </div>
      </div>
      <NoDragImage :src="imgSrc(stakeholderPictureIds[i])"/>
    </div>
  </div>
</template>
<script>

import NoDragImage from "@/components/common/NoDragImage";
import { mapState } from 'vuex';
import { nextTick } from 'vue';
import OnboardingBar from '../OnboardingBar/OnboardingBar';
import stakeholderIdShouldBeOnboarded from '@/data/stakeholderIdShouldBeOnboarded.json';

export default {
  name: 'StakeholderGroup',
  components: { OnboardingBar, NoDragImage },
  props: {
    stakeholders: {
      type: Array,
      required: true,
    },
    currentStakeholderIndex: {
      type: Number,
      default: 0,
    },
    useFilter: {
      type: Boolean,
      default: true,
    },
    backgroundColor: {
      type: String,
      default: 'transparent',
    },
    displayDebugInfo: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    stakeholders: {
      deep: true,
      handler() {
        this.recalculateStakeholderPositions();
      },
    },
    currentStakeholderIndex() {
      this.recalculateStakeholderPositions();
    },
    stakeholderOnboardStatus: {
      handler(newValue, oldValue) {
        this.updateStakeHolderOnBoardingPercentages();
      },
      deep: true,
    },
  },
  data() {
    return {
      stakeholderPositions: [],
      stakeHolderOnBoardingPercentages: [],
    };
  },
  mounted() {
    this.recalculateStakeholderPositions();
    nextTick(() => {
      this.updateStakeHolderOnBoardingPercentages();
    });
  },
  emits: ['click'],
  methods: {
    shouldShowOnBoardBar(i) {
      const stakeholderId = this.stakeholders[i].id;
      return stakeholderIdShouldBeOnboarded.includes(stakeholderId);
    },
    recalculateStakeholderPositions() {
      const stakeholderCount = this.stakeholders.length;

      let stakeholderPositions = [];

      for (let i = 0; i < stakeholderCount; i++) {
        stakeholderPositions.push(((stakeholderCount - 1 - i) + this.currentStakeholderIndex) % stakeholderCount);
      }

      // Move first element to end
      stakeholderPositions.push(stakeholderPositions.shift());

      this.stakeholderPositions = stakeholderPositions;
    },

    imgSrc(imageId) {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      const imgSrc = require(`../../assets/img/stakeholders/${imageId}.png`);

      return imgSrc;
    },
    getStakeholderPositionClass(i) {
      const classes = [];

      if (i === this.currentStakeholderIndex) {
        classes.push('stakeholder-active');
      } else {
        classes.push('stakeholder-inactive');
      }

      classes.push(`stakeholder-position-${this.stakeholderPositions[i]}`);

      return classes;
    },
    updateStakeHolderOnBoardingPercentages() {
      const onBoardingPercentages = [];

      for (const stakeholder of this.stakeholders) {
        const stakeholderId = stakeholder.id;
        if (!stakeholderId) {
          console.error('no stakeholder id for', stakeholder);
        }

        let percentage = 0;
        if (this.stakeholderOnboardStatus.hasOwnProperty(stakeholderId)) {
          // times 50 because there's only 2 steps
          percentage = this.stakeholderOnboardStatus[stakeholderId] * 50;
        }
        onBoardingPercentages.push(percentage);
      }

      this.stakeHolderOnBoardingPercentages = onBoardingPercentages;
    },
  },
  computed: {
    ...mapState([
      'stakeholderOnboardStatus',
    ]),
    stakeholderPictureIds() {
      const stakeholderPictureIds = [];

      for (let i = 0; i < this.stakeholders.length; i++) {
        const pictureId = this.stakeholders[i].pictureId;

        if (pictureId) {
          stakeholderPictureIds.push(pictureId);
        }
      }

      return stakeholderPictureIds;
    },
  },
};
</script>
<style lang="scss" scoped>
$stakeholderInactiveScale: 0.85;

.stakeholder-group, .stakeholder {
  height: 100%;
  max-width: 100%;
}

.stakeholder-group {
  position: relative;

  &.stakeholder-group-use-filter {
    .stakeholder-inactive {
      filter: blur(4px) grayscale(0.8);
    }
  }

  .invisible {
    opacity: 0 !important;
  }

  .on-boarding-bar {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 40%;
    top: -80px;

    .on-boarding-bar-inner {
      opacity: 1;
      transition: opacity 550ms ease-in-out;
      width: 45%;
      height: 100%;
      position: relative;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .stakeholder {
    position: absolute;
    z-index: 100;
    transition-timing-function: ease-in-out;
    transition-duration: 250ms;
    transition-delay: 900ms;

    img {
      z-index: 100;
      position: relative;
      max-height: 100%;
      //max-width: 100%;
      //margin-top: 100%;
    }

    &.stakeholder-active {
      z-index: 3;
    }

    &.stakeholder-inactive {
      z-index: 1;
    }
  }

  &.stakeholder-group-count-2 {
    .stakeholder {
      &.stakeholder-position-0 {
        left: 0;
        transform: scale($stakeholderInactiveScale);

        &.stakeholder-active {
          transform: scale(1) !important;
        }
      }

      &.stakeholder-position-1 {
        left: 100%;
        transform: translateX(-100%) scale($stakeholderInactiveScale);

        &.stakeholder-active {
          transform: translateX(-100%) scale(1) !important;
        }
      }
    }
  }

  &.stakeholder-group-count-3 {
    .stakeholder {
      &.stakeholder-position-0 {
        left: 0;
        transform: scale($stakeholderInactiveScale);
      }

      &.stakeholder-position-1 {
        left: 50%;
        transform: translateX(-50%);
      }

      &.stakeholder-position-2 {
        left: 100%;
        transform: translateX(-100%) scale($stakeholderInactiveScale);
      }
    }
  }
}

.debug-info {
  position: absolute;
  top: 0;
  left: 0;

  z-index: 10000;
  font-weight: bold;
  background: rgba(255, 255, 255, .6);
  padding: 0 2px;
}
</style>
