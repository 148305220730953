<template>
  <div class="data-break-container" :class="containerClasses">
    <div v-if="dataBreakWindowOpen" class="backdrop" @click.stop="onClickToggle" />
    <div id="data-break-button" class="data-break-button " @click.stop="onClickToggle">
      <div class="document-icon-wrapper" :class="buttonIconClasses">
        <NoDragImage class="document-icon-image" :src="getImageSrcDocument()" />
      </div>
      <h5>{{ $t('misc.vca4dData') }}</h5>
    </div>
    <div class="data-break-content-container">
      <h3 class="data-break-title">
        {{ $t('misc.dataCaps') }}
      </h3>
      <div v-if="dataNotEmpty">
        <div v-for="(dataItem, i) in dataBreakDataObject" :key="i" class="data-wrapper">
          <DataBreakModule :data-key="i"
                           :data-name="dataItem.name"
                           :data-passage="dataItem.passage"
                           :main-vocabulary-key="dataItem?.mainTechnicalInsight ?? ''"
                           :vocabulary-keys="dataItem.vocabularyKeys"
                           :data-type="dataItem.dataType"
                           @openVocabulary="openVocabularyModule"
          />
        </div>
      </div>
    </div>
    <div class="vocabulary-module-wrapper" :class="vocabularyModuleOpen ? '' : 'no-pointer-events'">
      <VocabularyModule :vocabulary-module-open="vocabularyModuleOpen" :vocabulary-key="currentVocabularyKey" :vocabulary-name="currentVocabularyName"
                        :vocabulary-insight="currentVocabularyInsights" :vocabulary-bonus-insight="currentVocabularyBonusInsights"
                        @toggle="closeVocabularyModule"
      />
    </div>
  </div>
</template>
<script>

import { mapState } from 'vuex';
import textToHtml from '@/utils/textToHtml';
import DataBreakModule from '@/components/DataBreak/DataBreakModule';
import VocabularyModule from '@/components/DataBreak/VocabularyModule';
import { nextTick } from 'vue';
import NoDragImage from '../common/NoDragImage';

export default {
  name: 'DataBreakPanel',
  components: {
    NoDragImage,
    VocabularyModule,
    DataBreakModule,
  },
  props: {
    dataBreakDataKeys: {
      type: Array,
      required: true,
    },
    dataBreakWindowOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggle'],
  data() {
    return {
      vocabularyModuleOpen: false,
      currentVocabularyName: '',
      currentVocabularyKey: '',
      currentVocabularyInsights: '',
      currentVocabularyBonusInsights: '',
      iconShouldWobble: false,
    };
  },
  computed: {
    ...mapState([
      'expertData',
    ]),
    vocabularyData() {
      return this.expertData ? this.expertData.vocabulary : [];
    },
    dataBreakData() {
      return this.expertData ? this.expertData.dataBreakData : {};
    },
    dataNotEmpty() {
      return this.dataBreakDataKeys && this.dataBreakDataKeys.length !== 0;
    },
    dataBreakDataObject() {
      const dataBreakDataObject = {};

      if (!this.dataBreakDataKeys) {
        return dataBreakDataObject;
      }

      for (const key of this.dataBreakDataKeys) {
        if (this.dataBreakData?.[key]) {
          dataBreakDataObject[key] = this.dataBreakData[key];
        } else {
          console.log('KEY NOT FOUND: ', key);
        }
      }
      return dataBreakDataObject;
    },
    containerClasses() {
      const containerClasses = [];
      if (this.dataBreakWindowOpen) {
        containerClasses.push('data-break-window-open');
      }
      if (!this.dataBreakWindowOpen) {
        containerClasses.push('data-break-window-closed');
      }
      if (!this.dataNotEmpty) {
        containerClasses.push('data-break-window-hidden');
      }
      return containerClasses;
    },
    buttonIconClasses() {
      const containerClasses = [];
      if (this.iconShouldWobble) {
        containerClasses.push('wobble-ver-right');
      }
      return containerClasses;
    },
  },
  watch: {
    dataBreakDataKeys(newValue, oldValue) {
      if (this.dataNotEmpty) {
        this.iconWobble();
      }

      if (!newValue || !oldValue) {
        return;
      }

      if (newValue.length !== oldValue.length) {
        this.iconWobble();
      }
    },
  },
  methods: {
    onClickToggle() {
      this.$emit('toggle');
      if (this.dataBreakWindowOpen) {
        this.closeVocabularyModule();
      }
    },
    closeVocabularyModule() {
      this.vocabularyModuleOpen = false;
      this.currentVocabularyKey = null;
    },
    iconWobble() {
      this.iconShouldWobble = false;
      nextTick(() => {
        this.iconShouldWobble = true;
      });
    },
    dataTextToHtml(text) {
      return textToHtml(text, this.$t);
    },
    openVocabularyModule(vocabularyKey) {
      if (!this.vocabularyData.hasOwnProperty(vocabularyKey)) {
        console.error('No vocabulary key: ', vocabularyKey);
      }

      const vocabularyItem = this.vocabularyData[vocabularyKey];
      this.currentVocabularyName = vocabularyKey;
      this.currentVocabularyKey = vocabularyKey;
      this.currentVocabularyInsights = vocabularyItem.technicalInsight;
      this.currentVocabularyBonusInsights = vocabularyItem.bonusTechnicalInsight;

      this.vocabularyModuleOpen = true;
    },
    getImageSrcDocument() {
      const images = require.context('../../assets/img/ui');
      const imgSrc = images('./document1.png');
      return imgSrc;
    },
  },
};
</script>
<style lang="scss" scoped>
$dataButtonWidth: 162px;

.data-break-container {
  position: absolute;
  width: 20%;
  min-width: 200px;
  right: 0;
  top: 0;
  height: 100%;
  transform: translateX(100%);
  padding: 0;
  transition-property: transform;
  transition-duration: 350ms;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.275);

  .no-pointer-events {
    pointer-events: none;
  }

  .backdrop {
    z-index: 0;
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    right: 0;
  }

  .data-break-title {
    text-align: center;
  }

  &.data-break-window-open {
    transform: translateX(0%);
  }

  &.data-break-window-closed {
    .vocabulary-module-wrapper {
      pointer-events: none;
    }
  }

  &.data-break-window-hidden {
    transform: translateX(calc(#{$dataButtonWidth} + 110%));
    pointer-events: none;
  }

  .data-break-button {
    width: $dataButtonWidth;
    z-index: 1;
    background-color: #005C5C;
    position: absolute;
    top: 180px;
    transform: translate(-99%, 0);
    border-radius: 15px 0 0 15px;
    cursor: pointer;
    box-shadow: -5px 13px 53px 0px rgba(0,0,0,0.75);

    .document-icon-wrapper {
      padding: 10px 0px 0;
      width: 150px;
      cursor: pointer;
      .document-icon-image {
        //width: 100%;
      }

      img {
        width: 100%;
      }
    }

    h5 {
      line-height: 1;
      padding-top: 0;
      position: relative;
      text-align: center;
      margin: 15px;
    }
  }

  .data-break-content-container {
    z-index: 1;
    position: relative;
    padding: 30px 10px;
    background: linear-gradient(170deg, rgba(0,92,92,1) 8%, rgba(44,44,44,1) 100%);
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }

  .data-wrapper {
    .data-title-container {
      color: #fff;
      background-color: #263250;
      border-radius: 7px 7px 0 0;
      font-size: 16px;
      line-height: 1.2;
      width: 100%;
      margin-top: 15px;
      padding: 10px;
      text-align: center;
      font-family: 'Fira Sans Condensed','Fira Sans', sans-serif;

      p {
        margin: 0;
      }
    }

    .data-content-container {
      color: #5B5B5B;
      background-color: #F8F8F8;
      padding: 12px 10px 17px 10px;
      border-radius: 0 0 7px 7px;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.2;
    }
  }

  .vocabulary-module-wrapper {
    position: absolute;
    z-index: 1;
    top: 65px;
    right: 105%;
    width: 100%;
  }
}
</style>
