<template>
  <div class="tutorial-outro-container">
    <div class="content-height-wrapper">
      <div class="content-wrapper">
        <div class="text-wrapper">
          <p>
            {{ $t('dilemmaIntroView.text1') }}
            <br><br>
            {{ $t('dilemmaIntroView.text2') }}
            <br><br>
            {{ $t('dilemmaIntroView.text3') }}
            <br><br>
            {{ $t('dilemmaIntroView.text4') }}
          </p>
        </div>
      </div>
    </div>
    <div class="button-wrapper d-flex">
      <AppButton :color="'light_grey'" size="fit" @click="toOverview">
        {{ $t('dilemmaIntroView.buttonText') }}
      </AppButton>
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/common/AppButton';
import { VIEW_DILEMMA } from '@/enum/Views';
import { SET_BACKGROUND } from '@/store/mutation-types';

export default {
  name: 'DilemmaIntroView',
  components: { AppButton },
  mounted() {
    this.$store.commit(SET_BACKGROUND, 'blue-gondwana');
  },
  methods: {
    toOverview() {
      this.$router.push({ name: VIEW_DILEMMA });
    },
  },
};
</script>

<style lang="scss" scoped>
.tutorial-outro-container {
  color: #ffffff;
  font-weight: 700;
  height: 100%;
  width: 100%;
  overflow-y: hidden;

  .content-height-wrapper {
    padding: 70px;
    height: calc(100vh - 110px);
    max-height: calc(100vh - 110px);
    overflow-y: auto;

    .content-wrapper {
      width: 60%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 5%;
      padding: 0 55px;

      .title-wrapper {
        text-align: center;
        font-size: 32px;
        margin-bottom: 45px;
      }
    }
  }

  .button-wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;
    z-index: 200;
  }
}

</style>
