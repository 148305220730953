import localizationExpertData from '@/data/expertData';
import {
  createDilemmaStakeholderProgressionStatusArray,
  createDilemmaUnlockedStatusObject,
  createTutorialAssistantMessagesShownObject,
} from '@/utils/dataUtil';
import { VIEW_EVAL_AND_PD, VIEW_INTRO } from '@/enum/Views';
import {
  INITIALIZE_STORE, RESET_GAME,
  SET_BACKGROUND,
  SET_CURRENT_DILEMMA_ID,
  SET_DILEMMA_UNLOCKED_STATUS,
  SET_CURRENT_STAKEHOLDER_ID,
  SET_ALL_DILEMMA_UNLOCKED_STATUS,
  SET_POLICY_UNLOCKED_STATUS,
  SET_ALL_DILEMMA_STAKEHOLDER_PROGRESS_STATUS,
  SET_DILEMMA_STAKEHOLDER_PERSON_PROGRESS_STATUS,
  SET_DILEMMA_STAKEHOLDER_DOCUMENT_PROGRESS_STATUS,
  ADD_ARGUMENT_PERSON_PROGRESSION,
  UPDATE_ONBOARDING_STATUS,
  SET_CURRENT_INTRO_PAGE_INDEX,
  SET_DYNAMIC_STORE_VALUE,
  ORDER_VCA4D_MATERIAL,
  SET_CURRENT_DATA_BREAK_DATA,
  FINISH_INFORMAL_PD,
  SET_INFORMAL_PD_PAGE_INDEX,
  SET_EVAL_AND_PD_PAGE_INDEX,
  SET_IDP_OPPORTUNITY_ACCEPTED,
  SET_VARIABLE_TYPE_SCENARIO_1_SEEN,
  SET_VARIABLE_TYPE_SCENARIO_2_SEEN,
  SET_VARIABLE_TYPE_SCENARIO_3_SEEN,
  SET_SELECTED_SCENARIO_INDEX,
  SET_DEAL_ACCEPTED,
  SET_EVIDENCE_HARVEST_POINTS,
  SET_EVIDENCE_TRANSFORM_POINTS,
  ADD_EVIDENCE_HARVEST_POINTS,
  ADD_EVIDENCE_TRANSFORM_POINTS,
  ADD_EVIDENCE_BOSS_FIGHT_POINTS,
  SET_STUDY_BRIEF_TAB_INDEX,
  SET_TUTORIAL_STEP_INDEX, RESET_TO_BOSS_FIGHT,
  SET_STAKEHOLDER_DIALOGUE_HISTORY,
  SET_IS_GAME_REPLAY,
  SET_ROUTER_CURRENT_PAGE,
  CHANGE_LANGUAGE,
  CHANGE_IS_LANGUAGE_SELECT_PAGE,
} from '../mutation-types';

export default {
  [INITIALIZE_STORE]: (state) => {
    // TODO REMOVE THIS
    // const introFlowIndex = 7;

    if (localStorage.getItem('currentLocale')) {
      state.currentLocale = localStorage.getItem('currentLocale');
    }
    if (localStorage.getItem('expertData')) {
      state.expertData = JSON.parse(localStorage.getItem('expertData'));
    }
    if (localStorage.getItem('currentRouterPage')) {
      state.currentRouterPage = localStorage.getItem('currentRouterPage');
    }
    if (localStorage.getItem('currentDilemmaId')) {
      state.currentDilemmaId = localStorage.getItem('currentDilemmaId');
    }
    if (localStorage.getItem('isGameReplay')) {
      state.isGameReplay = JSON.parse(localStorage.getItem('isGameReplay'));
    }
    if (localStorage.getItem('currentStakeholderId')) {
      state.currentStakeholderId = parseInt(localStorage.getItem('currentStakeholderId'), 10);
    }
    if (localStorage.getItem('currentIntroPageIndex')) {
      state.currentIntroPageIndex = parseInt(localStorage.getItem('currentIntroPageIndex'), 10);
      // state.currentIntroPageIndex = introFlowIndex;
    }
    if (localStorage.getItem('currentEvalAndPDPageIndex')) {
      state.currentEvalAndPDPageIndex = parseInt(localStorage.getItem('currentEvalAndPDPageIndex'), 10);
    }
    if (localStorage.getItem('currentInformalPDPageIndex')) {
      state.currentInformalPDPageIndex = parseInt(localStorage.getItem('currentInformalPDPageIndex'), 10);
    }
    if (localStorage.getItem('idpOpportunityAccepted')) {
      state.idpOpportunityAccepted = JSON.parse(localStorage.getItem('idpOpportunityAccepted'));
    }
    if (localStorage.getItem('currentIntroUnlockPageIndex')) {
      state.currentIntroUnlockPageIndex = parseInt(localStorage.getItem('currentIntroUnlockPageIndex'), 10);
    }
    if (localStorage.getItem('playerName')) {
      state.playerName = localStorage.getItem('playerName');
    }
    if (localStorage.getItem('playerSurname')) {
      state.playerSurname = localStorage.getItem('playerSurname');
    }
    if (localStorage.getItem('playerPosition')) {
      state.playerPosition = localStorage.getItem('playerPosition');
    }
    if (localStorage.getItem('playerLocation')) {
      state.playerLocation = localStorage.getItem('playerLocation');
    }
    if (localStorage.getItem('selectedScenarioIndex')) {
      state.selectedScenarioIndex = parseInt(localStorage.getItem('selectedScenarioIndex'), 10);
    }

    // points
    if (localStorage.getItem('onBoardingPoints')) {
      state.onBoardingPoints = localStorage.getItem('onBoardingPoints');
    }
    if (localStorage.getItem('evidencePointsHarvestData')) {
      state.evidencePointsHarvestData = parseInt(localStorage.getItem('evidencePointsHarvestData'), 10);
    }
    if (localStorage.getItem('evidencePointsTransformData')) {
      state.evidencePointsTransformData = parseInt(localStorage.getItem('evidencePointsTransformData'), 10);
    }
    if (localStorage.getItem('evidencePointsBossFight')) {
      state.evidencePointsBossFight = parseInt(localStorage.getItem('evidencePointsBossFight'), 10);
    }
    if (localStorage.getItem('onBoardingPoints')) {
      state.onBoardingPoints = localStorage.getItem('onBoardingPoints');
    }

    // tutorial
    if (localStorage.getItem('tutorialStepIndex')) {
      state.tutorialStepIndex = JSON.parse(localStorage.getItem('tutorialStepIndex'));
    }
    if (localStorage.getItem('vca4dDocumentsOrdered')) {
      state.vca4dDocumentsOrdered = JSON.parse(localStorage.getItem('vca4dDocumentsOrdered'));
    }
    if (localStorage.getItem('hasPlayedInformalPD')) {
      state.hasPlayedInformalPD = JSON.parse(localStorage.getItem('hasPlayedInformalPD'));
    }
    if (localStorage.getItem('tutorialAssistantMessagesShown')) {
      state.tutorialAssistantMessagesShown = JSON.parse(localStorage.getItem('tutorialAssistantMessagesShown'));
    }
    if (localStorage.getItem('tutorialCompleted')) {
      state.tutorialCompleted = JSON.parse(localStorage.getItem('tutorialCompleted'));
    }

    if (localStorage.getItem('knowledgeVaultSavedIds')) {
      state.knowledgeVaultSavedIds = JSON.parse(localStorage.getItem('knowledgeVaultSavedIds'));
    }

    if (localStorage.getItem('argumentIndexesPerPolicyIndex')) {
      state.argumentIndexesPerPolicyIndex = JSON.parse(localStorage.getItem('argumentIndexesPerPolicyIndex'));
    } else {
      const argumentIndexesPerPolicyIndex = {
        0: [],
        1: [],
        2: [],
        3: [],
      };
      localStorage.setItem('argumentIndexesPerPolicyIndex', JSON.stringify(argumentIndexesPerPolicyIndex));
      state.argumentIndexesPerPolicyIndex = argumentIndexesPerPolicyIndex;
    }

    if (localStorage.getItem('argumentsUsedInBossFight')) {
      state.argumentsUsedInBossFight = JSON.parse(localStorage.getItem('argumentsUsedInBossFight'));
    } else {
      const argumentsUsedInBossFight = {
        1: null,
        2: null,
        3: null,
        4: null,
      };
      localStorage.setItem('argumentsUsedInBossFight', JSON.stringify(argumentsUsedInBossFight));
      state.argumentsUsedInBossFight = argumentsUsedInBossFight;
    }

    // dilemma progression arrays
    if (localStorage.getItem('dilemmaUnlockedStatus')) {
      state.dilemmaUnlockedStatus = JSON.parse(localStorage.getItem('dilemmaUnlockedStatus'));
    } else {
      const dilemmaUnlockedStatus = createDilemmaUnlockedStatusObject();
      localStorage.setItem('dilemmaUnlockedStatus', JSON.stringify(dilemmaUnlockedStatus));
      state.dilemmaUnlockedStatus = dilemmaUnlockedStatus;
    }

    if (localStorage.getItem('dilemmaStakeholderProgressionStatus')) {
      state.dilemmaStakeholderProgressionStatus = JSON.parse(localStorage.getItem('dilemmaStakeholderProgressionStatus'));
    } else {
      const dilemmaStakeholderProgressionStatus = createDilemmaStakeholderProgressionStatusArray();
      localStorage.setItem('dilemmaStakeholderProgressionStatus', JSON.stringify(dilemmaStakeholderProgressionStatus));
      state.dilemmaStakeholderProgressionStatus = dilemmaStakeholderProgressionStatus;
    }

    if (localStorage.getItem('stakeholderOnboardStatus')) {
      state.stakeholderOnboardStatus = JSON.parse(localStorage.getItem('stakeholderOnboardStatus'));
    } else {
      const stakeholderOnboardStatus = {};
      localStorage.setItem('stakeholderOnboardStatus', JSON.stringify(stakeholderOnboardStatus));
      state.stakeholderOnboardStatus = stakeholderOnboardStatus;
    }

    if (localStorage.getItem('stakeholderDialogueHistories')) {
      state.stakeholderDialogueHistories = JSON.parse(localStorage.getItem('stakeholderDialogueHistories'));
    }
  },
  [RESET_GAME]: (state) => {
    console.log('Resetting game');

    state.isGameReplay = true;
    localStorage.setItem('isGameReplay', JSON.stringify(state.isGameReplay));

    state.currentRouterPage = VIEW_INTRO;
    state.currentDilemmaId = null;
    state.currentStakeholderId = null;
    state.selectedScenarioIndex = 0;
    state.currentIntroPageIndex = 0;
    state.currentEvalAndPDPageIndex = 0;
    state.currentIntroUnlockPageIndex = 0;
    state.tutorialStepIndex = 0;
    state.playerName = '';
    state.playerSurname = '';
    state.playerPosition = '';
    state.playerLocation = '';
    state.onBoardingPoints = 0;
    state.evidencePointsHarvestData = 0;
    state.evidencePointsTransformData = 0;
    state.evidencePointsBossFight = 0;
    state.vca4dDocumentsOrdered = false;
    state.hasPlayedInformalPD = false;
    state.scenario1Seen = false;
    state.scenario2Seen = false;
    state.scenario3Seen = false;
    state.currentDataBreakData = [];
    state.knowledgeVaultSavedIds = [];
    state.tutorialAssistantMessagesShown = createTutorialAssistantMessagesShownObject();
    state.idpOpportunityAccepted = false;
    state.currentInformalPDPageIndex = 0;
    state.argumentIndexesPerPolicyIndex = {
      0: [],
      1: [],
      2: [],
      3: [],
    };
    state.tutorialCompleted = false;
    localStorage.setItem('currentRouterPage', state.currentRouterPage);
    localStorage.setItem('tutorialCompleted', JSON.stringify(state.tutorialCompleted));
    localStorage.setItem('scenario1Seen', JSON.stringify(state.scenario1Seen));
    localStorage.setItem('scenario2Seen', JSON.stringify(state.scenario2Seen));
    localStorage.setItem('scenario3Seen', JSON.stringify(state.scenario3Seen));
    localStorage.setItem('selectedScenarioIndex', '-1');
    localStorage.setItem('tutorialStepIndex', '0');
    localStorage.setItem('currentDilemmaId', null);
    localStorage.setItem('currentStakeholderId', null);
    localStorage.setItem('currentIntroPageIndex', '0');
    localStorage.setItem('currentIntroUnlockPageIndex', '0');
    localStorage.setItem('currentEvalAndPDPageIndex', '0');
    localStorage.setItem('playerName', '');
    localStorage.setItem('playerSurname', '');
    localStorage.setItem('playerPosition', '');
    localStorage.setItem('playerLocation', '');
    localStorage.setItem('onBoardingPoints', '0');
    localStorage.setItem('evidencePointsHarvestData', '0');
    localStorage.setItem('evidencePointsTransformData', '0');
    localStorage.setItem('evidencePointsBossFight', '0');
    localStorage.setItem('currentDataBreakData', '[]');
    localStorage.setItem('knowledgeVaultSavedIds', '[]');
    localStorage.setItem('argumentIndexesPerPolicyIndex', JSON.stringify(state.argumentIndexesPerPolicyIndex));
    localStorage.setItem('hasPlayedInformalPD', JSON.stringify(state.hasPlayedInformalPD));
    localStorage.setItem('vca4dDocumentsOrdered', JSON.stringify(state.vca4dDocumentsOrdered));
    localStorage.setItem('tutorialAssistantMessagesShown', JSON.stringify(state.tutorialAssistantMessagesShown));
    localStorage.setItem('idpOpportunityAccepted', JSON.stringify(state.idpOpportunityAccepted));
    localStorage.setItem('currentInformalPDPageIndex', JSON.stringify(state.currentInformalPDPageIndex));
    localStorage.setItem('languageSelectPage', null);

    const dilemmaUnlockedStatus = createDilemmaUnlockedStatusObject();
    localStorage.setItem('dilemmaUnlockedStatus', JSON.stringify(dilemmaUnlockedStatus));
    state.dilemmaUnlockedStatus = dilemmaUnlockedStatus;

    const dilemmaStakeholderProgressionStatus = createDilemmaStakeholderProgressionStatusArray();
    localStorage.setItem('dilemmaStakeholderProgressionStatus', JSON.stringify(dilemmaStakeholderProgressionStatus));
    state.dilemmaStakeholderProgressionStatus = dilemmaStakeholderProgressionStatus;

    // const stakeholderOnboardStatus = createStakeholderOnboardStatusObject();
    state.stakeholderOnboardStatus = {};
    localStorage.setItem('stakeholderOnboardStatus', JSON.stringify(state.stakeholderOnboardStatus));

    const argumentsUsedInBossFight = {
      1: null,
      2: null,
      3: null,
      4: null,
    };
    localStorage.setItem('argumentsUsedInBossFight', JSON.stringify(argumentsUsedInBossFight));
    state.argumentsUsedInBossFight = argumentsUsedInBossFight;

    localStorage.setItem('stakeholderDialogueHistories', JSON.stringify({}));
    state.stakeholderDialogueHistories = {};
  },
  [RESET_TO_BOSS_FIGHT]: (state) => {
    state.isGameReplay = true;
    localStorage.setItem('isGameReplay', JSON.stringify(state.isGameReplay));

    state.currentRouterPage = VIEW_EVAL_AND_PD;
    localStorage.setItem('currentRouterPage', state.currentRouterPage);

    state.currentEvalAndPDPageIndex = 6;
    localStorage.setItem('currentEvalAndPDPageIndex', JSON.stringify(state.currentEvalAndPDPageIndex));

    state.isKnowledgeVaultOpen = false;

    state.currentDilemmaId = null;
    localStorage.setItem('currentDilemmaId', JSON.stringify(state.currentDilemmaId));

    state.selectedScenarioIndex = 0;
    localStorage.setItem('selectedScenarioIndex', JSON.stringify(state.selectedScenarioIndex));

    state.scenario1Seen = false;
    state.scenario2Seen = false;
    state.scenario3Seen = false;
    localStorage.setItem('scenario1Seen', JSON.stringify(state.scenario1Seen));
    localStorage.setItem('scenario2Seen', JSON.stringify(state.scenario2Seen));
    localStorage.setItem('scenario3Seen', JSON.stringify(state.scenario3Seen));

    // reset evidence points not used
    state.evidencePointsTransformData = 0;
    localStorage.setItem('evidencePointsTransformData', state.evidencePointsTransformData.toString());

    state.evidencePointsBossFight = 0;
    localStorage.setItem('evidencePointsBossFight', state.evidencePointsBossFight.toString());

    const argumentsUsedInBossFight = {
      1: null,
      2: null,
      3: null,
      4: null,
    };
    localStorage.setItem('argumentsUsedInBossFight', JSON.stringify(argumentsUsedInBossFight));
    state.argumentsUsedInBossFight = argumentsUsedInBossFight;

    const knowledgeVaultSavedIds = state.knowledgeVaultSavedIds;
    const argumentIndexesPerPolicyIndex = {
      0: [],
      1: [],
      2: [],
      3: [],
    };

    /* for (const policyKey in argumentIndexesPerPolicyIndex) {
      if (argumentIndexesPerPolicyIndex.hasOwnProperty(policyKey)) {
        const policyArguments = state.argumentIndexesPerPolicyIndex[policyKey];
        for (const argumentListItem of policyArguments) {
          knowledgeVaultSavedIds.push(argumentListItem);
        }
      }
    } */

    localStorage.setItem('argumentIndexesPerPolicyIndex', JSON.stringify(argumentIndexesPerPolicyIndex));
    state.argumentIndexesPerPolicyIndex = argumentIndexesPerPolicyIndex;

    localStorage.setItem('knowledgeVaultSavedIds', JSON.stringify(knowledgeVaultSavedIds));
    state.knowledgeVaultSavedIds = knowledgeVaultSavedIds;
  },
  [CHANGE_LANGUAGE]: (state, locale) => {
    state.expertData = localizationExpertData[locale];
    localStorage.setItem('expertData', JSON.stringify(state.expertData));

    state.currentLocale = locale;
    localStorage.setItem('currentLocale', locale);
  },
  [CHANGE_IS_LANGUAGE_SELECT_PAGE]: (state, isLanguageSelectPage) => {
    const isLanguageSelectPageBool = Boolean(isLanguageSelectPage);
    localStorage.setItem('isLanguageSelectPage', isLanguageSelectPageBool ? '1' : null);

    state.isLanguageSelectPage = isLanguageSelectPageBool;
  },
  [SET_ROUTER_CURRENT_PAGE]: (state, pageName) => {
    state.currentRouterPage = pageName;
    localStorage.setItem('currentRouterPage', state.currentRouterPage);
  },
  [SET_DEAL_ACCEPTED]: (state, newDealAccepted) => {
    state.dealAccepted = newDealAccepted;
    localStorage.setItem('dealAccepted', JSON.stringify(state.dealAccepted));
  },
  [SET_SELECTED_SCENARIO_INDEX]: (state, index) => {
    state.selectedScenarioIndex = index;
    localStorage.setItem('selectedScenarioIndex', JSON.stringify(index));
  },
  [SET_TUTORIAL_STEP_INDEX]: (state, index) => {
    state.tutorialStepIndex = index;
    localStorage.setItem('tutorialStepIndex', JSON.stringify(index));
  },
  [SET_IDP_OPPORTUNITY_ACCEPTED]: (state, isAccepted) => {
    state.idpOpportunityAccepted = isAccepted;
    localStorage.setItem('idpOpportunityAccepted', JSON.stringify(state.idpOpportunityAccepted));
  },
  [SET_VARIABLE_TYPE_SCENARIO_1_SEEN]: (state, isSeen) => {
    state.scenario1Seen = isSeen;
    localStorage.setItem('scenario1Seen', JSON.stringify(state.scenario1Seen));
  },
  [SET_VARIABLE_TYPE_SCENARIO_2_SEEN]: (state, isSeen) => {
    state.scenario2Seen = isSeen;
    localStorage.setItem('scenario2Seen', JSON.stringify(state.scenario2Seen));
  },
  [SET_VARIABLE_TYPE_SCENARIO_3_SEEN]: (state, isSeen) => {
    state.scenario3Seen = isSeen;
    localStorage.setItem('scenario3Seen', JSON.stringify(state.scenario3Seen));
  },
  [SET_STUDY_BRIEF_TAB_INDEX]: (state, tabIndex) => {
    state.studyBriefMenuTabIndex = tabIndex;
  },
  [SET_BACKGROUND]: (state, background) => {
    state.background = background;
    localStorage.setItem('background', JSON.stringify(state.background));
  },
  [SET_CURRENT_DILEMMA_ID]: (state, newDilemmaId) => {
    localStorage.setItem('currentDilemmaId', newDilemmaId);
    state.currentDilemmaId = newDilemmaId;
  },
  [SET_CURRENT_STAKEHOLDER_ID]: (state, newCurrentStakeholderId) => {
    localStorage.setItem('currentStakeholderId', newCurrentStakeholderId);
    state.currentStakeholderId = newCurrentStakeholderId;
  },
  [SET_CURRENT_DATA_BREAK_DATA]: (state, newCurrentDataBreakData) => {
    localStorage.setItem('currentDataBreakData', JSON.stringify(newCurrentDataBreakData));
    state.currentDataBreakData = newCurrentDataBreakData;
  },
  [SET_DYNAMIC_STORE_VALUE]: (state, data) => {
    const key = data?.key;
    const value = data?.value ? data.value : '';
    if (key && state.hasOwnProperty(key)) {
      localStorage.setItem(key, value);
      state[key] = value;
    }
  },
  [SET_CURRENT_INTRO_PAGE_INDEX]: (state, newCurrentIntroPageIndex) => {
    if (newCurrentIntroPageIndex > state.currentIntroUnlockPageIndex) {
      // if current page is larger than current unlock page, then set the unlock page to the previous page.
      localStorage.setItem('currentIntroUnlockPageIndex', state.currentIntroPageIndex);
      state.currentIntroUnlockPageIndex = state.currentIntroPageIndex;
    }
    localStorage.setItem('currentIntroPageIndex', newCurrentIntroPageIndex);
    state.currentIntroPageIndex = newCurrentIntroPageIndex;
  },
  [SET_INFORMAL_PD_PAGE_INDEX]: (state, newInformalPDPageIndex) => {
    localStorage.setItem('currentInformalPDPageIndex', newInformalPDPageIndex);
    state.currentInformalPDPageIndex = newInformalPDPageIndex;
  },
  [SET_EVAL_AND_PD_PAGE_INDEX]: (state, newPageIndex) => {
    localStorage.setItem('currentEvalAndPDPageIndex', newPageIndex);
    state.currentEvalAndPDPageIndex = newPageIndex;
  },
  [ADD_ARGUMENT_PERSON_PROGRESSION]: (state) => {
    state.dilemmaStakeholderProgressionStatus[parseInt(state.currentDilemmaId, 10)].person[state.currentStakeholderId].isCurrentKnowledgeVaultItemSaved = true;
  },
  [SET_EVIDENCE_HARVEST_POINTS]: (state, newScore) => {
    state.evidencePointsHarvestData = Math.min(newScore, 15);
    localStorage.setItem('evidencePointsHarvestData', state.evidencePointsHarvestData.toString());
  },
  [SET_EVIDENCE_TRANSFORM_POINTS]: (state, newScore) => {
    state.evidencePointsTransformData = newScore;
    localStorage.setItem('evidencePointsTransformData', state.evidencePointsTransformData.toString());
  },
  [ADD_EVIDENCE_HARVEST_POINTS]: (state, evidencePointsToAdd) => {
    state.evidencePointsHarvestData = Math.min(state.evidencePointsHarvestData + evidencePointsToAdd, 15);
    localStorage.setItem('evidencePointsHarvestData', state.evidencePointsHarvestData.toString());
  },
  [ADD_EVIDENCE_TRANSFORM_POINTS]: (state, evidencePointsToAdd) => {
    state.evidencePointsTransformData += evidencePointsToAdd;
    localStorage.setItem('evidencePointsTransformData', state.evidencePointsTransformData.toString());
  },
  [ADD_EVIDENCE_BOSS_FIGHT_POINTS]: (state, evidencePointsToAdd) => {
    state.evidencePointsBossFight += evidencePointsToAdd;
    localStorage.setItem('evidencePointsBossFight', state.evidencePointsBossFight.toString());
  },
  [SET_IS_GAME_REPLAY]: (state, isGameReplay) => {
    state.isGameReplay = isGameReplay;
    localStorage.setItem('isGameReplay', isGameReplay.toString());
  },
  [SET_DILEMMA_UNLOCKED_STATUS]: (state, data) => {
    if (!data.hasOwnProperty('dilemmaId')) {
      console.log("Missing data key: 'dilemmaId");
      return;
    }

    if (!data.hasOwnProperty('newStatus')) {
      console.log("Missing data key: 'newStatus");
      return;
    }

    if (!state.dilemmaUnlockedStatus.hasOwnProperty((data.dilemmaId).toString())) {
      console.log('No such dilemma to unlock');
      return;
    }

    state.dilemmaUnlockedStatus[data.dilemmaId.toString()].status = data.newStatus;
    localStorage.setItem('dilemmaUnlockedStatus', JSON.stringify(state.dilemmaUnlockedStatus));
  },
  [SET_ALL_DILEMMA_UNLOCKED_STATUS]: (state, newDilemmaUnlockedStatus) => {
    state.dilemmaUnlockedStatus = newDilemmaUnlockedStatus;
  },
  [SET_POLICY_UNLOCKED_STATUS]: (state, data) => {
    state.dilemmaUnlockedStatus[(data.dilemmaId).toString()].policies[(data.policyId).toString()] = data.newStatus;
    localStorage.setItem('dilemmaUnlockedStatus', JSON.stringify(state.dilemmaUnlockedStatus));
  },
  [SET_STAKEHOLDER_DIALOGUE_HISTORY]: (state, data) => {
    const dilemmaId = data?.dilemmaId;
    const stakeholderId = data?.stakeholderId;
    const dialogueHistory = data?.dialogueHistory;

    if (!dilemmaId) {
      console.error(`SET_STAKEHOLDER_DIALOGUE_HISTORY data does not contain dilemmaId. Data: ${data}`);
      return;
    }
    if (!stakeholderId && stakeholderId !== 0) {
      console.error(`SET_STAKEHOLDER_DIALOGUE_HISTORY data does not contain stakeholderId. Data: ${data}`);
      return;
    }
    if (!dialogueHistory) {
      console.error(`SET_STAKEHOLDER_DIALOGUE_HISTORY data does not contain dialogueHistory. Data: ${data}`);
      return;
    }

    // make the keys if they don't exist
    const stakeholderDialogueHistories = state.stakeholderDialogueHistories;

    if (!stakeholderDialogueHistories?.[dilemmaId]) {
      stakeholderDialogueHistories[dilemmaId] = {};
    }

    stakeholderDialogueHistories[dilemmaId][stakeholderId] = dialogueHistory;

    localStorage.setItem('stakeholderDialogueHistories', JSON.stringify(stakeholderDialogueHistories));
    state.stakeholderDialogueHistories = stakeholderDialogueHistories;
  },
  [SET_ALL_DILEMMA_STAKEHOLDER_PROGRESS_STATUS]: (state, newStatus) => {
    state.dilemmaStakeholderProgressionStatus = newStatus;
  },
  [UPDATE_ONBOARDING_STATUS]: (state, data) => {
    if (!data.hasOwnProperty('stakeholderIndex')) {
      console.log("Missing data key: 'StakeholderIndex");
      return;
    }

    if (!data.hasOwnProperty('stakeholderProgression')) {
      console.log("Missing data key: 'stakeholderProgression");
      return;
    }

    const previousValue = state.stakeholderOnboardStatus?.[data.stakeholderIndex] ? state.stakeholderOnboardStatus[data.stakeholderIndex] : 0;

    let newValue = previousValue + data.stakeholderProgression;
    newValue = newValue > 2 ? 2 : newValue;
    newValue = newValue < 0 ? 0 : newValue;

    state.stakeholderOnboardStatus[data.stakeholderIndex] = newValue;
    localStorage.setItem('stakeholderOnboardStatus', JSON.stringify(state.stakeholderOnboardStatus));
  },
  [SET_DILEMMA_STAKEHOLDER_PERSON_PROGRESS_STATUS]: (state, newStatus) => {
    state.dilemmaStakeholderProgressionStatus[state.currentDilemmaId].person[state.currentStakeholderId] = {
      currentChoiceIndex: newStatus.currentChoiceIndex,
      isCurrentKnowledgeVaultItemSaved: false,
      currentIndex: newStatus.currentIndex,
      storyMessageHistory: newStatus.storyMessageHistory,
      currentChoices: newStatus.currentChoices,
    };
    localStorage.setItem('dilemmaStakeholderProgressionStatus', JSON.stringify(state.dilemmaStakeholderProgressionStatus));
  },
  [SET_DILEMMA_STAKEHOLDER_DOCUMENT_PROGRESS_STATUS]: (state, dilemmaId, documentId, newStatus) => {
    state.dilemmaStakeholderProgressionStatus[dilemmaId.toString()].document[documentId.toString()] = newStatus;
  },
  [ORDER_VCA4D_MATERIAL]: (state) => {
    localStorage.setItem('vca4dDocumentsOrdered', 'true');
    state.vca4dDocumentsOrdered = true;
  },
  [FINISH_INFORMAL_PD]: (state) => {
    localStorage.setItem('hasPlayedInformalPD', 'true');
    state.hasPlayedInformalPD = true;
  },
};
