<template>
  <div class="dilemma-view-wrapper">
    <!--<div class="background-bottom-bar" />-->
    <div id="dilemma-info-wrapper" class="dilemma-info-wrapper">
      <div class="dilemma-title-text-wrapper">
        <div class="dilemma-title-wrapper" v-if="!(isTutorial)">
          <p>{{ $t(dilemmaTitle) }}<span v-if="isDilemmaReplay" style="font-weight: 400">{{ $t('dilemmaView.replayingDilemma') }}</span></p>
        </div>

        <div class="dilemma-text-wrapper" v-if="!(isTutorial && tutorialStepIndex === 2)">
          <p v-if="!isTutorial || (isTutorial && tutorialStepIndex === 0)">
            {{ $t(dilemmaDescription) }}
          </p>
          <div v-if="isTutorial && tutorialStepIndex === 1">
            <p>{{ $t('dilemmaView.tutorial1') }}<br></p>
          </div>
        </div>
      </div>

      <div v-if="isTutorial && tutorialStepIndex >= 1" class="dilemma-mail-button" :class="tutorialStepIndex === 2 ? 'button-offset' : ''">
        <div class="dilemma-mail-button-icon fat-text">
          <div v-if="!tutorialStepIndex" class="notification notification-placement">
            1
          </div>
          <no-drag-image v-if="tutorialStepIndex >= 2" src="@/assets/img/ui/icon_email_open.png" />
          <div v-if="tutorialStepIndex === 1">
            <no-drag-image src="@/assets/img/ui/icon_email_closed.png" />

            <div class="attachment-notification">
              <NotificationIcon>
                !
              </NotificationIcon>
            </div>
          </div>
        </div>
        <AppButton color="white" size="medium" @click="openTutorialEmail">
          {{ $t('dilemmaView.gotMail') }}
        </AppButton>
      </div>

      <div v-if="!isTutorial || (isTutorial && tutorialStepIndex === 2) || tutorialCompleted" class="mt-2">
        <AppButton id="dilemma-complete-button" :disabled="!dilemmaUnlocked" color="white" @click="finishDilemma()">
          {{ $t(dilemmaCompleteText) }}
        </AppButton>
      </div>
    </div>

    <div v-if="!isTutorial || (isTutorial && tutorialStepIndex === 2)" class="knowledge-vault-button-wrapper">
      <KnowledgeVaultButton id="knowledge-vault-button" @click="onClickKnowledgeButton()" />
    </div>
    <div class="modal-wrapper video-modal-wrapper" :class="videoModalOpen ? 'show' : 'hide'">
      <VideoModal :video-url="currentVideoUrl" @closeModal="closeVideoModal()" ref="videoModal"/>
    </div>
    <div class="modal-wrapper document-modal-wrapper" :class="documentModalOpen ? 'show' : 'hide'">
      <DocumentModal :document-id="currentDocumentId" @closeModal="closeDocumentModal()" />
    </div>
    <div class="email-view-container-blackness blackness" :class="emailModalOpen ? '' : 'hidden'">
      <div class="email-view-container" :class="emailModalOpen ? '' : 'hidden'">
        <EmailModule :title="tutorialEmail.title"
                     :email-text="tutorialEmail.emailText"
                     :is-news="false"
                     :to-from-data="tutorialEmail.toFromField"
                     :sender-info="tutorialEmail.senderInfo"
                     :attachments="tutorialEmail.attachments"
                     :show-close-button="true"
                     :attachments-seen="emailAttachmentsSeen"
                     @openAttachment="openAttachment"
                     @closeEmail="closeEmailModal"
        />
      </div>
    </div>

    <div v-if="isTutorial && tutorialStepIndex < 1">
      <div class="stakeholder-wrapper margin-left d-flex justify-content-left">
        <div v-for="(stakeholder, j) in tutorialStakeholders" :key="j" class="stakeholder-group-container">
          <StakeholderButton :stakeholders="[stakeholder]" :show-check-mark="false" size="small"
                             :show-enter-button="false" @click="onClickStakeholderButton(i)"
          />
        </div>
      </div>
      <div class="enter-meeting-button-wrapper">
        <AppButton color="white" size="medium" @click="onClickStakeholderButton(0)">
          {{ $t('dilemmaView.stepIn') }}
        </AppButton>
      </div>
    </div>
    <div v-if="!isTutorial" class="stakeholder-wrapper d-flex justify-content-around">
      <div v-for="(stakeholder, i) in dilemmaStakeholders" :key="i" class="stakeholder-group-container">
        <StakeholderButton :stakeholders="stakeholder" :show-check-mark="shouldShowCheckmark(i)" size="small"
                           :show-enter-button="!isDilemmaReplay || (isDilemmaReplay && shouldShowCheckmark(i))" @click="onClickStakeholderButton(i)"
        />
      </div>
    </div>
    <div class="document-source-wrapper" :class="isTutorial && tutorialStepIndex !== 2 ? 'tutorial' : ''">
      <div id="document-source-wrapper" class="document-source-inner-wrapper">
        <div v-for="(item, i) in persistentSources" :key="i">
          <DocumentSourceButton :id="item.id" :picture-id="item.pictureId" :name="item.name" :background-color="item.color"
                                :is-locked="isDocumentLocked(item.requiredTutorialKey)" :open-picture-id="item?.openPictureId"
                                :type="item.type" :data-id="item?.documentId" :folders="item?.folderContains" @openSource="onClickDocumentSourceButton"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import AppButton from '@/components/common/AppButton';
import StakeholderButton from '@/components/Stakeholder/StakeholderButton';
import DocumentSourceButton from '@/components/common/DocumentSourceButton';
import persistentSources from '@/data/persistentSources.json';
import {
  VIEW_OVERVIEW,
  VIEW_DIALOGUE,
  VIEW_DILEMMA_POLICY_CHOICE, VIEW_TUTORIAL_OUTRO,
} from '@/enum/Views.js';
import { mapState } from 'vuex';
import { getDilemmaFromId } from '@/utils/dataUtil';
import VideoModal from '@/components/common/VideoModal';
import { SOURCE_DOCUMENT, SOURCE_VIDEO } from '@/enum/SourceTypes';
import DocumentModal from '@/components/DocumentModal/DocumentModal';
import EmailModule from '@/components/common/EmailModule';
import {
  ORDER_VCA4D_MATERIAL,
  SET_BACKGROUND,
  SET_CURRENT_STAKEHOLDER_ID, SET_IS_KNOWLEDGE_VAULT_OPEN, SET_TUTORIAL_STEP_INDEX,
  UPDATE_TUTORIAL_ASSISTANT_STATUS, FINISH_TUTORIAL, SET_STUDY_BRIEF_TAB_INDEX,
} from '@/store/mutation-types';
import { BACKGROUND_FARM } from '@/enum/Background';
import { DOCUMENT_6_PAGER, VIDEO_VCA4D } from '@/enum/DocumentIds';
import { STATUS_COMPLETED, STATUS_LOCKED, STATUS_AVAILABLE } from '@/enum/UnlockedStatus';
import NotificationIcon from '@/components/common/NotificationIcon';
import NoDragImage from '../components/common/NoDragImage';
import KnowledgeVaultButton from '../components/common/KnowledgeVaultButton';

export default {
  name: 'DilemmaView',
  components: {
    NotificationIcon,
    NoDragImage,
    KnowledgeVaultButton,
    EmailModule,
    DocumentModal,
    VideoModal,
    AppButton,
    StakeholderButton,
    DocumentSourceButton,
  },
  props: {
  },
  data() {
    return {
      persistentSources: [],
      videoModalOpen: false,
      currentVideoUrl: '',
      documentModalOpen: false,
      attachmentOpen: false,
      emailModalOpen: false,
      currentDocumentId: '',
      tutorialEmail: {
        title: 'persistentSources.0.name',
        toFromField: {
          from: 'dilemmaView.emailFrom',
          to: 'misc.emailToMe',
          when: 'misc.emailDate',
        },
        emailText: 'dilemmaView.emailText',
        senderInfo: 'dilemmaView.emailSender',
        attachments: [
          {
            title: 'dilemmaView.attachment1',
            documentId: VIDEO_VCA4D,
            type: 'video',
          },
          {
            title: 'dilemmaView.attachment2',
            documentId: DOCUMENT_6_PAGER,
          },
        ],
      },
      emailAttachmentsSeen: {
        VCA4DVideo: false,
        '6pager': false,
      },
    };
  },
  watch: {
    allTutorialAttachmentsSeen(newValue, oldValue) {
      if (newValue) {
        this.$store.commit(SET_TUTORIAL_STEP_INDEX, 2);
      }
    },
    isKnowledgeVaultOpen(knowledgeVaultIsOpen, knowledgeVaultWasOpen) {
      if (this.isTutorial && !knowledgeVaultIsOpen && knowledgeVaultWasOpen) {
        this.playExitTutorialAssistant();
      }
    },
  },
  beforeCreate() {
    this.$store.commit(SET_BACKGROUND, BACKGROUND_FARM);
  },
  mounted() {
    this.assistantData = require('@/data/assistant/assistantData.json');

    if (!this.tutorialAssistantMessagesShown.dilemmaViewExplanation) {
      setTimeout(() => {
        this.$root.sendAssistantMessagesByKey('dilemmaViewExplanation');
        this.$store.commit(UPDATE_TUTORIAL_ASSISTANT_STATUS, 'dilemmaViewExplanation');
      }, 450);
    }

    if (!this.isTutorial && this.dilemmaUnlocked) {
      this.playExitDilemmaAssistant();
    }

    if (this.isTutorial && this.tutorialStepIndex === 1) {
      this.$root.sendAssistantMessagesByKey('timeExplanation');
    }

    if (this.currentDilemmaId === 1 || this.currentDilemmaId === '1') {
      if (!this.tutorialAssistantMessagesShown.dilemma1IntroExplanation) {
        setTimeout(() => {
          this.$root.sendAssistantMessagesByKey('dilemma1IntroExplanation');
          this.$store.commit(UPDATE_TUTORIAL_ASSISTANT_STATUS, 'dilemma1IntroExplanation');
        }, 450);
      }
    }

    if (!this.currentDilemmaId) {
      // this.$router.push({ name: VIEW_OVERVIEW });
    }
    this.$store.commit(SET_BACKGROUND, 'farm');

    // get the persistent sources
    this.persistentSources = persistentSources;

    if (this.isTutorial) {
      this.$store.commit(SET_STUDY_BRIEF_TAB_INDEX, 0);
    } else {
      this.$store.commit(SET_STUDY_BRIEF_TAB_INDEX, 1);
    }
  },
  computed: {
    ...mapState([
      'currentDilemmaId',
      'dilemmas',
      'stakeholderOnboardStatus',
      'dilemmaUnlockedStatus',
      'vca4dDocumentsOrdered',
      'tutorialAssistantMessagesShown',
      'tutorialStepIndex',
      'tutorialCompleted',
      'isKnowledgeVaultOpen',
      'expertData',
    ]),
    allTutorialAttachmentsSeen() {
      return Object.values(this.emailAttachmentsSeen)
        .every((item) => item === true);
    },
    currentDilemma() {
      return getDilemmaFromId(this.dilemmas, this.currentDilemmaId);
    },
    isDilemmaReplay() {
      if (this.isTutorial) {
        return this.dilemmaUnlockedStatus['0'].status === STATUS_COMPLETED;
      }

      const dilemmaStatus = this.dilemmaUnlockedStatus?.[this.currentDilemmaId]?.status || STATUS_AVAILABLE;
      return dilemmaStatus === STATUS_COMPLETED;
    },
    dilemmaUnlocked() {
      if (this.isTutorial) {
        return this.vca4dDocumentsOrdered;
      }
      return this.numberOfPoliciesFound >= 2;
    },
    dilemmaPolicyUnlocks() {
      return this.dilemmaUnlockedStatus?.[(this.currentDilemmaId).toString()]?.policies;
    },
    numberOfPoliciesFound() {
      let numberOfPoliciesFound = 0;
      if (this.currentDilemmaId === null) {
        return numberOfPoliciesFound;
      }

      const currentPolicyUnlockStatus = this.dilemmaUnlockedStatus?.[(this.currentDilemmaId).toString()]?.policies;

      if (!currentPolicyUnlockStatus) {
        return numberOfPoliciesFound;
      }

      for (const key of Object.keys(currentPolicyUnlockStatus)) {
        if (currentPolicyUnlockStatus[key] !== STATUS_LOCKED) {
          numberOfPoliciesFound += 1;
        }
      }
      return numberOfPoliciesFound;
    },
    dilemmaTitle() {
      return this?.currentDilemma?.title || '';
    },
    dilemmaDescription() {
      return this?.currentDilemma?.description || '';
    },
    dilemmaStakeholders() {
      return this?.currentDilemma?.sources.person || [];
    },
    tutorialStakeholders() {
      // they want the minister to be in the middle of the group
      if (this.dilemmaStakeholders.length !== 1) {
        console.error('the tutorial should only have one group of people');
      }
      if (this.dilemmaStakeholders[0].length !== 3) {
        console.error('the tutorial group should ALWAYS have three people in it');
      }

      const tutorialStakeholdersRearranged = [];
      const tutorialStakeholdersGroup = this.dilemmaStakeholders[0];

      const correctSequence = [1, 0, 2];

      for (const index of correctSequence) {
        tutorialStakeholdersRearranged.push(tutorialStakeholdersGroup[index]);
      }

      return tutorialStakeholdersRearranged;
    },
    isTutorial() {
      return this.currentDilemmaId === 'INTRO2';
    },
    dilemmaCompleteText() {
      if (this.isTutorial) {
        if (this.tutorialCompleted && this.tutorialStepIndex !== 2) {
          return 'dilemmaView.leaveTutorial';
        }
        return 'dilemmaView.meetLocalActors';
      }
      if (this.isDilemmaReplay) {
        return 'dilemmaView.leaveDilemmaReplay';
      }
      return 'dilemmaView.completeDilemma';
    },
  },
  methods: {
    shouldShowCheckmark(stakeholderIndex) {
      if (this.isTutorial) {
        return false;
      }

      let isPolicyUnlocked = false;

      const stakeholderPolicyValue = this.dilemmaPolicyUnlocks?.[(stakeholderIndex + 1).toString()];
      if (stakeholderPolicyValue && stakeholderPolicyValue !== STATUS_LOCKED) {
        isPolicyUnlocked = true;
      }

      return isPolicyUnlocked;
    },
    playExitDilemmaAssistant() {
      console.log('play exit dilemma Assistant');
      if (!this.tutorialAssistantMessagesShown.dilemmaExitButtonExplanation) {
        setTimeout(() => {
          this.$root.sendAssistantMessagesByKey('dilemmaExitButtonExplanation');
          this.$store.commit(UPDATE_TUTORIAL_ASSISTANT_STATUS, 'dilemmaExitButtonExplanation');
        }, 250);
      }
    },
    playExitTutorialAssistant() {
      // tutorial doesn't need redundancy check
      setTimeout(() => {
        this.$root.sendAssistantMessagesByKey('tutorialExitButtonExplanation');
      }, 250);
    },
    onClickStakeholderButton(i) {
      if (this.isTutorial) {
        this.$store.commit(SET_TUTORIAL_STEP_INDEX, 1);
      }
      this.$store.commit(SET_CURRENT_STAKEHOLDER_ID, i);
      this.$router.push({ name: VIEW_DIALOGUE });
    },
    onClickDocumentSourceButton(type, dataId) {
      if (type === SOURCE_VIDEO) {
        this.currentVideoUrl = this.expertData.links[dataId];
        this.videoModalOpen = true;
      } else if (type === SOURCE_DOCUMENT) {
        this.currentDocumentId = dataId;
        this.documentModalOpen = true;
      }
    },
    openAttachment(attachmentTitle, attachmentId) {
      this.emailModalOpen = false;
      this.attachmentOpen = true;

      // for tutorial
      if (this.emailAttachmentsSeen.hasOwnProperty(attachmentId)) {
        this.emailAttachmentsSeen[attachmentId] = true;
      }

      if (attachmentId === VIDEO_VCA4D) {
        this.currentVideoUrl = this.expertData.links[attachmentId];
        this.videoModalOpen = true;
        /* this.videoIdleTimeout = setTimeout(() => {
          if (this.tutorialAssistantMessagesShown.videoSeen) {
            return;
          }

          this.$refs.videoModal.activateAttentionWobble();

          this.videoIdleTimeout = setTimeout(() => {
            if (this.tutorialAssistantMessagesShown.videoSeen) {
              return;
            }

            this.$refs.videoModal.activateAttentionWobble();
          }, 8000);
        }, 8000); */
      } else {
        this.currentDocumentId = attachmentId;
        this.documentModalOpen = true;
      }
    },
    orderVca4dDocuments() {
      this.$store.commit(ORDER_VCA4D_MATERIAL);
    },
    isDocumentLocked(unlockKey) {
      if (!unlockKey && this[unlockKey] === undefined) {
        return false;
      }
      return !this[unlockKey];
    },
    openTutorialEmail() {
      this.emailModalOpen = true;
      this.orderVca4dDocuments();
    },
    closeEmailModal() {
      this.emailModalOpen = false;

      if (this.tutorialStepIndex === 2 && !this.tutorialAssistantMessagesShown.knowledgeVaultExplanation) {
        setTimeout(() => {
          this.$root.sendAssistantMessagesByKey('knowledgeVaultExplanation');
          this.$store.commit(UPDATE_TUTORIAL_ASSISTANT_STATUS, 'knowledgeVaultExplanation');
        }, 150);
      }
    },
    closeVideoModal() {
      /* if (!this.tutorialAssistantMessagesShown.videoSeen) {
        return;
      } */
      this.reopenEmailIfAttachment();
      this.videoModalOpen = false;
    },
    closeDocumentModal() {
      this.reopenEmailIfAttachment();
      this.documentModalOpen = false;
    },
    reopenEmailIfAttachment() {
      if (this.attachmentOpen) {
        this.attachmentOpen = false;
        this.openTutorialEmail();
      }
    },
    onClickKnowledgeButton() {
      this.$store.commit(SET_IS_KNOWLEDGE_VAULT_OPEN, true);
    },
    finishDilemma() {
      if (this.isTutorial || this.isDilemmaReplay) {
        if (this.isTutorial) {
          this.$store.commit(FINISH_TUTORIAL);
          this.$router.push({ name: VIEW_TUTORIAL_OUTRO });
          return;
        }
        this.$router.push({ name: VIEW_OVERVIEW });
        return;
      }

      this.$router.push({ name: VIEW_DILEMMA_POLICY_CHOICE });
    },
  },
};
</script>

<style lang="scss" scoped>
.dilemma-view-wrapper {
  height: 100%;
  color: #ffffff;
  overflow: hidden;

  .margin-left {
    margin-left: 10%;
  }

  .modal-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100000;

    transition-property: opacity;
    transition-duration: 950ms;
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.175);

    &.show {
      opacity: 1;
    }

    &.hide {
      opacity: 0;
      pointer-events: none;
    }
  }

  .button-icon {
    padding: 2px 14px 2px 5px;
    width: 60px;
    img {
      width: 100%;
    }
  }

  .background-bottom-bar {
    background: linear-gradient(180deg, #395D60 17.25%, #224547 70.64%);
    height: 100px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .corner-container {
    .complete-dilemma-button {
      position: absolute;
      right: 140px;
      top: 50px;
    }
  }

  .dilemma-info-wrapper {
    width: 56%;
    max-width: 920px;
    top: 45px;
    left: 75px;
    position: absolute;
    z-index: 1000;

    .dilemma-title-text-wrapper {
      background-color: rgba(0, 0, 0, 0.6);
      border-radius: 12px;
      p {
        // font-size: 1.0rem;
      }

      .dilemma-title-wrapper {
        background-color: rgba(0, 0, 0, 0.6);
        padding: 5px 15px;
        border-radius: 12px 12px 0 0;
        font-weight: 700;
        p {
          margin: 0;
        }

      }
      .dilemma-text-wrapper {
        padding: 10px 15px;

        p {
          margin: 0;
        }
      }
    }

    .dilemma-mail-button {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(calc(110% + 25px), -50%);

      &.button-offset {
        top: calc(50% - 1.5rem) !important;
        .dilemma-mail-button-icon {
          top: -13px !important;
        }
      }

      .notification-placement {
        top: -16px !important;
        right: -7px !important;
      }

      .dilemma-mail-button-icon {
        pointer-events: none;
        position: absolute;
        top: 6px;
        left: -32px;
        width: 55.01px;
        height: 43.1px;
        z-index: 1000;
        img {
          width: 100%;
        }
      }
    }
  }

  .knowledge-vault-button-wrapper {
    position: absolute;
    top: 155px;
    right: 5px;
    margin-right: auto;
    margin-left: auto;
    width: 176px;
    //white-space: nowrap;
  }

  .tutorial-stakeholder-wrapper {
    width: 50% !important;
  }

  .enter-meeting-button-wrapper {
    position: absolute;
    bottom: 75px;
    left: calc(690px + 10%);
    transform: translateX(50%);
    z-index: 99999;
  }

  .stakeholder-wrapper {
    position: absolute;
    bottom: 0;
    left: 35px;
    overflow-y: hidden;
    overflow-x: hidden;
    width: 65%;

    .stakeholder-container {
      width: 300px;
      height: 500px;
      padding: 10px;
    }

    .stakeholder-group-container {
      position: relative;
      height: 80vh;
      margin: 0 15px;

      .onBoarding-bar{
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }

    .large-stakeholder-group {
      max-width: 500px;
    }

    .small-stakeholder-group {
      max-width: 200px;
    }
  }

  .document-source-inner-wrapper {
    height: fit-content;
  }
  .document-source-wrapper {
    width: fit-content;
    position: absolute;
    z-index: 10000;
    right: 0;
    top: 295px;
    height: calc(100% - 295px);

    transition-property: top;
    transition-duration: 250ms;
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);

    &.tutorial {
      top: 175px !important;
      height: calc(100% - 175px);
    }
  }

  .knowledge-vault-wrapper {
    z-index: 100;
  }
}

.attachment-notification {
  position: absolute;
  top: -25px;
  right: -18px;
  width: 50px;
  height: 50px;
}

.email-view-container-blackness {
  z-index: 10011;
  position: absolute;
  transition-property: opacity;
  transition-duration: 250ms;
  overflow: hidden;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
  .email-view-container {
    position: relative;
    font-family: 'Fira Sans Condensed', 'Fira Sans', sans-serif;
    border-radius: 15px;
    width: 48%;
    z-index: 10001;
    min-width: 440px;
    float: left;
    padding-top: 155px;
    margin-left: 15%;
    overflow-y: hidden;
    height: 100vh;

    transition-property: transform;
    transition-duration: 850ms;
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.175);

    &.hidden {
      transform: translateY(120%);
    }
  }
}

</style>
