import GameData from '@/data';
import { createTutorialAssistantMessagesShownObject } from '@/utils/dataUtil';
import { VIEW_INTRO } from '@/enum/Views';
import localizationExpertData from '@/data/expertData';

export default {
  dilemmas: GameData.dilemmaData,
  expertData: localizationExpertData.en,
  background: 'farm',
  currentLocale: 'en',
  isLanguageSelectPage: false,
  evidencePointsHarvestData: 0,
  evidencePointsTransformData: 0,
  evidencePointsBossFight: 0,

  currentRouterPage: VIEW_INTRO,

  isGameReplay: false,

  isKnowledgeVaultOpen: false,

  currentDilemmaId: null,
  currentStakeholderId: null,

  studyBriefMenuTabIndex: 0,

  selectedScenarioIndex: -1,
  dealAccepted: false,

  currentIntroPageIndex: 0,
  currentIntroUnlockPageIndex: 0,

  currentInformalPDPageIndex: 0,
  currentEvalAndPDPageIndex: 0,

  idpOpportunityAccepted: false,
  scenario1Seen: false,
  scenario2Seen: false,
  scenario3Seen: false,

  dilemmaStakeholderProgressionStatus: {},
  dilemmaUnlockedStatus: {},
  stakeholderOnboardStatus: {},
  stakeholderDialogueHistories: {},

  // data break
  currentDataBreakData: [],

  // cheats
  cheatsWindowActivated: false,

  // tutorial stuff
  tutorialStepIndex: 0,
  tutorialCompleted: false,

  vca4dDocumentsOrdered: false,
  hasPlayedInformalPD: false,
  tutorialAssistantMessagesShown: createTutorialAssistantMessagesShownObject(),
  // boss fight
  argumentsUsedInBossFight: {
    1: null,
    2: null,
    3: null,
    4: null,
  },

  // knowledge bank items
  knowledgeVaultSavedIds: [],
  argumentIndexesPerPolicyIndex: {
    0: [],
    1: [],
    2: [],
    3: [],
  },

  playerName: '',
  playerSurname: '',
  playerPosition: '',
  playerLocation: '',
};
