<template>
  <div class="story-message" :class="['story-message-speaker-' + speaker]">
    <p>{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: 'StoryMessage',
  props: {
    text: {
      type: String,
      required: false,
      default: null,
    },
    speaker: {
      // StoryMessageSpeaker
      // STORY_MESSAGE_SPEAKER_THEM
      // STORY_MESSAGE_SPEAKER_US
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.story-message {
  padding: 10px;
  border: 1px solid #333333;
  background: rgba(0, 0, 0, 5%);

  &.story-message-speaker-us {
    background: rgba(#aa0000, 80%);
    margin-left: 50px;
    color: white;
  }

  &.story-message-speaker-them {
    background: rgba(#0000aa, 80%);
    margin-right: 50px;
    color: white;
  }
}
</style>
