<template>
  <div v-if="show" class="analytics-permission-popup-container">
    <div class="analytics-permission-popup">
      <div class="text">
        {{ choiceText }}
      </div>
      <div class="text-center">
        <button class="btn btn-custom" @click="onClickAcceptChoice">
          {{ $t('misc.yes') }}
        </button>
        <button class="btn btn-custom" @click="onClickCancelChoice">
          {{ $t('misc.no') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ChoicePopUp',
  props: {
    choiceText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  emit: ['acceptChoice', 'cancelChoice'],
  methods: {
    onShowChoice() {
      this.show = true;
    },
    onClickAcceptChoice() {
      this.show = false;

      this.$emit('acceptChoice');
    },
    onClickCancelChoice() {
      this.show = false;

      this.$emit('cancelChoice');
    },
  },
};
</script>

<style lang="scss" scoped>
.analytics-permission-popup-container {
  background: rgba(0, 0, 0, .6);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000000;
}

.text {
  display: block;
  padding-bottom: 25px;
}

.btn-custom {
  margin: 0 7px;
  background-color: #005C5C;
  color: #fff;
}

.analytics-permission-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  background: #f8f8f8;
  padding: 25px 50px 40px;
  border-radius: 12px;
  transform: translate(-50%, -50%);

  box-shadow: 10px 10px 27px 0px rgba(0,0,0,0.75);
  -webkit-box-shadow: 10px 10px 27px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 27px 0px rgba(0,0,0,0.75);
  color: black;
}
</style>
