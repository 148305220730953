<template>
  <div class="knowledge-vault-item-wrapper" :class="hasBeenUsed ? 'has-been-used' : ''" ref="knowledgeVaultItem" >
    <!--<div class="close-button-wrapper" @click="pressButton()"/>-->
    <div v-if="hasBeenUsed" class="has-been-used-text">
      {{ $t('misc.usedCaps') }}
    </div>
    <div class="knowledge-vault-source-name-wrapper" :class="typeClass">
      {{titleFromId}}
    </div>
    <div class="knowledge-vault-message-wrapper">
      <div class="data-type-container" :class="typeClass">
        {{ $t(`document.dataType.${getDataTypeLocalizationKey(typeFromId)}`) }}
      </div>
      <div v-html="dataTextToHtml(passageFromId)">

      </div>
    </div>
  </div>
</template>
<script>

import { mapState } from 'vuex';
import textToHtml from '@/utils/textToHtml';
import {
  DATA_TYPE_ECONOMIC_GROWTH,
  DATA_TYPE_ENVIRONMENTAL_SUSTAINABILITY,
  DATA_TYPE_FUNCTIONAL_ANALYSIS,
  DATA_TYPE_INCLUSIVENESS, DATA_TYPE_LOCALIZATION_KEY,
  DATA_TYPE_SOCIAL_SUSTAINABILITY,
} from '@/enum/DataTypes';

export default {
  name: 'KnowledgeVaultItem',
  props: {
    dataBreakId: {
      type: String,
      required: true,
    },
  },
  emits: ['click'],
  computed: {
    ...mapState([
      'dilemmas',
      'argumentsUsedInBossFight',
      'expertData',
    ]),
    dataBreakData() {
      return this.expertData ? this.expertData.dataBreakData : {};
    },
    titleFromId() {
      return this.dataBreakData?.[this.dataBreakId]?.name;
    },
    typeFromId() {
      return this.dataBreakData?.[this.dataBreakId]?.dataType ?? 'none';
    },
    passageFromId() {
      return this.dataBreakData?.[this.dataBreakId]?.passage;
    },
    hasBeenUsed() {
      return Object.values(this.argumentsUsedInBossFight).includes(this.dataBreakId);
    },
    typeClass() {
      let buttonClass = '';

      if (this.typeFromId === DATA_TYPE_SOCIAL_SUSTAINABILITY) {
        buttonClass = 'data-type-social-sustain';
      } else if (this.typeFromId === DATA_TYPE_ECONOMIC_GROWTH) {
        buttonClass = 'data-type-economic-growth';
      } else if (this.typeFromId === DATA_TYPE_FUNCTIONAL_ANALYSIS) {
        buttonClass = 'data-type-functional-analysis';
      } else if (this.typeFromId === DATA_TYPE_ENVIRONMENTAL_SUSTAINABILITY) {
        buttonClass = 'data-type-environ-sustain';
      } else if (this.typeFromId === DATA_TYPE_INCLUSIVENESS) {
        buttonClass = 'data-type-inclusiveness';
      } else {
        buttonClass = 'data-type-none';
      }

      return buttonClass;
    },
  },
  methods: {
    pressButton() {
      this.$emit('click');
    },
    dataTextToHtml(text) {
      return textToHtml(text, this.$t);
    },
    getDataTypeLocalizationKey(dataType) {
      return DATA_TYPE_LOCALIZATION_KEY[dataType];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/sass/variables.scss";

  .knowledge-vault-item-wrapper {
    width: 100%;
    max-width: 400px;
    position: relative;
    height: 100%;
    cursor: grab;

    .knowledge-vault-source-name-wrapper {
      color: #fff;
      border-radius: 7px 7px 0 0;
      font-size: 20px;
      line-height: 1.2;
      width: 100%;
      min-height: 70px;
      margin-top: 15px;
      padding: 10px 25px;
      text-align: center;
      font-family: 'Fira Sans Condensed','Fira Sans', sans-serif;

      p {
        margin: 0;
      }

      &.data-type-social-sustain {
        background-color: $colorDataTypeSocSus;
      }
      &.data-type-economic-growth {
        background-color: $colorDataTypeEcoGrow;
      }
      &.data-type-functional-analysis {
        background-color: $colorDataTypeFuncAna;
      }
      &.data-type-environ-sustain {
        background-color: $colorDataTypeEnvSus;
      }
      &.data-type-inclusiveness {
        background-color: $colorDataTypeInclu;
      }
      &.data-type-none {
        background-color: #fff;
      }
    }

    .data-type-container {
      text-align: center;
      font-style: italic;
      font-size: 16px !important;
      padding: 4px 7px;
      background-color: #E0E1E6;
      margin-bottom: 9px;
      border-radius: 4px;

      &.data-type-social-sustain {
        color: $colorDarkDataTypeSocSus;
      }
      &.data-type-economic-growth {
        color: $colorDarkDataTypeEcoGrow;
      }
      &.data-type-functional-analysis {
        color: $colorDarkDataTypeFuncAna;
      }
      &.data-type-environ-sustain {
        color: $colorDarkDataTypeEnvSus;
      }
      &.data-type-inclusiveness {
        color: $colorDarkDataTypeInclu;
      }
    }

    .knowledge-vault-message-wrapper {
      color: #5B5B5B;
      background-color: #F8F8F8;
      padding: 12px 10px 17px 10px;
      border-radius: 0 0 7px 7px;
      font-weight: 400;
      font-size: 22px;
      line-height: 1.2;
      height: 95%;
    }

    /* .close-button-wrapper {
      color: #000;
      height: 20px;
      width: 20px;
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
      background-image: url("../../assets/img/ui/close.svg");
      background-repeat: no-repeat;
      background-size: 90%;
      background-position: center;
    } */
  }
</style>
