<template>
  <div class="space">

  </div>
  <Data6PagerButton :data-key="dataKey" ref="dataButton"/>
  <div class="row mt-4">
    <h3>Dev buttons</h3>
    <div class="col">
      <button class="btn btn-light me-4" @click="highlightButton()">
        Highlight Button
      </button>
    </div>
    <h3><br>Change data</h3>
    <div class="col">
      <button class="btn btn-light me-4" @click="setDataBreakData1()">
        Toggle data economic
      </button>
      <button class="btn btn-light me-4" @click="setDataBreakData2()">
        Toggle data social sustainability
      </button>
      <button class="btn btn-light me-4" @click="setDataBreakData3()">
        Toggle data functional analysis
      </button>
      <button class="btn btn-light me-4" @click="setDataBreakData4()">
        Toggle data Environmental sustainability
      </button>
    </div>
    <div class="space">

    </div>
  </div>
</template>
<script>

import { mapState } from 'vuex';
import Data6PagerButton from './Data6PagerButton';

export default {
  name: 'DataBreak6PagerDev',
  components: {
    Data6PagerButton,
  },
  emits: ['click'],
  data() {
    return {
      dataKey: '33',
    };
  },
  computed: {
    ...mapState([
      'expertData',
    ]),
    dataBreakData() {
      return this.expertData ? this.expertData.dataBreakData : {};
    },
  },
  mounted() {
    this.setDataBreakData1();
  },
  methods: {
    highlightButton() {
      this.$refs.dataButton.highlightButton();
    },
    setDataBreakData1() {
      this.dataKey = '1';
    },
    setDataBreakData2() {
      this.dataKey = '2';
    },
    setDataBreakData3() {
      this.dataKey = '5';
    },
    setDataBreakData4() {
      this.dataKey = '9';
    },
  },
};
</script>
<style lang="scss" scoped>
.space {
  height :60px;
}
</style>
