<template>
  <div class="save-source-button-dev-wrapper">
    <SaveSourceButton :disabled="isDisabled" :can-add-argument="canAddArgument" ref="saveSourceButton" @argument-added="onArgumentAdded"/>
  </div>

  <div class="row mt-4">
    <div class="col">
      <button class="btn btn-light me-4" :disabled="canAddArgument" @click="reactivateButton()">Reactivate</button>
      <button class="btn me-4" :class="isDisabled ? 'btn-danger' : 'btn-success'" @click="toggleDisable()">{{ disabledText }}</button>
    </div>
  </div>
</template>
<script>

import SaveSourceButton from '@/components/SaveSourceButton/SaveSourceButton'

export default {
  name: 'SaveSourceButtonDev',
  data() {
    return {
      disabled: false,
      canAddArgument: true,
    };
  },
  components: {
    SaveSourceButton,
  },
  methods: {
    reactivateButton() {
      this.canAddArgument = true;
    },
    toggleDisable() {
      this.disabled = !this.disabled;
    },
    onArgumentAdded() {
      this.canAddArgument = false;
    }
  },
  computed: {
    disabledText() {
      return this.disabled ? 'Disabled' : 'Enabled';
    },
    isDisabled() {
      return this.disabled;
    },
  }
};
</script>
<style lang="scss" scoped>
  .save-source-button-dev-wrapper{
    width: 30px;
    height: 10px;
    margin: 0px 25px 50px 45px;
    position: relative;
  }

</style>
