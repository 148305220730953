<template>
  <transition :name="transitionName" mode="out-in">
    <div class="intro-view-wrapper" :class="introWrapperClasses" v-bind:key="currentIntroPageIndex">
      <div class="intro-view-content" :class="introContentWrapperClasses">
        <div v-if="canGoBack" class="back-button">
          <BackButton @click="onClickBackButton" />
        </div>
        <div class="reset-button">
        </div>
        <div class="intro-content-style">
          <VideoModal v-if="videoModalOpen" :videoUrl="currentVideoUrl" @closeModal="closeVideoModal()"/>
          <DocumentModal v-if="documentModalOpen" :documentId="currentDocumentId" @closeModal="closeDocumentModal()" />
          <BlackTransitionView v-if="currentPageType === 'blackTransitionView'" @transitionCompleted="progressFlow()" />
          <SelectLanguageView v-if="currentPageType === 'languageSelect'" @confirmLanguageSelect="progressFlow()" />
          <StartView v-if="currentPageType === 'startView'" />
          <InfoView v-if="currentPageType === 'infoView'" :data="currentPageData"/>
          <InfoScrollView v-if="currentPageType === 'infoScrollView'" :data="currentPageData" @pageCompleted="setPageEmittedComplete"/>
          <SituationView v-else-if="currentPageType === 'situationView'" :data="currentPageData"/>
          <EmailView v-else-if="currentPageType === 'emailView'" :data="currentPageData" @openDocument="openDocumentModal" @pageCompleted="setPageEmittedComplete"/>
          <TextInputView v-else-if="currentPageType === 'textInputView'" :data="currentPageData"/>
          <PhoneView v-else-if="currentPageType === 'phoneConversationView'" :data="currentPageData" @pageCompleted="setPageEmittedComplete"/>
        </div>
        <div class="button-wrapper d-flex">
          <div v-for="(button, i) in buttons" :key="i">
            <AppButton :color="'light_grey'" @click="buttonClick(button)" :disabled="continueButtonDisabled(button.type)"
                       v-if="isButtonHidden(button)" :key="pageEmittedComplete">
              {{ $t(button.text) }}
            </AppButton>
          </div>
        </div>
        <div v-if="showLogos">
          <logo-container/>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

import { mapState } from 'vuex';
import IntroFlow from '@/data/introFlow.js';
import BlackTransitionView from '@/views/introViews/000-BlackTransitionView';
import StartView from '@/views/introViews/010-StartView';
import InfoView from '@/views/introViews/020-InfoView';
import SituationView from '@/views/introViews/030-SituationView';
import EmailView from '@/views/introViews/040-EmailView';
import TextInputView from '@/views/introViews/050-TextInputView';
import SelectLanguageView from '@/views/introViews/005-SelectLanguageView.vue';
import PhoneView from '@/views/introViews/060-PhoneView';
import AppButton from '@/components/common/AppButton';
import { VIEW_DILEMMA } from '@/enum/Views.js';
import {
  PAGE_TYPE_TEXT_INPUT,
  PAGE_TYPE_EMAIL,
  PAGE_TYPE_PHONE,
  PAGE_TYPE_INFO_SCROLL
} from '@/enum/PageTypes.js';
import {
  SET_CURRENT_INTRO_PAGE_INDEX,
  SET_BACKGROUND,
  RESET_GAME,
  SET_CURRENT_DILEMMA_ID
} from '../store/mutation-types';
import { BUTTON_TYPE_DOCUMENT, BUTTON_TYPE_PROGRESS, BUTTON_TYPE_VIDEO } from '@/enum/ButtonType';
import BackButton from '@/components/common/BackButton';
import VideoModal from '@/components/common/VideoModal';
import DocumentModal from '@/components/DocumentModal/DocumentModal';
import { ASSISTANT_TRIGGER_ON_PAGE_COMPLETE } from '@/enum/AssistantTriggers';
import { SERIOUS_ANALYTICS_KEY_START_APP } from '@/enum/SeriousAnalyticsKeys';
import InfoScrollView from './introViews/021-InfoScrollView';
import LogoContainer from '@/components/common/LogoContainer';

export default {
  name: 'IntroView',
  components: {
    LogoContainer,
    InfoScrollView,
    VideoModal,
    BackButton,
    InfoView,
    BlackTransitionView,
    SituationView,
    EmailView,
    PhoneView,
    TextInputView,
    StartView,
    SelectLanguageView,
    AppButton,
    DocumentModal,
  },
  data() {
    return {
      videoModalOpen: false,
      documentModalOpen: false,
      pageRequirementMet: true,
      pageEmittedComplete: false,
      currentVideoUrl: null,
      currentDocumentId: '',
      assistantMessageQueue: [],
    };
  },
  watch: {
    background() {
      this.$store.commit(SET_BACKGROUND, this.background);
    },
  },
  computed: {
    ...mapState([
      'dilemmas',
      'dilemmaUnlockedStatus',
      'currentIntroPageIndex',
      'currentIntroUnlockPageIndex',
      'playerName',
      'playerSurname',
      'playerPosition',
      'playerLocation',
      'expertData',
    ]),
    currentPageType() {
      return IntroFlow[this.currentIntroPageIndex].type;
    },
    currentPageData() {
      return IntroFlow[this.currentIntroPageIndex].data;
    },
    introWrapperClasses() {
      const wrapperClasses = [];

      if (this.currentPageType === 'emailView') {
        wrapperClasses.push('overflow-hidden');
      }

      return wrapperClasses;
    },
    introContentWrapperClasses() {
      const wrapperClasses = [];

      if (this.currentPageType !== 'emailView') {
        wrapperClasses.push('overflow-auto');
      }

      return wrapperClasses;
    },
    buttons() {
      return IntroFlow[this.currentIntroPageIndex]?.bottomButtons ? IntroFlow[this.currentIntroPageIndex].bottomButtons : [];
    },
    assistantData() {
      return IntroFlow[this.currentIntroPageIndex]?.assistantData ? IntroFlow[this.currentIntroPageIndex].assistantData : [];
    },
    transitionName() {
      return IntroFlow[this.currentIntroPageIndex]?.transition ? IntroFlow[this.currentIntroPageIndex].transition : 'short';
    },
    background() {
      return IntroFlow[this.currentIntroPageIndex].background;
    },
    conditionalContinue() {
      return IntroFlow[this.currentIntroPageIndex]?.conditionalContinue ? IntroFlow[this.currentIntroPageIndex].conditionalContinue : false;
    },
    isModalOpen() {
      return this.videoModalOpen || this.documentModalOpen;
    },
    showLogos() {
      if (this.currentPageType === 'infoView') {
        return true;
      }
      if (this.currentPageType === 'infoScrollView') {
        return true;
      }
      if (this.currentPageType === 'textInputView') {
        return true;
      }
      return false;
    },
    pageCompleted() {
      if (!this.conditionalContinue) {
        return true;
      }
      if (this.currentPageType === PAGE_TYPE_TEXT_INPUT) {
        // I need to change this so that it looks at multiple
        const inputData = this.currentPageData?.inputData;
        for (const input of inputData) {
          const inputStoreId = input?.inputStoreId;
          if (!inputStoreId) {
            return false;
          }
          if (inputStoreId === 'playerName') {
            if (this.playerName.length === 0) {
              return false;
            }
          } else if (inputStoreId === 'playerSurname') {
            if (this.playerSurname.length === 0) {
              return false;
            }
          } else if (inputStoreId === 'playerPosition') {
            if (this.playerPosition.length === 0) {
              return false;
            }
          } else if (inputStoreId === 'playerLocation') {
            if (this.playerLocation.length === 0) {
              return false;
            }
          } else {
            console.log('inputstoreId not matching any player variables', inputStoreId);
          }
        }
        return true;
      }
      if (this.currentPageType === PAGE_TYPE_EMAIL || this.currentPageType === PAGE_TYPE_PHONE || this.currentPageType === PAGE_TYPE_INFO_SCROLL) {
        return this.pageEmittedComplete;
      }
      return false;
    },
    canGoBack() {
      return this.currentIntroPageIndex !== 0;
    },
  },
  mounted() {
    this.$store.commit(SET_BACKGROUND, this.background);
    this.assistantMessageData = require('@/data/assistant/assistantData.json');
  },
  methods: {
    buttonClick(button) {
      const buttonType = button.type;
      const buttonData = button?.data;
      const buttonAnalytics = button?.seriousAnalytics || null;
      if (buttonType === BUTTON_TYPE_PROGRESS) {
        this.progressFlow();
      } else if (buttonType === BUTTON_TYPE_VIDEO) {
        this.videoModalOpen = true;
        if (buttonData) {
          this.currentVideoUrl = this.expertData.links[buttonData];
        }
      } else if (buttonType === BUTTON_TYPE_DOCUMENT) {
        this.documentModalOpen = true;
        if (buttonData) {
          this.currentDocumentId = buttonData;
        }
      }

      if (buttonAnalytics && buttonAnalytics === SERIOUS_ANALYTICS_KEY_START_APP) {
        this.$root.callStartEvent();
      }
    },
    continueButtonDisabled(buttonType) {
      if (buttonType === BUTTON_TYPE_PROGRESS) {
        // You don't have to perform page quests again, unless it's an input page where you've deleted your input.
        if ((this.currentIntroUnlockPageIndex >= this.currentIntroPageIndex || !this.conditionalContinue)
          && this.currentPageType !== PAGE_TYPE_TEXT_INPUT) {
          return false;
        }
        if (!this.pageCompleted) {
          return true;
        }
      }
      return false;
    },
    isButtonHidden(button) {
      const isHidden = button?.isHidden ? button.isHidden : false;
      const type = button?.type ? button.type : '';

      const continueButtonDisabled = this.continueButtonDisabled(type);

      return !isHidden || !continueButtonDisabled;
    },
    progressFlow() {
      // see if we can progress
      const introFlowLength = IntroFlow.length;
      const newIntroPageIndex = this.currentIntroPageIndex + 1;
      if (introFlowLength <= newIntroPageIndex) {
        this.$store.commit(SET_CURRENT_DILEMMA_ID, 'INTRO2');
        this.$router.push({ name: VIEW_DILEMMA });
      } else {
        // progress flow and set background
        this.$store.commit(SET_CURRENT_INTRO_PAGE_INDEX, newIntroPageIndex);
        this.loadNewPage();
      }
    },
    onClickBackButton() {
      // progress flow and set background
      if (this.currentIntroPageIndex === 0) {
        return;
      }
      this.$store.commit(SET_CURRENT_INTRO_PAGE_INDEX, this.currentIntroPageIndex - 1);
      this.loadNewPage();
    },
    onClickResetButton() {
      this.$store.commit(RESET_GAME);
      this.loadNewPage();
    },
    loadNewPage() {
      this.pageEmittedComplete = false;
      this.closeVideoModal();
      this.closeDocumentModal();
    },
    closeVideoModal() {
      this.videoModalOpen = false;
      if (this.assistantMessageQueue.length > 0) {
        for (const message of this.assistantMessageQueue) {
          this.$root.sendAssistantMessages(this.assistantMessageData[message]);
        }
      }
      this.assistantMessageQueue = [];
    },
    closeDocumentModal() {
      this.documentModalOpen = false;
      if (this.assistantMessageQueue.length > 0) {
        for (const message of this.assistantMessageQueue) {
          this.$root.sendAssistantMessages(this.assistantMessageData[message]);
        }
      }
      this.assistantMessageQueue = [];
    },
    setPageEmittedComplete() {
      this.pageEmittedComplete = true;

      if (this.assistantData.length > 0) {
        for (const assistantData of this.assistantData) {

          const trigger = assistantData?.trigger;
          const assistantDataId = assistantData?.assistantDataId;

          if (!trigger || !assistantDataId) {
            continue;
          }
          if (trigger === ASSISTANT_TRIGGER_ON_PAGE_COMPLETE) {
            if (!this.assistantMessageData.hasOwnProperty(assistantDataId)) {
              console.log('assistant data does not contain the assistant ID', assistantDataId);
              continue;
            }
            if (this.isModalOpen) {
              this.assistantMessageQueue.push(assistantDataId);
            } else {
              this.$root.sendAssistantMessages(this.assistantMessageData[assistantDataId]);
            }
          }
        }
      }
      // const instance = getCurrentInstance();
      // instance.proxy.forceUpdate();
    },
    openDocumentModal(attachmentID) {
      this.currentDocumentId = attachmentID;
      this.documentModalOpen = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.intro-view-wrapper {
  color: #ffffff;
  padding: 0px 0px 110px;
  height: 100%;
  width: 100%;

  .intro-view-content {
    padding: 70px 70px 0;
    height: 100%;
  }

  .intro-content-style  {
    z-index: 1;
  }

  .back-button {
    position: absolute;
    left: 80px;
    top: 60px;
    z-index: 200;
  }

  .reset-button {
    position: absolute;
    right: 165px;
    top: 15px;
    z-index: 200;
  }

  .button-wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;
    z-index: 200;
  }

}

</style>
