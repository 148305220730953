<template>
  <UseArgumentPanel :use-argument-panel-open="useArgumentPanelOpen"/>
  <div class="row mt-4">
    <h3>Dev buttons</h3>
    <div class="col">
      <button class="btn btn-light me-4" @click="openDataBreakWindow()">
        Open use argument window
      </button>
      <button class="btn btn-light me-4" @click="closeDataBreakWindow()">
        Close use argument window
      </button>
    </div>
  </div>
</template>
<script>

import UseArgumentPanel from '@/components/ChooseArgument/UseArgumentPanel';

export default {
  name: 'UseArgumentDev',
  components: {
    UseArgumentPanel,
  },
  emits: ['click'],
  data() {
    return {
      useArgumentPanelOpen: true,
    };
  },
  mounted() {
  },
  methods: {
    openDataBreakWindow() {
      this.useArgumentPanelOpen = true;
    },
    closeDataBreakWindow() {
      this.useArgumentPanelOpen = false;
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
