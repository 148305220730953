<template>
  <div class="document-vca4d-intro">
    <div v-for="(mission, index) in missions" v-show="currentPageIndex === index" :key="index">
      <div class="mission-title">
        {{ $t(mission.title) }}
      </div>
      <p v-html="getMissionText(index)" />
    </div>
  </div>
</template>

<script>
import missionTextKeys from '@/data/missionTextKeys';
import textToHtml from '@/utils/textToHtml';

export default {
  name: 'DocumentMission',
  props: {
    currentPageIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isMultiPage: true,
      numberOfPages: 6,
      missions: Array.from({ length: 7 }, (_, i) => ({
        title: missionTextKeys[i.toString()].title,
        textKey: i.toString(),
      })),
    };
  },
  methods: {
    getMissionText(index) {
      return textToHtml(this.$t(missionTextKeys[index.toString()].text), this.$t);
    },
  },
};
</script>

<style lang="scss" scoped>
.document-vca4d-intro {
  height: 100%;
  min-height: 600px;
}

.mission-title {
  color: #2B4146;
  margin-bottom: 25px;
  font-size: 1rem;
  font-weight: 600;
}

</style>
