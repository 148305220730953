<template>
  <div class="dilemma-policy-choice-view-wrapper">
    <div v-if="!choosePolicyVisible && numberOfPoliciesFound === 2">
      <div class="title-wrapper">
        <h3>{{ $t('dilemmaPolicyChoiceView.decisionPrompt') }}{{relevantDilemmaQuestion()}}</h3>
      </div>

      <div v-if="!choosePolicyVisible" class="button-container row">
        <div class="single-button-wrapper col">
          <AppButton :color="'light_grey'" :size="'large'" @click="goToChoosePolicy">
            {{ $t('misc.yesDot') }}
          </AppButton>
        </div>
        <div class="single-button-wrapper col">
          <AppButton :color="'light_grey'" :size="'large'" @click="backToDilemma">
            {{ $t('dilemmaPolicyChoiceView.broaderVision') }}
          </AppButton>
        </div>
      </div>
    </div>

    <div v-if="numberOfPoliciesFound === 3">
      <div class="title-wrapper">
        <h3 v-html="readyTextHtml" />
      </div>
    </div>

    <div v-if="choosePolicyVisible">
      <div class="stakeholder-wrapper d-flex">
        <div class="button-container row">
          <div v-for="(policy, i) in policies" :key="i" class="single-button-wrapper col">
            <div class="lock-container">
              <NoDragImage v-if="!policyCanBeChosen(i)" :src="getImageSrc('lock')" />
            </div>
            <div v-if="!policyCanBeChosen(i)" class="locked-button-overlay" @click="sendLockedMessage(i)" />
            <AppButton :disabled="!policyCanBeChosen(i)" color="light_grey" :size="'fill'" @click="finishDilemma(i)">
              {{ $t(policy.title) }}
            </AppButton>
          </div>
          <div class="single-button-wrapper col">
            <AppButton :color="'light_grey'" :size="'fill'" @click="backToDilemma">
              <div>
                <no-drag-image class="button-icon" src="@/assets/img/ui/looking-glass.png" />
              </div>
              <br>
              {{ $t('dilemmaPolicyChoiceView.keepInvestigating') }}
            </AppButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import AppButton from '@/components/common/AppButton';
import {
  SET_BACKGROUND, SET_DILEMMA_UNLOCKED_STATUS, SET_POLICY_UNLOCKED_STATUS,
} from '@/store/mutation-types';
import { BACKGROUND_DILEMMA_POLICY_SELECT } from '@/enum/Background';
import {
  STATUS_AVAILABLE,
  STATUS_COMPLETED,
  STATUS_LOCKED, STATUS_SELECTED,
  STATUS_UNLOCKED,
} from '@/enum/UnlockedStatus';
import { VIEW_DILEMMA, VIEW_OVERVIEW } from '@/enum/Views';
import { getDilemmaFromId } from '@/utils/dataUtil';
import textToHtml from '@/utils/textToHtml';
import NoDragImage from '../components/common/NoDragImage';

export default {
  name: 'DilemmaPolicyChoiceView',
  components: {
    NoDragImage,
    AppButton,
  },
  data() {
    return {
      choosePolicyVisible: false,
    };
  },
  computed: {
    ...mapState([
      'dilemmaUnlockedStatus',
      'currentDilemmaId',
      'dilemmas',
    ]),
    numberOfPoliciesFound() {
      let numberOfPoliciesFound = 0;
      if (this.currentDilemmaId === null) {
        return numberOfPoliciesFound;
      }

      if (!this.currentPolicyUnlockStatus) {
        return numberOfPoliciesFound;
      }

      for (const key of Object.keys(this.currentPolicyUnlockStatus)) {
        if (this.currentPolicyUnlockStatus[key] !== STATUS_LOCKED) {
          numberOfPoliciesFound += 1;
        }
      }
      return numberOfPoliciesFound;
    },
    currentDilemma() {
      return getDilemmaFromId(this.dilemmas, this.currentDilemmaId);
    },
    policies() {
      return this.dilemmas[this.currentDilemmaId].policyUnlocks;
    },
    currentPolicyUnlockStatus() {
      return this.dilemmaUnlockedStatus?.[(this.currentDilemmaId).toString()]?.policies;
    },
    readyTextHtml() {
      const text = this.$t('dilemmaPolicyChoiceView.ready');
      return textToHtml(text, this.$t);
    },
    containsChosenPolicy() {
      if (!this.currentPolicyUnlockStatus) {
        return false;
      }

      for (const unlockStatusId in this.currentPolicyUnlockStatus) {
        if (this.currentPolicyUnlockStatus[unlockStatusId] === STATUS_SELECTED) {
          return true;
        }
      }
      return false;
    },
  },
  mounted() {
    this.$store.commit(SET_BACKGROUND, BACKGROUND_DILEMMA_POLICY_SELECT);

    this.assistantData = require('@/data/assistant/assistantData.json');

    if (this.numberOfPoliciesFound === 3) {
      this.choosePolicyVisible = true;
    }
  },
  methods: {
    relevantDilemmaQuestion() {
      return this.$t(`misc.dilemmaQuestion${this.currentDilemmaId}`);
    },
    backToDilemma() {
      if (this.numberOfPoliciesFound === 2) {
        this.$root.sendAssistantMessages(this.assistantData.feedbackKeepOnInvestigating);
      }

      this.$router.push({ name: VIEW_DILEMMA });
    },
    getImageSrc(img) {
      const images = require.context('../assets/img/ui');
      const imgSrc = images(`./${img}.png`);
      return imgSrc;
    },
    policyUnlocked(policyId) {
      return this.currentPolicyUnlockStatus?.[policyId] === STATUS_UNLOCKED;
    },
    policyCanBeChosen(policyId) {
      if (this.containsChosenPolicy) {
        return this.currentPolicyUnlockStatus?.[policyId] === STATUS_SELECTED;
      }
      return this.policyUnlocked(policyId);
    },
    goToChoosePolicy() {
      this.choosePolicyVisible = true;
    },
    sendLockedMessage(policyId) {
      const policyUnlocked = this.currentPolicyUnlockStatus?.[policyId];
      if (policyUnlocked === STATUS_LOCKED) {
        this.$root.sendAssistantMessages(this.assistantData.feedbackPolicySelectionLocked);
      }
    },
    finishDilemma(policyId) {
      // send assistant message
      this.sendAssistantMessage(policyId);

      // analytics
      this.$root.callDilemmaEndEvent(this.currentDilemmaId, this.numberOfPoliciesFound);

      // set dilemma status to complete
      let data = {
        dilemmaId: this.currentDilemmaId,
        newStatus: STATUS_COMPLETED,
      };
      this.$store.commit(SET_DILEMMA_UNLOCKED_STATUS, data);

      // set policy selected
      const policyData = {
        dilemmaId: this.currentDilemmaId,
        policyId,
        newStatus: STATUS_SELECTED,
      };

      this.$store.commit(SET_POLICY_UNLOCKED_STATUS, policyData);

      // unlock dilemmas if applicable
      const { unlocksDilemmas } = this.currentDilemma;

      for (const dilemmaId of unlocksDilemmas) {
        data = {
          dilemmaId,
          newStatus: STATUS_AVAILABLE,
        };
        const dilemmaUnlockStatus = this.dilemmaUnlockedStatus?.[(dilemmaId).toString()]?.status;

        if (dilemmaUnlockStatus && dilemmaUnlockStatus !== STATUS_COMPLETED) {
          this.$store.commit(SET_DILEMMA_UNLOCKED_STATUS, data);
        }
      }

      // go to overview
      this.$router.push({ name: VIEW_OVERVIEW });
    },
    sendAssistantMessage() {
      if (this.numberOfPoliciesFound === 2) {
        this.$root.sendAssistantMessages(this.assistantData.feedbackPolicySelectionLimited);
      }
      if (this.numberOfPoliciesFound === 3) {
        this.$root.sendAssistantMessages(this.assistantData.feedbackPolicySelectionCongratz);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dilemma-policy-choice-view-wrapper {

  text-align: center;
  padding: 12% 10% 0 10%;
  max-width: 80%;
  margin-right: auto;
  margin-left: auto;

  .title-wrapper {
    margin-top: 65px;
  }

  .button-container {
    position: relative;
    margin-top: 12vh;

    .single-button-wrapper {
      position: relative;

      .locked-button-overlay {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }

      .lock-container {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -85%);
        z-index: 2;
        width: 15%;
        img {
          width: 100%;
        }
      }
    }
  }
}

</style>
