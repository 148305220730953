<template>
  <div class="tutorial-highlight-overlay-wrapper-dev-wrapper">
    <TutorialHighlightOverlay :highlight-id="highlightId" :type="highlightType" :offset="highlightOffset"/>
    <div id="circle" class="circle"></div>
    <div id="roundedSquare" class="rounded-square"></div>
  </div>

  <div class="row mt-4 buttons">
    <h2 id="idTitle">Current higlightId: {{highlightId}}</h2>
    <div id="allbuttons" class="col">
      <button class="btn btn-light me-4" @click="removeHighlightId()">set to null</button>
      <button class="btn btn-light me-4" @click="setHighlightId('idTitle')">set to 'idTitle'</button>
      <button class="btn btn-light me-4" @click="setHighlightId('allbuttons')">set to 'allbuttons'</button>
      <button id="button" class="btn btn-light me-4" @click="setHighlightId('button')">set to 'button'</button>
      <button class="btn btn-light me-4" @click="setHighlightId('circle')">set to 'circle'</button>
      <button class="btn btn-light me-4" @click="setHighlightId('roundedSquare')">set to 'roundedSquare'</button>
    </div>
    <div class="row">
      <div class="col">
        <h2>Current offset: {{highlightOffset}}</h2>
        <button class="btn btn-light me-4" @click="setHighlightOffset(0)">set to 0</button>
        <button class="btn btn-light me-4" @click="setHighlightOffset(10)">set to 10</button>
        <button class="btn btn-light me-4" @click="setHighlightOffset(30)">set to 30</button>
      </div>
    </div>
  </div>
</template>
<script>

import TutorialHighlightOverlay from '@/components/TutorialHighlightOverlay/TutorialHighlightOverlay';
import { TYPE_SQUARE, TYPE_SLIGHTLY_ROUNDED, TYPE_ROUNDED, TYPE_CIRCLE } from '@/components/TutorialHighlightOverlay/TutorialHighlightOverlay';

export default {
  name: 'TutorialHighlightOverlayDev',
  components: { TutorialHighlightOverlay },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      highlightId: '',
      highlightType: TYPE_SQUARE,
      highlightOffset: 50,
    };
  },
  emits: ['click'],
  methods: {
    setHighlightId(highlightId) {
      this.highlightId = highlightId;
      if (highlightId === 'circle') {
        this.highlightType = TYPE_CIRCLE;
      } else if (highlightId === 'roundedSquare') {
        this.highlightType = TYPE_ROUNDED;
      } else if (highlightId === 'button') {
        this.highlightType = TYPE_SLIGHTLY_ROUNDED;
      } else {
        this.highlightType = TYPE_SQUARE;
      }
    },
    setHighlightOffset(highlightOffset) {
      this.highlightOffset = highlightOffset;
    },
    removeHighlightId() {
      this.highlightId = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.tutorial-highlight-overlay-wrapper-dev-wrapper {
  z-index: 0;
}

.circle {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: #aa0000;
}

.rounded-square {
  position: absolute;
  top: 50%;
  left: 30%;
  height: 150px;
  width: 100px;
  border-radius: 12px;
  background-color: #0000aa;
}
.buttons {
  z-index: 1;
}
</style>
