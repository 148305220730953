<template>
  <div class="language-select-popup">
    <div class="language-select-container d-flex justify-content-center flex-wrap flex-column">
      <div class="language-select-row">
        <i class="select-language-icon" />
        <v-select
          v-model="selectedLanguage"
          class="language-select"
          :options="languages"
          :reduce="country => country.code"
          :selectable="country => country.enabled"
          :clearable="false"
          :filterable="false"
          :searchable="false"
        >
          <template #open-indicator="{ attributes }">
            <i v-bind="attributes" class="chevron-down-icon" />
          </template>
        </v-select>
      </div>
      <app-button color="white" @click="confirmLanguageSelect">
        {{ $t('misc.continue') }}
      </app-button>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import AppButton from '@/components/common/AppButton.vue';
import vSelect from 'vue-select';
import {
  CHANGE_IS_LANGUAGE_SELECT_PAGE,
} from '@/store/mutation-types';

export default {
  name: 'SelectLanguageView',
  components: { AppButton, vSelect },
  props: {
  },
  emits: ['confirmLanguageSelect'],
  data() {
    return {
      selectedLanguage: '',
      languages: [
        { code: 'en', label: 'English', enabled: true },
        { code: 'fr', label: 'Français', enabled: true },
        { code: 'pt', label: 'Português', enabled: false },
        { code: 'es', label: 'Español', enabled: false },
      ],
    };
  },
  computed: {
    ...mapState([
      'currentLocale',
      'isLanguageSelectPage',
    ]),
  },
  watch: {
    selectedLanguage(val) {
      console.log('changeLanguage', val);
      this.$i18n.locale = val;
    },
  },
  mounted() {
    this.selectedLanguage = this.currentLocale;
    this.$store.commit(CHANGE_IS_LANGUAGE_SELECT_PAGE, true);
  },
  methods: {
    confirmLanguageSelect() {
      this.$store.commit(CHANGE_IS_LANGUAGE_SELECT_PAGE, false);
      this.$emit('confirmLanguageSelect');
    },
  },
};
</script>

<style lang="scss" scoped>
//>>> {
//  --vs-font-size: 2rem;
//}

.language-select-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #2C4A57;
  border: 1px solid #333333;
  transform: translate(-50%, -50%);
  border-radius: 10px;

  box-shadow: 0px 12px 9.3px 0px rgba(0, 0, 0, 0.63);
  -webkit-box-shadow: 0px 12px 9.3px 0px rgba(0, 0, 0, 0.63);
  -moz-box-shadow: 0px 12px 9.3px 0px rgba(0, 0, 0, 0.63);
  color: black;

  .language-select-container {
    padding: 70px 120px 50px 120px;

    .language-select-row {
      display: flex;
      gap: 30px;

      .language-select {
        width: 620px;
        --vs-font-size: 1.5rem;
        --vs-dropdown-option--active-bg: #283F46;
        --vs-dropdown-option-padding: 15px 15px;
        font-size: 1.5rem;
        font-weight: bold;
        background-color: #f9f9f9;
        color: #283F46;
        box-shadow: 0px -6.48px 0px 0px rgba(0, 0, 0, 0.4) inset;
        border-radius: 10px;
        margin-bottom: 70px;
      }
    }

    .app-button {
      margin: auto;
    }
  }
}
//chevron-down.svg
</style>

<style lang="scss">
.language-select {
  .vs__dropdown-toggle {
    padding: 10px 15px 15px 15px;
  }
  .vs__dropdown-menu {
    margin-top: 5px;
    margin-left: 1rem;
    width: calc(100% - 2rem);
    padding: 1px 0 0 0;
    box-shadow: 0px 12px 9.3px 0px rgba(0, 0, 0, 0.63);
    -webkit-box-shadow: 0px 12px 9.3px 0px rgba(0, 0, 0, 0.63);
    -moz-box-shadow: 0px 12px 9.3px 0px rgba(0, 0, 0, 0.63);
  }
  .vs__dropdown-option:first-child {
    border-top: none;
  }
  .vs__dropdown-option {
    border-top: #283F46 solid 1px;
  }
}
.select-language-icon {
  width: 85px;
  height: 80px;
  background-image: url("~@/assets/img/ui/select-language-icon.png");
  background-repeat: no-repeat;
}
.chevron-down-icon {
  width: 30px;
  height: 24px;
  background-image: url("~@/assets/img/ui/chevron-down.svg");
  background-repeat: no-repeat;
}
</style>
