<template>
  <div class="row">
    <div v-for="event in events" :key="event" class="col text-center">
      <div :class="eventsActive[event] ? 'event-tick-active' : 'event-tick-inactive'"
           class="event-tick"
           v-text="event"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventTicks',
  props: {
    events: {
      type: Array,
      required: true,
    },
    eventsActive: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.event-tick {
  display: inline-block;
  transition-property: background-color;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
  background: #333333;
  border-radius: 12.5px;
  width: 100%;
  height: 25px;
  font-size: 12px;
  color: white;
  line-height: 25px;

  &.event-tick-active {
    transition-duration: 0ms;
    background: #ff5555;
  }
}
</style>
