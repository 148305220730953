<template>
  <div v-if="loading" class="container pt-2">
    Loading..
  </div>
  <div v-else class="container pt-2">
    <div class="row">
      <label class="form-label">Component</label>
      <select v-model="selectedDevComponent" class="form-control">
        <option value="">
          Select a dev component
        </option>
        <option v-for="devComponentName in devComponentNames" :key="devComponentName"
                :value="devComponentName"
        >
          {{ devComponentName }}
        </option>
      </select>
    </div>
    <div class="row mt-2 component-container">
      <component :is="selectedDevComponent" v-if="selectedDevComponent" />
      <div v-else>
        Select a Dev component in the dropdown above
      </div>
    </div>
  </div>
</template>

<script>

import ChoiceButtonDev from '@/components/ChoiceButton/ChoiceButtonDev';
import StoryDialogueDev from '@/components/StoryDialogue/dev/StoryDialogueDev';
import StakeholderGroupDev from '@/components/Stakeholder/StakeholderGroupDev';
import KnowledgeVaultFullPanelDev from '@/components/KnowledgeVault/KnowledgeVaultFullPanelDev';
import SaveSourceButtonDev from '@/components/SaveSourceButton/SaveSourceButtonDev';
import AssistantDev from '@/components/AssistantBanner/AssistantDev';
import OnboardingBarDev from '@/components/OnboardingBar/OnboardingBarDev';
import UseArgumentDev from '@/components/ChooseArgument/UseArgumentDev';
import TutorialHighlightOverlayDev from '@/components/TutorialHighlightOverlay/TutorialHighlightOverlayDev';
import DataBreakDev from '../components/DataBreak/DataBreakDev';
import Data6PagerButtonDev from '../components/Data6PagerButton/Data6PagerButtonDev';
import DilemmaButtonDev from '../components/DilemmaButton/DilemmaButtonDev';
import PersistentAssistantButtonDev from '../components/PersistentAssistantButton/PersistentAssistantButtonDev';

const devComponents = {
  ChoiceButtonDev,
  StoryDialogueDev,
  StakeholderGroupDev,
  KnowledgeVaultFullPanelDev,
  SaveSourceButtonDev,
  AssistantDev,
  OnboardingBarDev,
  DataBreakDev,
  UseArgumentDev,
  Data6PagerButtonDev,
  TutorialHighlightOverlayDev,
  DilemmaButtonDev,
  PersistentAssistantButtonDev,
};

export default {
  name: 'DevView',
  components: devComponents,
  data() {
    return {
      devComponentNames: Object.keys(devComponents),
      selectedDevComponent: '',
      loading: false,
    };
  },
  computed: {
    currentDevComponent() {
      return this.$router.currentRoute?.value?.params?.component;
    },
  },
  watch: {
    currentDevComponent() {
      if (this.currentDevComponent === this.selectedDevComponent) {
        return;
      }

      this.selectedDevComponent = this.currentDevComponent || '';
    },
    selectedDevComponent() {
      if (this.currentDevComponent === this.selectedDevComponent) {
        return;
      }

      this.$router.push(`/dev/${this.selectedDevComponent}`);
    },
  },
  mounted() {
    this.selectedDevComponent = this.currentDevComponent || '';
  },
};
</script>

<style lang="scss" scoped>
.component-container {
  border: 1px solid #333333;
  padding: 10px;
  overflow-x: hidden;

  &.component-theme-grey {
    background: #EDF1F4;
  }

  &.component-theme-blue {
    background: #45A4CD;
  }

  &.component-theme-white {
    background: #F8F8F8;
  }
}

</style>
