<template>
  <div className="situation-view-container">
    <div className="text-wrapper">
      <div class="icon-wrapper" v-if="icon">
        <NoDragImage class="news-item-image" :src="getImageSrc(icon)"/>
      </div>
      <h3 v-html="textHtml"/>
    </div>
  </div>
</template>

<script>

import textToHtml from '@/utils/textToHtml';
import NoDragImage from '@/components/common/NoDragImage';

export default {
  name: 'SituationView',
  components: { NoDragImage },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    icon() {
      return this.data?.icon;
    },
    text() {
      return this.data.text;
    },
    textHtml() {
      return textToHtml(this.$t(this.text), this.$t);
    },
  },
  methods: {
    getImageSrc(pictureId) {
      const images = require.context('../../assets/img/ui');
      const imgSrc = images(`./${pictureId}.png`);
      return imgSrc;
    },
  },
};
</script>

<style lang="scss" scoped>
.situation-view-container {
  color: #ffffff;
  font-weight: 700;
  font-size: 34px;

  .text-wrapper {
    width: 58%;
    max-width: fit-content;
    position: absolute;
    text-align: center;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);

    .icon-wrapper {
      margin: 15px;
      margin-bottom: 25px;
      width: 60px;
      margin-left: auto;
      margin-right: auto;

      img {
        width: 100%;
      }
    }
  }
}

</style>
