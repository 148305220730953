<template>
  <div class="cheat-window" :class="cheatsWindowOpen ? '' : 'cheat-window-closed'">
    <div class="toggle-button" @click="onClickToggle">dev</div>
    <div class="back-button" @click="onClickBackButton">✖</div>
    <AppButton :size="'small'" color="grey" @click="resetGame()">
      Reset game
    </AppButton>
    <AppButton :size="'small'" color="grey" @click="finishIntro()">
      Finish intro
    </AppButton>
    <AppButton :size="'small'" color="grey" @click="finishTutorial()">
      Finish tutorial
    </AppButton>
    <AppButton :size="'small'" color="grey" @click="skipToInformalDG()">
      Skip to informal PD
    </AppButton>
    <AppButton :size="'small'" color="grey" @click="finishInformalDG()">
      Finish informal PD
    </AppButton>
    <AppButton :size="'small'" color="grey" @click="finishAllDilemmas()">
      Finish all dilemmas
    </AppButton>
    <AppButton :size="'small'" color="grey" @click="skipToBossFight()">
      Skip to boss fight
    </AppButton>
    <AppButton :size="'small'" color="grey" @click="changeLocale('blank')">
      Change language: blank
    </AppButton>
    <AppButton :size="'small'" color="grey" @click="changeLocale('en')">
      Change language: english
    </AppButton>
    <AppButton :size="'small'" color="grey" @click="changeLocale('fr')">
      Change language: french
    </AppButton>
  </div>
</template>
<script>

import AppButton from '@/components/common/AppButton';
import {
  CHEAT_FINISH_INFORMAL_PD,
  CHEAT_SKIP_INTRO,
  CHEAT_SKIP_TO_INFORMAL_PD,
  CHEAT_SKIP_TUTORIAL,
  RESET_GAME,
  CHEAT_FINISH_ALL_DILEMMAS, CHEAT_SKIP_TO_BOSS_FIGHT,
} from '@/store/mutation-types';
import { VIEW_DILEMMA, VIEW_EVAL_AND_PD, VIEW_INTRO, VIEW_OVERVIEW } from '@/enum/Views';

export default {
  name: 'CheatWindow',
  components: { AppButton },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  data() {
    return {
      cheatsWindowOpen: false,
    };
  },
  computed: {
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
    },
    onClickBackButton() {
      this.closeCheatWindow();
    },
    closeCheatWindow() {
      this.cheatsWindowOpen = false;
    },
    onClickToggle() {
      this.cheatsWindowOpen = !this.cheatsWindowOpen;
    },
    resetGame() {
      this.closeCheatWindow();
      this.$store.commit(RESET_GAME);
      this.$router.push({ name: VIEW_INTRO });
    },
    finishIntro() {
      this.closeCheatWindow();
      this.$store.commit(CHEAT_SKIP_INTRO);
      this.$router.push({ name: VIEW_DILEMMA });
    },
    finishTutorial() {
      this.closeCheatWindow();
      this.$store.commit(CHEAT_SKIP_TUTORIAL);
      this.$router.push({ name: VIEW_DILEMMA });
    },
    skipToInformalDG() {
      this.closeCheatWindow();
      this.$store.commit(CHEAT_SKIP_TUTORIAL);
      this.$store.commit(CHEAT_SKIP_TO_INFORMAL_PD);
      this.$router.push({ name: VIEW_OVERVIEW });
    },
    finishInformalDG() {
      this.closeCheatWindow();
      this.$store.commit(CHEAT_SKIP_TUTORIAL);
      this.$store.commit(CHEAT_SKIP_TO_INFORMAL_PD);
      this.$store.commit(CHEAT_FINISH_INFORMAL_PD);
      this.$router.push({ name: VIEW_OVERVIEW });
    },
    finishAllDilemmas() {
      this.closeCheatWindow();
      this.$store.commit(CHEAT_SKIP_TUTORIAL);
      this.$store.commit(CHEAT_SKIP_TO_INFORMAL_PD);
      this.$store.commit(CHEAT_FINISH_ALL_DILEMMAS);
      this.$router.push({ name: VIEW_OVERVIEW });
    },
    skipToBossFight() {
      this.closeCheatWindow();
      this.$store.commit(RESET_GAME);
      this.$store.commit(CHEAT_SKIP_TUTORIAL);
      this.$store.commit(CHEAT_SKIP_TO_INFORMAL_PD);
      this.$store.commit(CHEAT_SKIP_TO_BOSS_FIGHT);
      this.$router.push({ name: VIEW_EVAL_AND_PD });
    },
  },
};
</script>
<style lang="scss" scoped>
  .cheat-window {
    position: absolute;
    right: 150px;
    top: 0;
    background-color: #F6E6E6;
    border-radius: 0px 0px 0 15px;
    color: #000;
    width: fit-content;
    padding: 46px 20px 25px;
    transition-property: transform;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
  }

  .cheat-window-closed {
    transform: translate(0%, -100%);
  }

  .toggle-button {
    position: absolute;
    background-color: #F6E6E6;
    color: #000;
    border-radius: 0px 0 15px 15px;
    bottom: 0;
    right: 0;
    padding: 1px 10px;
    transform: translateY(96%);
    cursor: pointer;
  }

  .back-button {
    position: absolute;
    font-size: 1.5em;
    color: #777;
    right: 20px;
    top: 10px;
    cursor: pointer;
  }

</style>
