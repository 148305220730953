<template>
  <div class="message-anticipation-dots-wrapper">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </div>
</template>
<script>

export default {
  name: 'MessageAnticipationDots',
  props: {

  },
  components: {
  },
  emits: [],
  data() {
    return {
      canAddArgument: true,
    };
  },
  watch: {
    messageId() {
      this.canAddArgument = true;
    },
  },
  computed: {

    isSpeakerMessage() {
      return this.type === 0;
    },
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
$choiceColor: #F4F4F4;
$tickSize: 25px;
$tickSizeSmall: 15px;
$tickDistanceRight: 45px;

$minWidth: ($tickSize * 2) + $tickDistanceRight;

.message-anticipation-dots-wrapper {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 0px 10px;
  border-radius: 25px;

  .dot {
    height: 10px;
    width: 10px;
    border-radius: 100%;
    margin: 3px;
    display: inline-block;
    background-color: #4C6066;
    animation: 1.2s anticipation-dot ease-in-out infinite;
  }

  .dot:nth-of-type(2) {
    animation-delay: 0.14s;
  }

  .dot:nth-of-type(3) {
    animation-delay: 0.24s;
  }

  @keyframes anticipation-dot {
    15% {
      transform: translateY(-37%);
      opacity: 0.45;
    }
    30% {
      transform: translateY(0%);
      opacity: 1;
    }
  }
}

</style>
