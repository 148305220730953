<template>
  <div class="accordion" :style="style">
    <div ref="accordionInner" class="accordion-inner">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  props: {
    open: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      fullHeight: 0,
    };
  },
  computed: {
    style() {
      const calcHeight = this.open ? this.fullHeight : 0;

      return {
        height: `${calcHeight}px`,
      };
    },
  },
  watch: {
    open() {
      if (this.open) {
        // Just opened
        requestAnimationFrame(() => {
          this.recalculateHeight(); // Maybe ???
          requestAnimationFrame(() => {
            this.recalculateHeight();
          });
        });
      }
    },
  },
  created() {
    window.addEventListener('resize', this.recalculateHeightOnResize);
  },
  mounted() {
    this.recalculateHeight();
  },
  unmounted() {
    window.removeEventListener('resize', this.recalculateHeightOnResize);
  },
  methods: {
    recalculateHeightOnResize() {
      this.recalculateHeight();
    },
    recalculateHeight() {
      this.fullHeight = this.$refs.accordionInner.clientHeight;
    },
  },
};
</script>

<style lang="scss">
.accordion {
  transition-duration: 180ms;
  transition-property: height;
  transition-timing-function: cubic-bezier(0.250, 0.460, 0.450, 0.940);
  overflow: hidden;
  height: 0;
}

</style>
