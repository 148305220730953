const enVocabulary = require('./localization/en/vocabulary.json');
const enDataBreakData = require('./localization/en/data-break-data.json');
const enPlacements = require('./localization/en/data-6-pager-placement.json');
const enLinks = require('./localization/en/links.json');

const ptVocabulary = require('./localization/pt/vocabulary.json');
const ptDataBreakData = require('./localization/pt/data-break-data.json');
const ptPlacements = require('./localization/pt/data-6-pager-placement.json');
const ptLinks = require('./localization/pt/links.json');

const esVocabulary = require('./localization/es/vocabulary.json');
const esDataBreakData = require('./localization/es/data-break-data.json');
const esPlacements = require('./localization/es/data-6-pager-placement.json');
const esLinks = require('./localization/es/links.json');

const frVocabulary = require('./localization/fr/vocabulary.json');
const frDataBreakData = require('./localization/fr/data-break-data.json');
const frPlacements = require('./localization/fr/data-6-pager-placement.json');
const frLinks = require('./localization/fr/links.json');

const blankVocabulary = require('./localization/blank/vocabulary.json');
const blankDataBreakData = require('./localization/blank/data-break-data.json');
const blankPlacements = require('./localization/blank/data-6-pager-placement.json');
const blankLinks = require('./localization/blank/links.json');

export default {
  en: {
    vocabulary: enVocabulary,
    dataBreakData: enDataBreakData,
    placements: enPlacements,
    links: enLinks,
  },
  pt: {
    vocabulary: ptVocabulary,
    dataBreakData: ptDataBreakData,
    placements: ptPlacements,
    links: ptLinks,
  },
  es: {
    vocabulary: esVocabulary,
    dataBreakData: esDataBreakData,
    placements: esPlacements,
    links: esLinks,
  },
  fr: {
    vocabulary: frVocabulary,
    dataBreakData: frDataBreakData,
    placements: frPlacements,
    links: frLinks,
  },
  blank: {
    vocabulary: blankVocabulary,
    dataBreakData: blankDataBreakData,
    placements: blankPlacements,
    links: blankLinks,
  },
};
