<template>
  <div class="print-vocabulary-outer-wrapper">
    <div class="print-vocabulary-wrapper">
      <div class="document-pager">
        <div v-for="(vocabularyItem, i) in vocabularyData" :key="i">
          <div class="glossary-entry-wrapper">
            <div class="vocabulary-header d-flex">
              <div class="vocabulary-name">
                {{ $t('misc.name') }}
              </div>
              <div :class="vocabularyItem.bonusTechnicalInsight ? 'vocabulary-content' : 'vocabulary-content-full'">
                {{ $t('misc.content') }}
              </div>
              <div v-if="vocabularyItem.bonusTechnicalInsight" class="vocabulary-bonus">
                {{ $t('misc.bonusInsights') }}
              </div>
            </div>
            <div class="vocabulary-body d-flex">
              <div class="vocabulary-name">
                {{ i }}
              </div>
              <div :class="vocabularyItem.bonusTechnicalInsight ? 'vocabulary-content' : 'vocabulary-content-full'">
                {{ vocabularyItem.technicalInsight }}
              </div>
              <div v-if="vocabularyItem.bonusTechnicalInsight" class="vocabulary-bonus">
                {{ vocabularyItem.bonusTechnicalInsight }}
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>

import { mapState } from 'vuex';

export default {
  name: 'PrintVocabulary',
  computed: {
    ...mapState([
      'expertData',
    ]),
    vocabularyData() {
      return this.expertData ? this.expertData.vocabulary : [];
    },
  },
};
</script>

<style lang="scss" scoped>

  .print-vocabulary-outer-wrapper {
    position: absolute;
    width: 100%;
    height: fit-content;
    top: 0;
    left: 0;
    background-color: #fff;
  }

  .print-vocabulary-wrapper {
    background-color: #fff;
    width: 700px;
    height: fit-content;
    padding: 25px;
    font-size: 0.85rem;
  }
 .document-pager {
   position: relative;
   width: 100%;
   height: fit-content;

   .vocabulary-name {

     padding: 10px 20px;
     width: 20%;
   }

   .vocabulary-content {
     border-left: 3px solid #2F7E96;

     padding: 10px 20px;
     width: 40%;
   }

   .vocabulary-content-full {
     border-left: 3px solid #2F7E96;

     padding: 10px 20px;
     width: 80%;
   }

   .vocabulary-bonus {
     width: 40%;

     padding: 10px 20px;
     border-left: 3px solid #2F7E96;
   }

   .glossary-entry-wrapper {
     padding-bottom: 20px;
     font-size: 13px;
     display: block;
     page-break-inside: avoid;

     .vocabulary-header {
       z-index: 999999;
       right: -36%;

       color: #fff;
       background-color: #2F7E96;
       font-weight: 600;

       border-radius: 12px 12px 0 0;
     }

     .vocabulary-body {
       z-index: 999999;
       right: -36%;
       border-radius: 0 0 12px 12px;
       border: solid #2F7E96 3px;
       color: #000;
     }
   }

   .pager-image {
     position: relative;
     width: 100%;
     img {
       width: 100%;
     }
   }
 }
</style>
