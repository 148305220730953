<template>
  <div class="side-menu-wrapper">
    <div class="tab-menu-wrapper d-flex">
      <div v-for="(tab, j) in tabs" :key="j"
           class="menu-tab d-flex flex-fill"
           @click="selectTab(j)"
           :class="studyBriefMenuTabIndex === j ? 'selected' : ''">
        <div class="tab-image" :class="tab.tabClass"/>
        <div class="tab-title">
          {{$t(tab.tabTitle)}}
        </div>
      </div>
    </div>
    <div class="content-wrapper">
      <div v-if="studyBriefMenuTabIndex === 0" class="content-expert-comments">
        <div v-if="noExpertDataAvailable" class="cursive">
          {{ $t('misc.noExpertComments') }}
        </div>
        <div v-if="!noExpertDataAvailable">
          <h5 class="fat-text" v-html="expertDataTitleHtml"/>
          <div v-html="expertDataTextHtml"/>
        </div>
      </div>
      <div v-if="studyBriefMenuTabIndex === 1" class="content-data">
        <p class="fat-text">
          {{ $t('misc.dataAvailableTitle') }}
        </p>
        <ul>
          <li v-for="(dataItem, i) in sortedDataIds6Pager" :key="i" @click="clickDataPoint({dataKey: dataItem.dataKey, dataId: dataItem.id})">
            {{dataItem.name}}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import textToHtml from '@/utils/textToHtml';
import { mapState } from 'vuex';
import { SET_STUDY_BRIEF_TAB_INDEX } from '@/store/mutation-types';

export default {
  name: 'SideMenu6Pager',
  props: {
    expertDataTitle: {
      type: String,
      default: '',
    },
    expertDataText: {
      type: String,
      default: '',
    },
    dataIds6Pager: {
      type: Array,
      required: true,
    },
    currentPageIndex: {
      type: Number,
      default: 0,
    },
  },
  emits: ['dataPointClicked'],
  data() {
    return {
      tabs: [
        {
          tabTitle: 'misc.experts',
          tabClass: 'tab-expert',
        },
        {
          tabTitle: 'misc.data',
          tabClass: 'tab-data',
        },
      ],
    };
  },
  computed: {
    ...mapState([
      'studyBriefMenuTabIndex',
      'expertData',
    ]),
    dataBreakData() {
      return this.expertData ? this.expertData.dataBreakData : {};
    },
    filteredDataIds6Pager() {
      if (!this.dataIds6Pager) {
        return [];
      }

      const pageFilteredList = this.dataIds6Pager.filter((dataId) => dataId.page === this.currentPageIndex);
      const duplicateFreeList = Array.from(new Set(pageFilteredList.map((item) => item.dataKey)))
        .map((dataKey) => pageFilteredList.find((item) => item.dataKey === dataKey));

      for (const dataPoint of duplicateFreeList) {
        dataPoint.name = this.getDataItemName(dataPoint.dataKey);
      }
      return duplicateFreeList;
    },
    sortedDataIds6Pager() {
      return this.filteredDataIds6Pager.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0; // names are equal
      });
    },
    expertDataTitleHtml() {
      return this.expertDataTitle === '' ? '' : textToHtml(this.$t(this.expertDataTitle), this.$t);
    },
    expertDataTextHtml() {
      return this.expertDataText === '' ? '' : textToHtml(this.$t(this.expertDataText), this.$t);
    },
    noExpertDataAvailable() {
      return this.expertDataText === '';
    },
  },
  methods: {
    selectTab(i) {
      console.log("Selected tab index:", i);
      this.$store.commit(SET_STUDY_BRIEF_TAB_INDEX, i);
    },
    getDataItem(dataKey) {
      return this.dataBreakData?.[dataKey];
    },
    getDataItemName(dataKey) {
      return this.getDataItem(dataKey)?.name;
    },
    clickDataPoint(dataKey) {
      this.$emit('dataPointClicked', dataKey);
    },
  },
};
</script>

<style lang="scss" scoped>
.side-menu-wrapper {
  height: 100%;
  width: 100%;
  color: #0A282F;
  min-width: 260px;

  .tab-menu-wrapper {
    transform: translateY(0.3px);
    height: 40px;
    .menu-tab {
      background-color: #2B5156;
      color: #fff;
      padding: 5px 10px;
      border-radius: 10px 10px 0 0;
      cursor: pointer;

      .tab-image {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: 5px;
        &.tab-expert {
          background-image: url("./../../assets/img/ui/tab-icon-expert-data-white.png");
          margin-top: 5px;
          height: 20px;
          width: 20px;
        }
        &.tab-data {
          background-image: url("./../../assets/img/ui/tab-icon-globe-white.png");
          height: 25px;
          width: 25px;
        }
      }
      &.selected {
        background-color: #EDF1F4;
        color: #0A282F;
        .tab-expert {
          background-image: url("./../../assets/img/ui/tab-icon-expert-data.png");
        }
        .tab-data {
          background-image: url("./../../assets/img/ui/tab-icon-globe.png");
        }
      }
    }
  }
  .content-wrapper {
    padding: 20px 15px 45px;
    background-color: #EDF1F4;
    width: 100%;
    height: calc(100% - 40px);
    overflow-y: auto;
    .content-data {
      li {
        cursor: pointer;
      }
      li:hover {
        color: #4A686F;
        text-decoration: underline;
      }
    }
  }
}
</style>
