<template>
  <div class="">
    <div class="final-scores-view-container d-flex">
      <div class="personal-score-wrapper">
        <h4 class="title">Certificate of completion of the VCA Game</h4>
        <h2 class="title">{{playerName}} {{playerSurname}} </h2>
        <div class="personal-score-wrapper-inner">
          <div class="personal-score-item">
            <div class="d-flex justify-content-between">
              <h3 class="score-title">Building your arguments based on evidence</h3>
              <h3 class="score-grade">Score: {{ evidenceBasedScoreObject.argumentsScore }}</h3>
            </div>
            <hr>
            <p class="fat-text">The evidence-based score is at the core of this game. You were evaluated based on</p>
            <ul>
              <li>Harvesting data: showing both curiosity and rigour when checking facts and opinions, and saving selected data into your Knowledge Vault.</li>
              <li>Transforming relevant data in preparation of the policy dialogue, according to the scenario you were promoting.</li>
            </ul>
            <p class="fat-text">This score tells about your ability to build solid arguments based on evidence based knowledge, by both making sure you revise all the information, but also being able to discern the most relevant one.</p>

            <div class="personal-score-border">
              <p class="fat-text">{{ evidenceBasedScoreObject.scoreArgumentsTitle }}</p>
              <p class="">{{ evidenceBasedScoreObject.scoreArgumentsText }}</p>
            </div>
          </div>
          <div class="personal-score-item">
            <div class="d-flex justify-content-between">
              <h3 class="score-title">Negotiating based on evidence</h3>
              <h3 class="score-grade">Score: {{ evidenceBasedScoreObject.negotiationScore }}</h3>
            </div>
            <hr>
            <p class="fat-text">This score is also at the core of the game! You had four opportunities to use data to convince your partner to support your scenario.</p>

            <div class="personal-score-border">
              <p class="">{{ evidenceBasedScoreObject.scoreNegotiationText }}</p>
            </div>
          </div>
          <div class="personal-score-item">
            <div class="d-flex justify-content-between">
              <h3 class="score-title">Sustainability Appreciation</h3>
              <h3 class="score-grade">Score: {{ sustainabilityAppreciationObject.score }}</h3>
            </div>
            <hr>
            <p class="quote-text">
              “Sustainable development means meeting the needs of the present whilst ensuring future generations can meet their own needs.
              It has three pillars: economic, environmental and social. To achieve sustainable development, policies in these three areas have to work together and support each other.”
            </p>
            <div class="ml-2 mt-1 mb-4">
              <a class="blue" href="https://policy.trade.ec.europa.eu/development-and-sustainability/sustainable-development_en#:~:text=Sustainable%20development%20means%20meeting%20the,together%20and%20support%20each%20other" target="_blank">~ European Commission </a>
            </div>
            <p class="fat-text">The characters you met during your journey warned you about the risks associated with each scenario you could choose from.
              No scenario is perfect, and in a dream world, doing a bit of all of them could have a positive impact.
              Yet, consistency was forced upon you, and you did choose one of the three.
            </p>
            <div class="personal-score-border">
              <p class="" v-html="sustainabilityAppreciationObject.scoreTextHtml"></p>
            </div>
          </div>
          <div class="personal-score-item">
            <div class="d-flex justify-content-between">
              <h3 class="score-title">Onboarding points</h3>
              <h3 class="score-grade">Score: {{ onboardingScoreTextObject.score }}</h3>
            </div>
            <hr>
            <div class="personal-score-border">
              <p>{{ onboardingScoreTextObject.scoreText }}</p>
            </div>
          </div>
        </div>
        <h4 class="title">Certificate of completion of the VCA Game</h4>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import {
  createEvidenceBasedScoreFinalEvalObject,
  createOnboardingPointFinalEvalText, createSustainabilityFinalEvalObject,
} from '@/utils/finalScoreUtil';

export default {
  name: 'PrintResults',
  props: {
  },
  emits: [''],
  mounted() {
    console.log(new XMLSerializer().serializeToString(document));
  },
  computed: {
    ...mapState([
      'evidencePointsHarvestData',
      'evidencePointsTransformData',
      'evidencePointsBossFight',
      'dealAccepted',
      'stakeholderOnboardStatus',
      'selectedScenarioIndex',
      'playerName',
      'playerSurname',
    ]),
    evidenceBasedScoreObject() {
      return createEvidenceBasedScoreFinalEvalObject(this.evidencePointsHarvestData, this.evidencePointsTransformData, this.evidencePointsBossFight, this.$t);
    },
    onboardingScoreTextObject() {
      return createOnboardingPointFinalEvalText(this.accumulatedStakeholderPoints, this.selectedScenarioIndex, this.dealAccepted, this.$t);
    },
    sustainabilityAppreciationObject() {
      return createSustainabilityFinalEvalObject(this.selectedScenarioIndex, this.$t);
    },
    accumulatedStakeholderPoints() {
      let accumulatedStakeholderPoints = 0;

      for (const stakeholder in this.stakeholderOnboardStatus) {
        if (this.stakeholderOnboardStatus?.[stakeholder]) {
          accumulatedStakeholderPoints += this.stakeholderOnboardStatus[stakeholder];
        }
      }

      return accumulatedStakeholderPoints;
    },
  },
};
</script>

<style lang="scss" scoped>

.final-scores-view-container {
  color: #ffffff;
  font-weight: 700;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 5%;

  .title {
    color: #1B3136;
  }

  .score-title {
    width: 70%;
    font-size: 1.6rem;
  }

  .score-grade {
    width: 25%;
    text-align: right;
    font-size: 1.6rem;
  }

  .evaluated-on-wrapper {
    position: relative;
    width: 30vw;
    height: 100%;
    margin-right: 15px;

    .evaluated-on-wrapper-inner {
      position: relative;
      height: 100%;

      .evaluated-on-content {
        position: relative;
        z-index: 1;
        padding: 15px;
        height: 100%;

        .evaluated-on-content-item {
          border: 1px solid #FFFFFF;
          border-radius: 12.6071px;
          margin: 15px 0;
          padding: 15px;
        }

        .evaluated-on-content-item:first-of-type {
          margin-top: 0;
        }

        .evaluated-on-content-item:last-of-type {
          margin-bottom: 0;
        }
      }

      .background-color {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-color: #0A282F;
        opacity: 0.25;
        border-radius: 12.6071px;
        z-index: 0;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }

  .personal-score-wrapper {
    width: 80vw;
    margin-left: auto;
    margin-right: auto;

    .title {
      text-align: center;
    }

    .personal-score-border {
      border: #0A282F 1px solid;
      border-radius: 15px;
      padding: 18px 15px;
    }

    .personal-score-wrapper-inner {
      background: #fff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 12.6071px;
      color: #283F46;
      width: 100%;
      padding: 25px 50px;

      .personal-score-item {
        margin-bottom: 55px;
        page-break-inside: avoid;

        p {
          font-weight: 400;
          font-size: 17px;
        }
      }

      .personal-score-item:last-of-type {
        margin-bottom: 100px;
      }
    }
  }

  .logo-wrapper {
    position: relative;
    margin-right: auto;
    margin-left: auto;
    width: 30%;
  }

}
</style>
