<template>
  <div v-if="showPopup" class="analytics-permission-popup-container">
    <div class="analytics-permission-popup">
      {{ $t('analytics.permissionText') }}
      <br>
      <div class="d-flex justify-content-center">
        <button class="btn btn-success mx-1 mt-2" @click="onClickGrantPermission">
          {{ $t('misc.yes') }}
        </button> <button class="btn btn-danger mx-1 mt-2" @click="onClickRejectPermission">
          {{ $t('misc.no') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { SeriousAnalyticsEvent } from 'serious-analytics';
import { mapState } from "vuex";

export default {
  name: 'AnalyticsPermissionPopup',
  emits: ['permission:granted', 'permission:rejected'],
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapState([
      'isLanguageSelectPage',
    ]),
    showPopup() {
      return !this.isLanguageSelectPage && this.show;
    },
  },
  mounted() {
    this.$seriousAnalytics.on(SeriousAnalyticsEvent.PermissionRequested, this.onPermissionRequested);
    this.$seriousAnalytics.on(SeriousAnalyticsEvent.PermissionGranted, this.onPermissionGranted);
    this.$seriousAnalytics.on(SeriousAnalyticsEvent.PermissionRejected, this.onPermissionRejected);
  },
  beforeUnmount() {
    try {
      this.$seriousAnalytics.off(SeriousAnalyticsEvent.PermissionRequested, this.onPermissionRequested);
      this.$seriousAnalytics.off(SeriousAnalyticsEvent.PermissionGranted, this.onPermissionGranted);
      this.$seriousAnalytics.off(SeriousAnalyticsEvent.PermissionRejected, this.onPermissionRejected);
    } catch (error) {
      console.log('An error occured', error);
    }
  },
  emit: ['permission:granted', 'permission:rejected'],
  methods: {
    onPermissionRequested() {
      this.show = true;
    },
    onPermissionGranted() {
      this.show = false;

      this.$emit('permission:granted');
    },
    onPermissionRejected() {
      this.show = false;

      this.$emit('permission:rejected');
    },
    onClickGrantPermission() {
      this.$seriousAnalytics.grantPermission();
    },
    onClickRejectPermission() {
      this.$seriousAnalytics.rejectPermission();
    },
  },
};
</script>

<style lang="scss" scoped>
  .analytics-permission-popup-container {
    background: rgba(255, 255, 255, .8);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000000;
  }

  .analytics-permission-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    background: #f8f8f8;
    padding: 50px;
    border: 1px solid #333333;
    transform: translate(-50%, -50%);

    box-shadow: 10px 10px 27px 0 rgba(0,0,0,0.75);
    -webkit-box-shadow: 10px 10px 27px 0 rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 27px 0 rgba(0,0,0,0.75);
    color: black;
  }
</style>
