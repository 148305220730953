<template>
  <div class="document-vca4d-intro">
    <p v-html="textHtml" />
  </div>

</template>

<script>

import textToHtml from '@/utils/textToHtml';

export default {
  name: 'DocumentScenarioExplanation',
  components: {},
  data() {
    return {
      isMultiPage: false,
    };
  },
  computed: {
    textHtml() {
      const text = this.$t('document.scenarioExplanation.text');
      return textToHtml(text, this.$t);
    },
  },
};
</script>
<style lang="scss" scoped>
.document-vca4d-intro {
  min-height: 300px;
}

</style>
