<template>
  <div class="start-view-container">
    <div class="image-container d-flex align-self-end">
      <no-drag-image class="eu-logo" src="@/assets/img/ui/EN_fundedbyEU_VERTICAL_RGB_BLACK_OUTLINE.png"/>
      <no-drag-image class="mx-4 vca4d-logo" src="@/assets/img/ui/vca_game_logo_black.png" />
      <no-drag-image class="agrinatura-logo" src="@/assets/img/ui/agrinatura-logo-black.png" @click="clickLogo" />
    </div>
    <div class="title-wrapper">
      <h1>{{ $t('gameName') }}</h1>
    </div>
  </div>
</template>

<script>

import NoDragImage from '@/components/common/NoDragImage';
import { ENABLE_CHEATS } from '@/store/mutation-types';
import { mapState } from 'vuex';

export default {
  name: 'InfoView',
  components: { NoDragImage },
  props: {
  },
  data() {
    return {
      clicks: [],
      secretActivated: false,
    };
  },
  computed: {
    ...mapState([
      'cheatsWindowActivated',
    ]),
    cannotContinueToBossFight() {
      return !this.allDilemmasCompleted;
    },
  },
  methods: {
    clickLogo() {
      if (this.cheatsWindowActivated) {
        return;
      }

      const now = Date.now();
      this.clicks.push(now);

      // Filter out clicks that are older than 1 second
      this.clicks = this.clicks.filter((clickTime) => now - clickTime < 1000);

      // Check if there are at least 6 clicks within the last second
      if (this.clicks.length === 6) {
        this.$store.commit(ENABLE_CHEATS);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.start-view-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 16%;
  width: 80%;
  max-width: 70vw;

  .image-container {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
    width: fit-content;

    img {
      width: 11vw;
      height: auto;
    }

    .eu-logo {
      padding-bottom: 0.7vw;
    }

    .agrinatura-logo {
      padding-bottom: 0.9vw;
      padding-top: 3.4vw;
    }

    .vca4d-logo {
      padding-top: 1.6vw;
      padding-bottom: 0.7vw;
    }
  }

  .title-wrapper {
    text-align: center;
    color: #251D13;
    h1 {
      font-size: 7.2vw;
      letter-spacing: 2.5px;
    }
  }
}
</style>
