<template>
  <transition :name="transitionName" mode="out-in">
    <div :key="currentEvalAndPDPageIndex" class="intro-view-wrapper" :class="introWrapperClasses">
      <div class="intro-view-content" :class="introContentWrapperClasses">
        <div class="reset-button"/>
        <div class="intro-content-style">
          <VideoModal v-if="videoModalOpen" :video-url="currentVideoUrl" @closeModal="closeVideoModal()"/>
          <DocumentModal v-if="documentModalOpen" :document-id="currentDocumentId" @closeModal="closeDocumentModal()"/>
          <BlackTransitionView v-if="currentPageType === 'blackTransitionView'" @transitionCompleted="progressFlow()"/>
          <StartView v-if="currentPageType === 'startView'"/>
          <InfoView v-if="currentPageType === 'infoView'" :data="currentPageData" @pageCompleted="setPageEmittedComplete"/>
          <SituationView v-else-if="currentPageType === 'situationView'" :data="currentPageData"/>
          <EmailView v-else-if="currentPageType === 'emailView'" :data="currentPageData" @openDocument="openDocumentModal" @pageCompleted="setPageEmittedComplete"/>
          <TextInputView v-else-if="currentPageType === 'textInputView'" :data="currentPageData"/>
          <PhoneView v-else-if="currentPageType === 'phoneConversationView'" :data="currentPageData" @pageCompleted="setPageEmittedComplete"/>
          <DynamicTextView v-else-if="currentPageType === 'dynamicTextView'" :data="currentPageData"/>
          <BossFightView v-else-if="currentPageType === 'bossFightConversationView'" :data="currentPageData" @pageCompleted="setPageEmittedComplete"/>
          <FinalScoresView v-else-if="currentPageType === 'finalScoresView'" :data="currentPageData"/>
        </div>
      </div>
      <div class="button-wrapper d-flex">
        <div v-for="(button, i) in buttons" :key="i">
          <AppButton v-if="isButtonHidden(button)" :key="pageEmittedComplete" :color="'light_grey'" size="medium"
                     :disabled="continueButtonDisabled(button.type)" @click="buttonClick(button)">
            {{ $t(button.text) }}
          </AppButton>
        </div>
      </div>
      <div v-if="showLogos">
        <logo-container/>
      </div>
    </div>
  </transition>
</template>

<script>

import { mapState } from 'vuex';
import EvalAndPDFlow from '@/data/evalAndPDFlow.js';
import BlackTransitionView from '@/views/introViews/000-BlackTransitionView';
import StartView from '@/views/introViews/010-StartView';
import InfoView from '@/views/introViews/020-InfoView';
import SituationView from '@/views/introViews/030-SituationView';
import EmailView from '@/views/introViews/040-EmailView';
import TextInputView from '@/views/introViews/050-TextInputView';
import PhoneView from '@/views/introViews/060-PhoneView';
import DynamicTextView from '@/views/introViews/070-DynamicTextView';
import BossFightView from '@/views/introViews/080-BossFightView';
import AppButton from '@/components/common/AppButton';
import { isConditionMet } from '@/utils/dataUtil';
import {
  PAGE_TYPE_EMAIL,
  PAGE_TYPE_PHONE,
  PAGE_TYPE_BOSS_FIGHT,
  PAGE_TYPE_INFO,
} from '@/enum/PageTypes.js';
import {
  BUTTON_TYPE_DOCUMENT,
  BUTTON_TYPE_PROGRESS,
  BUTTON_TYPE_VIDEO,
  BUTTON_TYPE_REPLAY_GAME,
  BUTTON_TYPE_DOWNLOAD,
  BUTTON_TYPE_FEEDBACK,
} from '@/enum/ButtonType';
import VideoModal from '@/components/common/VideoModal';
import DocumentModal from '@/components/DocumentModal/DocumentModal';
import { ASSISTANT_TRIGGER_ON_PAGE_COMPLETE } from '@/enum/AssistantTriggers';
import { VARIABLE_TYPE_DEAL_ACCEPTED } from '@/enum/VariableType';
import finalScoreHtmlTemplate from '@/data/pdfData/finalScoreHtmlTemplate';

import { saveStaticPdf, createAndSavePdf } from '@/utils/pdfUtil';
import {
  createEvidenceBasedScoreFinalEvalObject,
  createOnboardingPointFinalEvalText, createSustainabilityFinalEvalObject,
} from '@/utils/finalScoreUtil';
import { SERIOUS_ANALYTICS_KEY_END_APP } from '@/enum/SeriousAnalyticsKeys';
import LogoContainer from '@/components/common/LogoContainer';
import {
  SET_CURRENT_INTRO_PAGE_INDEX,
  SET_BACKGROUND,
  RESET_GAME,
  SET_EVAL_AND_PD_PAGE_INDEX, ADD_EVIDENCE_TRANSFORM_POINTS,
} from '@/store/mutation-types';
import FinalScoresView from './introViews/090-FinalScoresView';

export default {
  name: 'EvalAndPD',
  components: {
    LogoContainer,
    FinalScoresView,
    DynamicTextView,
    VideoModal,
    InfoView,
    SituationView,
    EmailView,
    PhoneView,
    TextInputView,
    BossFightView,
    StartView,
    AppButton,
    DocumentModal,
    BlackTransitionView,
  },
  data() {
    return {
      videoModalOpen: false,
      documentModalOpen: false,
      pageRequirementMet: true,
      pageEmittedComplete: false,
      currentVideoUrl: null,
      currentDocumentId: '',
      assistantMessageQueue: [],
      hasTransformedArguments: false,
      previouslyTransformedArguments: [],
    };
  },
  computed: {
    ...mapState([
      'currentEvalAndPDPageIndex',
      'playerName',
      'evidencePointsHarvestData',
      'evidencePointsTransformData',
      'evidencePointsBossFight',
      'playerSurname',
      'dealAccepted',
      'argumentIndexesPerPolicyIndex',
      'selectedScenarioIndex',
      'stakeholderOnboardStatus',
      'expertData',
      'currentLocale',
    ]),
    dataBreakData() {
      return this.expertData ? this.expertData.dataBreakData : {};
    },
    introWrapperClasses() {
      const wrapperClasses = [];

      if (this.currentPageType === 'emailView') {
        wrapperClasses.push('overflow-hidden');
      }

      return wrapperClasses;
    },
    introContentWrapperClasses() {
      const wrapperClasses = [];

      if (this.currentPageType !== 'emailView') {
        wrapperClasses.push('overflow-auto');
      }

      return wrapperClasses;
    },
    currentPageType() {
      return EvalAndPDFlow?.[this.currentEvalAndPDPageIndex]?.type;
    },
    currentPageData() {
      return EvalAndPDFlow?.[this.currentEvalAndPDPageIndex]?.data;
    },
    buttons() {
      return EvalAndPDFlow?.[this.currentEvalAndPDPageIndex]?.bottomButtons ? EvalAndPDFlow[this.currentEvalAndPDPageIndex].bottomButtons : [];
    },
    showLogos() {
      return EvalAndPDFlow?.[this.currentEvalAndPDPageIndex]?.showLogos ? EvalAndPDFlow[this.currentEvalAndPDPageIndex].showLogos : false;
    },
    assistantData() {
      return EvalAndPDFlow?.[this.currentEvalAndPDPageIndex]?.assistantData ? EvalAndPDFlow[this.currentEvalAndPDPageIndex].assistantData : [];
    },
    transitionName() {
      return EvalAndPDFlow?.[this.currentEvalAndPDPageIndex]?.transition ? EvalAndPDFlow[this.currentEvalAndPDPageIndex].transition : 'short';
    },
    background() {
      return EvalAndPDFlow?.[this.currentEvalAndPDPageIndex]?.background;
    },
    conditionalContinue() {
      return EvalAndPDFlow?.[this.currentEvalAndPDPageIndex]?.conditionalContinue ? EvalAndPDFlow[this.currentEvalAndPDPageIndex].conditionalContinue : false;
    },
    conditionalShowPage() {
      return EvalAndPDFlow?.[this.currentEvalAndPDPageIndex]?.conditionalShowPage || false;
    },
    isModalOpen() {
      return this.videoModalOpen || this.documentModalOpen;
    },
    pageCompleted() {
      if (!this.conditionalContinue) {
        return true;
      }
      if (this.currentPageType === PAGE_TYPE_INFO && this.currentPageData?.showKnowledgeVaultButton) {
        let numberOfSavedArguments = 0;

        for (const argumentListId in this.argumentIndexesPerPolicyIndex) {
          if (this.argumentIndexesPerPolicyIndex.hasOwnProperty(argumentListId)) {
            numberOfSavedArguments += this.argumentIndexesPerPolicyIndex[argumentListId].length;
          }
        }

        if (!this.pageEmittedComplete) {
          return false;
        }

        if (numberOfSavedArguments < 4) {
          return false;
        }

        return true;
      }
      if (this.currentPageType === PAGE_TYPE_EMAIL || this.currentPageType === PAGE_TYPE_PHONE ||this.currentPageType === PAGE_TYPE_BOSS_FIGHT || this.currentPageType === PAGE_TYPE_INFO) {
        return this.pageEmittedComplete;
      }
      return false;
    },
    canGoBack() {
      return this.currentEvalAndPDPageIndex !== 0;
    },
    evidenceBasedScoreObject() {
      return createEvidenceBasedScoreFinalEvalObject(this.evidencePointsHarvestData, this.evidencePointsTransformData, this.evidencePointsBossFight, this.$t);
    },
    onboardingScoreTextObject() {
      return createOnboardingPointFinalEvalText(this.accumulatedStakeholderPoints, this.selectedScenarioIndex, this.dealAccepted, this.$t);
    },
    sustainabilityAppreciationObject() {
      return createSustainabilityFinalEvalObject(this.selectedScenarioIndex, this.$t);
    },
    dilemmaStakeholdersMet() {
      // TODO THESE ARE MADE FROM STATIC NUMBERS WHEN THEY REALLY SHOULDN'T BE
      // ONLY THESE SPECIFIC PEOPLE ARE STAKEHOLDERS
      const dilemmaStakeholderId = ['8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19'];
      let dilemmaStakeholdersMet = 0;

      for (const stakeholder in this.stakeholderOnboardStatus) {
        if (dilemmaStakeholderId.includes(stakeholder)) {
          dilemmaStakeholdersMet += 1;
        }
      }

      return dilemmaStakeholdersMet;
    },
    accumulatedStakeholderPoints() {
      let accumulatedStakeholderPoints = 0;

      for (const stakeholder in this.stakeholderOnboardStatus) {
        if (this.stakeholderOnboardStatus?.[stakeholder]) {
          accumulatedStakeholderPoints += this.stakeholderOnboardStatus[stakeholder];
        }
      }

      return accumulatedStakeholderPoints;
    },
  },
  watch: {
    background() {
      this.$store.commit(SET_BACKGROUND, this.background);
    },
  },
  mounted() {
    this.$store.commit(SET_BACKGROUND, this.background);
    this.assistantMessageData = require('@/data/assistant/assistantData.json');
  },
  methods: {
    buttonClick(button) {
      const buttonType = button.type;
      const buttonData = button?.data;
      const buttonAnalytics = button?.seriousAnalytics || null;

      if (buttonType === BUTTON_TYPE_PROGRESS) {
        this.progressFlow();
      } else if (buttonType === BUTTON_TYPE_VIDEO) {
        this.videoModalOpen = true;
        if (buttonData) {
          this.currentVideoUrl = this.expertData.links[buttonData];
        }
      } else if (buttonType === BUTTON_TYPE_DOCUMENT) {
        this.documentModalOpen = true;
        if (buttonData) {
          this.currentDocumentId = buttonData;
        }
      } else if (buttonType === BUTTON_TYPE_REPLAY_GAME) {
        this.$root.resetGame();
      } else if (buttonType === BUTTON_TYPE_FEEDBACK) {
        // sends you to the feedback link
        window.open(this.expertData.links[buttonType], '_blank');
      } else if (buttonType === BUTTON_TYPE_DOWNLOAD) {
        if (!buttonData) {
          console.warn('No button data avaialble for download');
          return;
        }
        if (buttonData === 'technicalInsights') {
          saveStaticPdf(`agrinatura-technical-insights-${this.currentLocale}.pdf`);
        } else if (buttonData === 'myScore') {
          const date = new Date();
          createAndSavePdf({
            html: this.getPersonalizedPdfHtml(),
            contentDisposition: `test-pdf-${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}.${date.getMilliseconds()}.pdf`,
          });
        } else if (buttonData === 'currentPage') {
          const date = new Date();
          createAndSavePdf({
            html: new XMLSerializer().serializeToString(document),
            contentDisposition: `test-pdf-${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}.${date.getMilliseconds()}.pdf`,
          });
        }
      }

      if (buttonAnalytics && buttonAnalytics === SERIOUS_ANALYTICS_KEY_END_APP) {
        this.$root.callEndEvent(this.accumulatedStakeholderPoints, this.dilemmaStakeholdersMet);
      }
    },
    getPersonalizedPdfHtml() {
      let personalizedHtml = finalScoreHtmlTemplate.resultHtml;

      personalizedHtml = personalizedHtml.replace('{{certificate.title}}', this.$t('certificate.title'));
      personalizedHtml = personalizedHtml.replace('{{certificate.evidenceTitle}}', this.$t('certificate.evidenceTitle'));
      personalizedHtml = personalizedHtml.replaceAll('{{certificate.score}}', this.$t('certificate.score'));
      personalizedHtml = personalizedHtml.replace('{{certificate.evidenceBasedOn}}', this.$t('certificate.evidenceBasedOn'));
      personalizedHtml = personalizedHtml.replace('{{certificate.harverstingData}}', this.$t('certificate.harverstingData'));
      personalizedHtml = personalizedHtml.replace('{{certificate.transforming}}', this.$t('certificate.transforming'));
      personalizedHtml = personalizedHtml.replace('{{certificate.evidenceDetails}}', this.$t('certificate.evidenceDetails'));
      personalizedHtml = personalizedHtml.replace('{{certificate.negotiatingTitle}}', this.$t('certificate.negotiatingTitle'));
      personalizedHtml = personalizedHtml.replace('{{certificate.negotiatingDetails}}', this.$t('certificate.negotiatingDetails'));
      personalizedHtml = personalizedHtml.replace('{{certificate.sustainabilityAppreciationTitle}}', this.$t('certificate.sustainabilityAppreciationTitle'));
      personalizedHtml = personalizedHtml.replace('{{certificate.sustainabilityAppreciationDetails}}', this.$t('certificate.sustainabilityAppreciationDetails'));
      personalizedHtml = personalizedHtml.replace('{{certificate.europeanCommission}}', this.$t('certificate.europeanCommission'));
      personalizedHtml = personalizedHtml.replace('{{certificate.sustainabilityAppreciationDetails2}}', this.$t('certificate.sustainabilityAppreciationDetails2'));
      personalizedHtml = personalizedHtml.replace('{{certificate.onboardingPointsTitle}}', this.$t('certificate.onboardingPointsTitle'));
      personalizedHtml = personalizedHtml.replace('{{certificate.fundedBy}}', this.$t('certificate.fundedBy'));

      personalizedHtml = personalizedHtml.replace('{{evidenceHarvestArgumentScore}}', this.evidenceBasedScoreObject.argumentsScore);
      personalizedHtml = personalizedHtml.replace('{{evidenceHarvestArgumentTitle}}', this.evidenceBasedScoreObject.scoreArgumentsTitle);
      personalizedHtml = personalizedHtml.replace('{{evidenceHarvestArgumentsText}}', this.evidenceBasedScoreObject.scoreArgumentsText);
      personalizedHtml = personalizedHtml.replace('{{evidenceNegotiationText}}', this.evidenceBasedScoreObject.scoreNegotiationText);
      personalizedHtml = personalizedHtml.replace('{{evidenceNegotiationScore}}', this.evidenceBasedScoreObject.negotiationScore);
      personalizedHtml = personalizedHtml.replace('{{sustanabilityScore}}', this.sustainabilityAppreciationObject.score);
      personalizedHtml = personalizedHtml.replace('{{sustanabilityText}}', this.sustainabilityAppreciationObject.scoreTextHtml);
      personalizedHtml = personalizedHtml.replace('{{onboardingScore}}', this.onboardingScoreTextObject.score);
      personalizedHtml = personalizedHtml.replace('{{onboardingText}}', this.onboardingScoreTextObject.scoreText);
      personalizedHtml = personalizedHtml.replace('{{playerFirstName}}', this.playerName);
      personalizedHtml = personalizedHtml.replace('{{playerSurname}}', this.playerSurname);

      return personalizedHtml;
    },
    continueButtonDisabled(buttonType) {
      if (buttonType === BUTTON_TYPE_PROGRESS) {
        if (!this.conditionalContinue) {
          return false;
        }
        if (!this.pageCompleted) {
          return true;
        }
      }
      return false;
    },
    isButtonHidden(button) {
      const isHidden = button?.isHidden ? button.isHidden : false;
      const type = button?.type ? button.type : '';

      const continueButtonDisabled = this.continueButtonDisabled(type);

      return !isHidden || !continueButtonDisabled;
    },
    progressFlow() {
      // see if we can progress
      const EvalAndPDFlowLength = EvalAndPDFlow.length;
      const newIpdPageIndex = this.currentEvalAndPDPageIndex + 1;

      // Is the flow ending
      if (EvalAndPDFlowLength <= newIpdPageIndex) {
        // if not jumping to subflow we should go to overview and unlock dilemma 3
        // this.$router.push({ name: VIEW_OVERVIEW });
        alert('No more pages available at the current stage');
      } else {
        // ACTIONS BEFORE WE MOVE ON
        // logic for getting points from transformed arguments
        if (this.currentPageType === PAGE_TYPE_INFO && this.currentPageData?.showKnowledgeVaultButton) {
          for (const argumentListId in this.argumentIndexesPerPolicyIndex) {
            if (this.argumentIndexesPerPolicyIndex.hasOwnProperty(argumentListId)) {
              const argumentList = this.argumentIndexesPerPolicyIndex[argumentListId];
              for (const argumentId of argumentList) {
                if (!this.hasTransformedArguments) {
                  this.previouslyTransformedArguments.push(argumentId);
                }
                if (this.hasTransformedArguments) {
                  const pointsGivenForCurrentArgument = this.previouslyTransformedArguments.includes(argumentId);
                  if (pointsGivenForCurrentArgument) {
                    continue;
                  }
                }
                const argumentScenarioSupport = this.dataBreakData[argumentId].scenarioSupport[this.selectedScenarioIndex];
                const { isSupported, isSuperData } = argumentScenarioSupport;
                if (isSupported || isSuperData) {
                  this.$store.commit(ADD_EVIDENCE_TRANSFORM_POINTS, 2);
                } else {
                  this.$store.commit(ADD_EVIDENCE_TRANSFORM_POINTS, -1);
                }
              }
            }
          }

          if (!this.hasTransformedArguments) {
            this.hasTransformedArguments = true;
          }
        }

        // MOVE ON TO NEXT PAGE
        // progress flow and set background
        this.$store.commit(SET_EVAL_AND_PD_PAGE_INDEX, newIpdPageIndex);
        // this.currentEvalAndPDPageIndex++;

        // Logic for skipping pages that should not be shown based on conditionalShowPage
        if (this.conditionalShowPage) {
          const { value, operator, conditional } = this.conditionalShowPage;

          let shouldSkipPage = false;

          if (conditional === 'evidencePointsBossFight') {
            shouldSkipPage = !isConditionMet(this.evidencePointsBossFight, value, operator);
          }

          if (conditional === VARIABLE_TYPE_DEAL_ACCEPTED) {
            shouldSkipPage = !isConditionMet(this.dealAccepted, value, operator);
          }

          if (shouldSkipPage) {
            this.progressFlow();
            return;
          }
        }
        this.loadNewPage();
      }
    },
    onClickBackButton() {
      // progress flow and set background
      if (this.currentEvalAndPDPageIndex === 0) {
        return;
      }
      this.$store.commit(SET_CURRENT_INTRO_PAGE_INDEX, this.currentEvalAndPDPageIndex - 1);
      this.loadNewPage();
    },
    onClickResetButton() {
      this.$store.commit(RESET_GAME);
      this.loadNewPage();
    },
    loadNewPage() {
      this.pageEmittedComplete = false;
      this.closeVideoModal();
      this.closeDocumentModal();
    },
    closeVideoModal() {
      this.videoModalOpen = false;
      if (this.assistantMessageQueue.length > 0) {
        for (const message of this.assistantMessageQueue) {
          this.$root.sendAssistantMessages(this.assistantMessageData[message]);
        }
      }
      this.assistantMessageQueue = [];
    },
    closeDocumentModal() {
      this.documentModalOpen = false;
      if (this.assistantMessageQueue.length > 0) {
        for (const message of this.assistantMessageQueue) {
          this.$root.sendAssistantMessages(this.assistantMessageData[message]);
        }
      }
      this.assistantMessageQueue = [];
    },
    setPageEmittedComplete() {
      this.pageEmittedComplete = true;

      if (this.assistantData.length > 0) {
        for (const assistantData of this.assistantData) {
          const trigger = assistantData?.trigger;
          const assistantDataId = assistantData?.assistantDataId;

          if (!trigger || !assistantDataId) {
            continue;
          }

          if (trigger === ASSISTANT_TRIGGER_ON_PAGE_COMPLETE) {
            if (!this.assistantMessageData.hasOwnProperty(assistantDataId)) {
              console.log('assistant data does not contain the assistant ID', assistantDataId);
              continue;
            }

            if (this.isModalOpen) {
              this.assistantMessageQueue.push(assistantDataId);
            } else {
              this.$root.sendAssistantMessages(this.assistantMessageData[assistantDataId]);
            }
          }
        }
      }
      // const instance = getCurrentInstance();
      // instance.proxy.forceUpdate();
    },
    openDocumentModal(attachmentID) {
      this.currentDocumentId = attachmentID;
      this.documentModalOpen = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.intro-view-wrapper {
  color: #ffffff;
  height: 100%;
  width: 100%;
  padding: 0 0 110px;

  .intro-view-content {
    padding: 70px 70px 0;
    height: 100%;
  }
  @media(max-height: 785px) {
    .intro-view-content {
      padding: 3vh 70px 0;
    }
  }

  .intro-content-style  {
    z-index: 1;
  }

  .back-button {
    position: absolute;
    left: 25px;
    top: 15px;
    z-index: 200;
  }

  .reset-button {
    position: absolute;
    right: 165px;
    top: 15px;
    z-index: 200;
  }

  .button-wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;
    z-index: 200;
  }

}

</style>
