<template>
  <div class="save-source-button-dev-wrapper">
    <PersistentAssistantButton :disabled="isDisabled" assistant-message-id="feedbackKeepOnInvestigating"/>
  </div>

  <div class="row mt-4">
    <div class="col">
      <button class="btn me-4" :class="isDisabled ? 'btn-danger' : 'btn-success'" @click="toggleDisable()">{{ disabledText }}</button>
    </div>
  </div>
</template>
<script>

import PersistentAssistantButton from '@/components/PersistentAssistantButton/PersistentAssistantButton';

export default {
  name: 'PersistentAssistantButtonDev',
  data() {
    return {
      disabled: false,
    };
  },
  components: {
    PersistentAssistantButton,
  },
  methods: {
    toggleDisable() {
      this.disabled = !this.disabled;
    },
  },
  computed: {
    disabledText() {
      return this.disabled ? 'Disabled' : 'Enabled';
    },
    isDisabled() {
      return this.disabled;
    },
  },
};
</script>
<style lang="scss" scoped>
  .save-source-button-dev-wrapper{
    width: 30px;
    height: 10px;
    margin: 0px 25px 50px 45px;
    position: relative;
  }

</style>
