<template>
  <div class="email-module-container">
    <div class="top-bar-wrapper d-flex justify-content-between">
      <div class="left-top-bar d-flex">
        <div class="top-bar-icon top-bar-icon-archive" />
        <div class="top-bar-icon top-bar-icon-trash" />
      </div>
      <div class="right-top-bar d-flex">
        <div class="top-bar-icon top-bar-icon-return" />
        <div class="top-bar-icon top-bar-icon-star" />
        <div v-if="showCloseButton" class="back-button" @click="onClickBackButton">
          ✖
        </div>
      </div>
    </div>
    <div class="email-view-container-inner">
      <div class="text-wrapper">
        <p class="email-title" v-html="titleHtml" />
        <div class="sender-container d-flex justify-content-between">
          <div class="sender-icon">
            {{ senderFromFirstLetter }}
          </div>
          <div>
            <p class="email-from" v-html="senderFromHtml" />
            <p class="email-to grey-text" v-html="senderToHtml" />
          </div>
          <p class="email-when grey-text" v-html="senderWhenHtml" />
        </div>
        <p class="email-body" v-html="emailTextHtml" />
        <p class="email-sender" v-html="senderInfoHtml" />
        <div v-for="(attachment, i) in attachments" :key="i" class="attachment-wrapper">
          <AppButton size="large" color="green" type="icon_btn" @click="openAttachment(attachment.title, attachment.documentId)">
            <no-drag-image class="button-attachment-icon" src="@/assets/img/ui/attachment.png" /><span>{{ $t(attachment.title) }}</span>
          </AppButton>
          <div v-if="!attachmentsSeen?.[attachment.documentId]" class="attachment-notification">
            <NotificationIcon>
              !
            </NotificationIcon>
          </div>

        </div>
        <DocumentNewsfeed v-if="isNews"/>
      </div>
    </div>
  </div>
</template>
<script>

import textToHtml from '@/utils/textToHtml';
import AppButton from '@/components/common/AppButton';
import DocumentNewsfeed from '@/components/DocumentModal/Documents/DocumentNewsfeed';
import NotificationIcon from '@/components/common/NotificationIcon';
import NoDragImage from '@/components/common/NoDragImage';

export default {
  name: 'EmailModule',
  components: {
    NoDragImage, NotificationIcon, DocumentNewsfeed, AppButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    emailText: {
      type: String,
      required: true,
    },
    toFromData: {
      type: Object,
      default: null,
    },
    senderInfo: {
      type: String,
      default: '',
    },
    attachments: {
      type: Array,
      default: () => [],
    },
    attachmentsSeen: {
      type: Object,
      default: () => {},
    },
    isNews: {
      type: Boolean,
      default: false,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click', 'openAttachment', 'closeEmail'],
  data() {
    return {
      attachmentsOpened: {},
      allAttachmentsOpenedSent: false,
    };
  },
  computed: {
    titleHtml() {
      return textToHtml(this.$t(this.title), this.$t);
    },
    emailTextHtml() {
      const emailText = this?.emailText ? this.emailText : '';
      return textToHtml(this.$t(emailText), this.$t);
    },
    senderInfoHtml() {
      return this.senderInfo ? textToHtml(this.$t(this.senderInfo), this.$t) : '';
    },
    senderToHtml() {
      const toInfo = this.toFromData?.to ? this.toFromData.to : '';
      return textToHtml(this.$t(toInfo), this.$t);
    },
    senderFromHtml() {
      const fromInfo = this.toFromData?.from ? this.toFromData.from : '';
      return textToHtml(this.$t(fromInfo), this.$t);
    },
    senderWhenHtml() {
      const whenInfo = this.toFromData?.when ? this.toFromData.when : '';
      return textToHtml(this.$t(whenInfo), this.$t);
    },
    senderFromFirstLetter() {
      return this.senderFromHtml.length > 0 ? this.senderFromHtml[0] : '';
    },
  },
  mounted() {
    for (const attachment of this.attachments) {
      this.attachmentsOpened[attachment.title] = false;
    }
  },
  methods: {
    openAttachment(attachmentTitle, attachmentId) {
      this.$emit('openAttachment', attachmentTitle, attachmentId);
    },
    getImageSrc(pictureId) {
      const images = require.context('../../assets/img/newsImages');
      return images(`./${pictureId}.png`);
    },
    onClickBackButton() {
      this.$emit('closeEmail');
    },
  },
};
</script>
<style lang="scss" scoped>
$side_padding_outer: 25px;
$side_padding: 40px;
$title_size: 34px;

.email-module-container {
  position: relative;
  font-family: 'Fira Sans Condensed', 'Fira Sans', sans-serif;
  width: 100%;
  min-width: 440px;
  border-radius: 15px;
  height: 75vh;
  background-color: #F6F6F6;
  padding-top: 50px;

  .top-bar-wrapper {
    position: absolute;
    top: 10px;
    left: calc(#{$side_padding} / 2);
    width: calc(100% - #{$side_padding_outer} * 2);

    .left-top-bar {
      position: relative;
      display: block;
    }

    .right-top-bar {
      position: relative;
      display: block;
    }

    .top-bar-icon {
      width: 23px;
      height: 23px;
      background-repeat: no-repeat;
      background-size: contain;
      margin: 3px;
    }

    .top-bar-icon-archive {
      margin-top: 5px;
      height: 21px;
      background-image: url("./../../assets/img/ui/email-archive.png");
    }

    .top-bar-icon-trash {
      background-image: url("./../../assets/img/ui/email-trash.png");
    }

    .top-bar-icon-return {
      background-image: url("./../../assets/img/ui/email-return.png");
    }

    .top-bar-icon-star {
      height: 21px;
      margin-top: 4px;
      background-image: url("./../../assets/img/ui/email-star.png");
    }
  }

  .email-view-container-inner {
    position: relative;
    height: 100%;
    overflow-y: scroll;
    padding: 0 $side_padding_outer 45px $side_padding_outer;

    .grey-text {
      color: rgba(116, 116, 116, 1);
    }

    .text-wrapper {
      padding: 45px $side_padding 45px $side_padding;
      color: #000000;

      .email-title {
        font-weight: 700;
        font-size: $title_size;
      }

      .sender-container {
        width: 100%;

        .sender-icon {
          position: absolute;
          background-color: rgba(179, 179, 179, 1);
          height: 39px;
          width: 39px;
          line-height: 39px;
          border-radius: 50%;
          transform: translate(-115%, -3px);
          color: #fff;
          text-align: center;
          font-weight: 700;
          font-size: 22px;
        }

        .email-to {
          font-size: 18px;
          margin-top: -3px;
          margin-bottom: 10px;
        }

        .email-from {
          font-size: 18px;
          font-size: 22px;
          font-weight: 700;
          margin-bottom: 0;
        }

        .email-when {
          font-size: 18px;
        }
      }

      .email-body {
        font-size: 22px;
      }

      .email-sender {
        font-size: 18px;
      }
    }

    .attachment-wrapper {
      position: relative;
      max-width: 500px;
      .button-attachment-icon {
        padding: 2px 14px 2px 5px;
      }

      .attachment-notification {
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        height: 50px;
        pointer-events: none;
      }

    }

    .news-item-wrapper {
      background-color: #F0F0F0;
      margin: 7px 0;

      .news-item-image {
        object-fit: cover;
      }

      .news-item-text {
        position: relative;
        margin-left: 10px;
        padding: 10px 10px 25px 0;

        .news-item-text-title {
          font-size: 23px;
          font-weight: 500;
        }

        .news-item-text-newspaper {
          position: absolute;
          bottom: 3px;
          margin-bottom: 0;
          color: #585858;
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }
}

.back-button {
  position: absolute;
  font-size: 1.7em;
  z-index: 999999;
  color: #777;
  right: 20px;
  top: 25px;
  cursor: pointer;
}
</style>
