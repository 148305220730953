import { getTextFromArgument, getScenarioSuperPointSpecificText, getScenarioSuperPointSpecificTextAndPolicies } from '@/utils/dataUtil';
import store from '../store';
import { EVIDENCE_POINTS_FOR_DG_CHANGE_MIND } from '../data/evaluationData/scoreConstants';

export default function textToHtml(text, translate) {
  // get relevant store variables:
  const playerName = store.getters?.playerName;
  const playerSurname = store.getters?.playerSurname;
  const evidencePointsBossFight = store.getters?.evidencePointsBossFight;
  const selectedScenarioIndex = store.getters?.selectedScenarioIndex;
  const stakeholderOnboardStatus = store.getters?.stakeholderOnboardStatus;
  const currentLocale = store.getters?.currentLocale;

  // check if text is argument node
  if (text && text.includes('{DATA_ITEM')) {
    text = getTextFromArgument(text, currentLocale);
  }

  if (text?.includes('{SUPER_DATA_DEPENDENT_ANSWER_1}')) {
    if (evidencePointsBossFight < EVIDENCE_POINTS_FOR_DG_CHANGE_MIND) {
      text = translate('dialogueView.bossFightNotConvinced');
    } else {
      text = translate('dialogueView.bossFightConvinced');
    }
  }
  if (text && text.includes('{SCENARIO_SUPER_POINT_SPECIFIC}')) {
    text = getScenarioSuperPointSpecificText(text, selectedScenarioIndex, evidencePointsBossFight, translate);
  }
  if (text && text.includes('{SCENARIO_SUPER_POINT_SPECIFIC_WITH_POLICY}')) {
    text = getScenarioSuperPointSpecificTextAndPolicies(text, selectedScenarioIndex, evidencePointsBossFight, stakeholderOnboardStatus, translate);
  }

  // Convert to safe HTML

  const textNode = document.createTextNode(text);
  const p = document.createElement('p');

  p.appendChild(textNode);

  // make ordered list to a list

  let lines = p.innerHTML.split(/\r\n|\r|\n/g);

  let listsInText = [];

  let currentListIndex = -1;
  let currentList = -1;

  let newP = p.innerHTML;

  // make ordered lists
  for (let i = 0; i < lines.length; i++) {
    if (/\d+\. /g.test(lines[i].substring(0, 3))) {
      if(lines[i].substring(0, 3) === '1. ') {
        currentListIndex = 1;
        currentList++;
        listsInText.push(1);
      } else {
        let listVal = parseInt(lines[i][0]);
        if (listVal === currentListIndex + 1) {
          // the list continues
          listsInText[currentList] = listVal;
          currentListIndex = listVal;
        }
      }
    }
  }

  if (listsInText.length !== 0) {
    newP = newP.replace(/1\. /g, '<ol><li>');

    for (const endList of listsInText) {
      const regex = new RegExp("(" + endList + "\. .+?)\n", "g");
      newP = newP.replace(regex, (fullMatch, starMatch) => {
        return `${starMatch}</li></ol>\n`;
      });
    }

    newP = newP.replace(/\d+\. /g, '<li>');

    newP = newP.replace(/(<li>.+?)\n/g, (fullMatch, starMatch) => {
      return `${starMatch}</li>`;
    });

    newP = newP.replace(/(<\/ol>)<\/li>/g, (fullMatch, starMatch) => {
      return `${starMatch}`;
    });
  }

  // make unorderedLists
  newP = newP.replace('---_', '</ul>');
  newP = newP.replace('_---', '<ul><li>');
  newP = newP.replace(/--- /g, '<li>');

  newP = newP.replace(/(<li>.+?)\n/g, (fullMatch, starMatch) => {
    return `${starMatch}</li>`;
  });

  // insert store values
  if (playerName) {
    newP = newP.replace('[playerName]', playerName);
    if (playerSurname) {
      const fullName = `${playerName} ${playerSurname}`;
      newP = newP.replace('[playerFullName]', fullName);
    }
  }

  newP = newP.replace('{KEEP_LISTENING}', translate('misc.keepListening'))
    .replace('[SYMBOL_AT]', '@');

  newP = newP.replace(/\n/g, '<br />')
    .replace(/\*\*(.+?)\*\*/g, (fullMatch, starMatch) => `<span class="fat-text">${starMatch}</span>`)
    .replace(/\^\^(.+?)\^\^/g, (fullMatch, starMatch) => `<span class="small-space">${starMatch}</span>`)
    .replace(/__(.+?)__/g, (fullMatch, starMatch) => `<span class="cursive-text">${starMatch}</span>`);

  // make links (using [[link]])
  const regexLink = new RegExp('\\[\\[(.*?)\\]\\]', "g");
  newP = newP.replace(regexLink, (fullMatch, starMatch) => {
    return `<a href="${starMatch}" target="_blank">${starMatch}</a>`;
  });

  // make email (using {[email]})
  const regexEmail = new RegExp('\\(\\[(.*?)\\]\\)', "g");
  newP = newP.replace(regexEmail, (fullMatch, starMatch) => {
    return `<a href="mailto:${starMatch}">${starMatch}</a>`;
  });

  return newP;
}
