<template>
  <app-button v-if="!disabled" color="white" size="fit" @click="onClickButton">
    <div class="back-button">
      <div class="text-wrapper">
        <slot />
      </div>
    </div>
  </app-button>
</template>
<script>

import AppButton from "@/components/common/AppButton.vue";

export default {
  name: 'BackButton',
  components: {AppButton},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  methods: {
    onClickButton() {
      if (this.disabled) {
        return;
      }

      this.$emit('click');
    },
  },
};
</script>
<style lang="scss" scoped>
  .back-button {
    width: 35px;
    height: 35px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("./../../assets/img/ui/back-icon-filled.png");
    margin: 5px 25px 5px 25px;
  }

</style>
