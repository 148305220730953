<template>
  <div>
    <div/>
    <div id="argument-list-wrapper" class="knowledge-vault-side-panel-wrapper">
      <div class="knowledge-vault-side-panel-title-wrapper">
        <h3>{{ $t('misc.argumentsCaps') }}</h3>
        <div class="knowledge-vault-side-panel-text-wrapper">
          <p class="fat-text">{{ $t('misc.selectTwoRelevant') }}</p>
        </div>
      </div>
      <div v-for="(policyIdentification, i) in currentStrategy" :key="i">
        <div class="knowledge-vault-side-panel-text-wrapper">
          <p class="fat-text">{{ $t('misc.policy') }}</p>
          {{ getPolicyNameFromIdentification(policyIdentification) }}
          <p class="number-text fat-text" :class="dataArguments[i].length === 2 ? 'arguments-complete' : ''">{{ dataArguments[i].length }} / 2</p>
        </div>
        <ul :ref="'knowledgeVaultSide' + i" class="knowledge-vault-items-wrapper">
          <div class="drop-zone" v-if="dataArguments[i].length < 2">
            <p>{{ $t('misc.dragRelevant') }}</p>
          </div>
          <li v-for="(item, j) in argumentIndexesPerPolicyIndexCopy[i]" :key="item" class="knowledge-vault-item list-unstyled draggable" :class="getDraggableClass(item)" :itemIndex="item">
            <KnowledgeVaultItem :data-break-id="item" @click="removeArgument(item)"/>
          </li>
        </ul>
      </div>
    </div>
  </div>

</template>
<script>

import { mapState } from 'vuex';
import KnowledgeVaultItem from '@/components/KnowledgeVault/KnowledgeVaultItem';
import {Draggable, Sortable, Plugins} from '@shopify/draggable';
import { REMOVE_ARGUMENT, UPDATE_TUTORIAL_ASSISTANT_STATUS } from '@/store/mutation-types';
import strategyData from '@/data/strategyData.json';

export default {
  name: 'KnowledgeVaultSidePanel',
  components: {
    KnowledgeVaultItem,
  },
  emits: ['click'],
  data() {
    return {
      argumentIndexesPerPolicyIndexCopy: {
        0: [],
        1: [],
        2: [],
        3: [],
      },
    };
  },
  computed: {
    ...mapState([
      'selectedScenarioIndex',
      'argumentIndexesPerPolicyIndex',
      'dilemmas',
      'tutorialAssistantMessagesShown',
    ]),
    dataArguments() {
      return this.argumentIndexesPerPolicyIndex;
    },
    currentStrategy() {
      return strategyData?.[this?.selectedScenarioIndex]?.data || {};
    },
  },
  mounted() {
    this.argumentIndexesPerPolicyIndexCopy = this.argumentIndexesPerPolicyIndex;

    if (!this.tutorialAssistantMessagesShown.argumentExplanation) {
      this.$root.sendAssistantMessagesByKey('argumentExplanation');
      this.$store.commit(UPDATE_TUTORIAL_ASSISTANT_STATUS, 'argumentExplanation');
    }
  },
  methods: {
    onClickBackButton() {
      if (this.disabled) {
        return;
      }

      this.$emit('click');
    },
    getDraggableClass(i) {
      const classList = [];

      classList.push('knowledge-item-is-draggable');
      classList.push(`knowledge-item-is-draggable--item${i}`);

      return classList;
    },
    removeArgument(id) {
      this.$store.commit(REMOVE_ARGUMENT, id);
    },
    getPolicyNameFromIdentification(policyIdentification) {
      const dilemmaId = policyIdentification.dilemmaId.toString();
      const policyId = policyIdentification.policyId.toString();

      // find policyName
      const policyInDilemmas = this.dilemmas?.[dilemmaId].policyUnlocks?.[policyId];
      const policyTitle = policyInDilemmas?.title;
      return this.$t(policyTitle);
    },
  },
};
</script>
<style lang="scss" scoped>
  .knowledge-vault-side-panel-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 350px;
    background: #2C4A57;
    overflow-y: auto;
    padding-bottom: 45px;

    .knowledge-vault-side-panel-title-wrapper{
      text-align: center;
      margin-top: 25px;
    }

    .knowledge-vault-side-panel-text-wrapper {
      padding: 15px;
      text-align: left;
    }

    .number-text {
      text-align: center;
      margin-bottom: -15px;
      margin-top: 15px;
      color: #999;
    }

    .arguments-complete {
      color: #fff !important;
    }

    .knowledge-vault-items-wrapper {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 15px;
      min-height: 45px;
      padding: 0 25px 15px 25px;

      .knowledge-vault-item {
        margin: 25px 0;
      }

      .drop-zone {
        height: 140px;
        width: 100%;
        border: 3px dashed #566E79;
        text-align: center;
        padding: 15px;
        border-radius: 15px;
        display: table-cell;
        vertical-align: middle;
        color: #999;
        p {
          margin-bottom: 0;
        }
      }
    }
  }
</style>
