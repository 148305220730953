<template>
  <div class="stakeholder-button-wrapper"
       :class="'button-wrapper-' + size">
    <div class="stakeholder-button-image-wrapper">
      <div class="stakeholder-button-image-inner-wrapper">
        <StakeholderGroup v-if="stakeholders" :use-filter="false" :current-stakeholder-index="1" :stakeholders="stakeholders"/>
      </div>
    </div>
    <div v-if="showCheckMark" class="checkmark-container">
      <NoDragImage class="checkmark-container-image" :src="getImageSrcCheckmark()"/>
    </div>
    <div class="stakeholder-button-text-wrapper">
      <div class="stakeholder-title-wrapper">
        <p>{{$t(stakeholderName)}}</p>
      </div>
      <h6>{{$t(stakeholderRole)}}</h6>
    </div>
    <div v-if="showEnterButton" class="enter-meeting-button-wrapper">
      <AppButton :color="'white'" :size="'fit'" @click="onClickButton">
        {{ $t('misc.enterMeeting') }}
      </AppButton>
    </div>

  </div>
</template>
<script>

import StakeholderGroup from "@/components/Stakeholder/StakeholderGroup";
import {
  keySelectionAsCommaSeperatedString,
  keySelectionAsCommaSeperatedStringInverted
} from '@/utils/arrayUtil.js';
import NoDragImage from '@/components/common/NoDragImage';
import AppButton from '@/components/common/AppButton';

export default {
  name: 'StakeholderButton',
  props: {
    stakeholders: {
      type: Array,
      required: true,
    },
    size: {
      type: String,
      default: 'large',
    },
    showCheckMark: {
      type: Boolean,
      default: false,
    },
    showEnterButton: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AppButton,
    NoDragImage,
    StakeholderGroup,
  },
  emits: ['click'],
  computed: {
    stakeholderName() {
      return keySelectionAsCommaSeperatedStringInverted(this.stakeholders, 'name');
    },
    stakeholderRole() {
      return keySelectionAsCommaSeperatedStringInverted(this.stakeholders, 'role');
    },
  },
  methods: {
    onClickButton() {
      if (this.disabled) {
        return;
      }

      this.$emit('click');
    },
    imgSrc(imgString) {
      const images = require.context('../../assets/img/stakeholders');
      const imgSrc = images('./' + imgString + '.png');
      return imgSrc;
    },
    getImageSrcCheckmark() {
      const images = require.context('../../assets/img/ui');
      const imgSrc = images('./checkmark.png');
      return imgSrc;
    },
  },
};
</script>
<style lang="scss" scoped>
  .button-wrapper-small{
    min-width: 200px;
  }
  .button-wrapper-large {
    min-width: 400px;
  }

  .stakeholder-button-wrapper {
    margin: 10px;
    text-align: center;
    height: 100%;
    max-width: 500px;
    width: 100%;
    position: relative;

    .stakeholder-button-image-wrapper {
      height: 100%;
      width: 100%;;
      .stakeholder-button-image-inner-wrapper {
        height: 100%;
        width: 100%;
        max-width: 500px;
        position: absolute;
        bottom: -90px;
        img {
          max-height: 400px;
        }
      }
    }

    .checkmark-container {
      position: absolute;
      height: 40px;
      width: 40px;
      bottom: 70px;
      right: -27px;
      z-index: 400;

      .checkmark-container-image {
        width: 100%;
      }
    }

    .enter-meeting-button-wrapper {
      position: absolute;
      width: 100%;
      bottom: 40px;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 100;
    }

    .stakeholder-button-text-wrapper {
      position: absolute;
      bottom: 85px;
      width: 100%;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 100;
      background-color: rgba(0, 0, 0, 0.34);
      overflow: hidden;
      border-radius: 12px;

      .stakeholder-title-wrapper {
        font-weight: 700;
        background-color: rgba(0, 0, 0, 0.34);
        padding: 5px 5px 2px 5px;
      }
      p {
        margin: 0;
      }

      h6 {
        font-weight: 400;
        padding: 5px;
        margin: 0;
      }
    }
  }
</style>
