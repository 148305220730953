<template>
  <div class="row">
    <div class="knowledge-vault-container">
      <KnowledgeVaultFullPanel />
    </div>
  </div>
</template>
<script>

import BackButton from '@/components/common/BackButton';
import { mapState } from 'vuex';
import KnowledgeVaultItem from '@/components/KnowledgeVault/KnowledgeVaultItem';
import KnowledgeVaultSidePanel from "@/components/KnowledgeVault/KnowledgeVaultSidePanel";
import {Draggable, Sortable, Plugins} from '@shopify/draggable';
import KnowledgeVaultFullPanel from '@/components/KnowledgeVault/KnowledgeVaultFullPanel';

export default {
  name: 'KnowledgeVaultFullPanelDev',
  components: {
    KnowledgeVaultFullPanel,
  },
  emits: ['click'],
  methods: {
    onClickBackButton() {
      if (this.disabled) {
        return;
      }

      this.$emit('click');
    },
  },
  computed: {
    ...mapState([
      'knowledgeVaultSavedIds',
    ]),
  },
  mounted() {
    const drag = new Draggable(this.$refs.knowledgeVaultFull, {
      draggable: 'li',
    });
  },
};
</script>
<style lang="scss" scoped>
  .knowledge-vault-container {
    position: relative;
    height: 600px;
    width: 100%;
    background: #3E7784;
    padding-right: 20%;
    z-index: 10;
    overflow-y: hidden;

    .knowledge-vault-title-wrapper {
      position: absolute;
      top: 50px;
      left: 100px;
    }

    .knowledge-vault-items-wrapper {
      width: 89%;
      max-height: 100%;
      margin-left: 10px;
      overflow-y: auto;

      .knowledge-vault-item {
        margin: 10px;
      }
    }
  }
</style>
