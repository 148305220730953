<template>
  <div class="info-view-container">
    <div class="content-wrapper">
      <div class="title-wrapper">
        <p>{{ $t(title) }}</p>
      </div>
      <div class="title-wrapper">
        <p v-html="subtitleHtml" />
      </div>
      <div class="row">
        <div v-for="(input, i) in inputData" :key="i" :class="input.class" class="input-wrapper">
          <p class="hint-wrapper">
            {{ $t(input.inputHint) }}
          </p>
          <div v-if="input?.options">
            <select v-model="storeValues[input.inputStoreId]"
                    :class="isEmpty(input.inputStoreId) ? '' : 'input-filled'"
                    @change="inputValueChanged(input.inputStoreId)"
            >
              <option v-for="option in input.options" :key="option" :value="option">
                {{ $t(option) }}
              </option>
            </select>
          </div>
          <div v-if="!input?.options">
            <input v-model="storeValues[input.inputStoreId]"
                   :class="isEmpty(input.inputStoreId) ? '' : 'input-filled'"
                   :placeholder="$t(input.inputPlaceholder)"
                   @change="inputValueChanged(input.inputStoreId)"
            >
          </div>
        </div>
      </div>
      <div class="extra-text-wrapper">
        <p v-html="extraTextHtml" />
      </div>
      <!-- <div class="input-wrapper">
        <input :class="isEmpty ? '' : 'input-filled'"
               v-model="text"
               :placeholder="inputPlaceholder"
               v-on:change="inputValueChanged">
      </div>-->
    </div>
  </div>
</template>

<script>

import textToHtml from '@/utils/textToHtml';
import { mapState } from 'vuex';
import {
  SET_DYNAMIC_STORE_VALUE,
} from '@/store/mutation-types';

export default {
  name: 'TextInputView',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // right now they're all using text, make this dynamic somehow.
      text: '',
      inputValues: [],
    };
  },
  computed: {
    ...mapState([
      'playerName',
      'playerSurname',
      'playerPosition',
      'playerLocation',
    ]),
    title() {
      return this.data?.title ? this.data.title : '';
    },
    subtitle() {
      return this.data?.text ? this.data.text : '';
    },
    extraText() {
      return this.data?.extraText ? this.data.extraText : '';
    },
    titleHtml() {
      return textToHtml(this.$t(this.title), this.$t);
    },
    subtitleHtml() {
      return textToHtml(this.$t(this.subtitle), this.$t);
    },
    extraTextHtml() {
      return textToHtml(this.$t(this.extraText), this.$t);
    },
    inputData() {
      return this.data?.inputData ? this.data.inputData : [];
    },
    inputPlaceholder() {
      return this.data?.inputPlaceholder;
    },
    inputStoreId() {
      return this.data?.inputStoreId ? this.data.inputStoreId : '';
    },
    storeValues() {
      const storeValues = [];

      if (!this.inputValues) {
        return storeValues;
      }
      for (const inputValue of this.inputValues) {
        let currentValue = '';
        if (inputValue === 'playerName') {
          currentValue = this.playerName;
        } else if (inputValue === 'playerSurname') {
          currentValue = this.playerSurname;
        } else if (inputValue === 'playerPosition') {
          currentValue = this.playerPosition;
        } else if (inputValue === 'playerLocation') {
          currentValue = this.playerLocation;
        }
        storeValues[inputValue] = currentValue;
      }

      return storeValues;
    },
  },
  mounted() {
    this.inputData.forEach((obj) => {
      this.inputValues.push(obj.inputStoreId);
    });
  },
  methods: {
    inputValueChanged(storeId) {
      const newStoreData = {
        key: storeId,
        value: this.storeValues[storeId],
      };
      this.$store.commit(SET_DYNAMIC_STORE_VALUE, newStoreData);
    },
    isEmpty(storeId) {
      return this.storeValues[storeId] === '';
    },
  },
};
</script>

<style lang="scss" scoped>
.info-view-container {
  color: #ffffff;
  font-weight: 700;

  .content-wrapper {
    width: 58%;
    max-width: fit-content;
    margin-left: auto;
    padding: 0 35px 60px;
    margin-right: auto;
    margin-top: 5%;

    .title-wrapper {
      text-align: center;
      font-size: 22px;
      margin-bottom: 45px;
    }

    .extra-text-wrapper {
      text-align: left;
      font-size: 1rem;
      margin-top: 45px;
      margin-bottom: 45px;
    }

    .input-wrapper {
      position: relative;

      .hint-wrapper {
        margin-bottom: 5px;
        margin-top: 15px;
        font-weight: 400;
      }
    }

    input {
      background-color: #3B6166;
      border-color: #F2F2F2;
      border-style: solid;
      padding: 5px;
      width: 100%;
      font-family: 'Josefin Sans', sans-serif;
    }
    input::placeholder {
      color: rgba(225, 225, 225, 0.4);
    }

    .input-filled {
      background-color: #F2F2F2;
      color: #000000 !important;
    }

    select {
      background-color: #3B6166;
      border-color: #F2F2F2;
      border-style: solid;
      padding: 10px 5px;
      width: 100%;
      font-family: 'Josefin Sans', sans-serif;
    }
    select::placeholder {
      color: rgba(225, 225, 225, 0.4);
    }

    .input-filled {
      background-color: #F2F2F2;
      color: #000000 !important;
    }
  }
}

</style>
