<template>
  <div class="chosen-argument-container" :class="containerClasses">
    <div class="title-container">
      <p>{{ $t('misc.chosenArgument') }}</p>
    </div>
    <div class="data-wrapper" :class="hasChosenArgument ? '' : 'low-opacity'">
      <div class="data-title-container" :class="typeClass">
        <p>{{ chosenDataName }}</p>
      </div>
      <div class="data-content-container">
        <div class="app-icon-button-wrapper">
          <AppButton v-if="hasChosenArgument" :size="'fill'" :color="'light_grey'" @click="removeChosenArgument">
            {{ $t('misc.remove') }}
          </AppButton>
          <NoDragImage class="app-icon-button-image" :src="getImageSrc('arrow-right')"/>
        </div>
        <div class="app-icon-button-wrapper">
          <AppButton v-if="hasChosenArgument" :size="'fill'" :color="'light_grey'" @click="acceptChosenArgument">
            {{ $t('misc.accept') }}
          </AppButton>
          <NoDragImage class="app-icon-button-image" :src="getImageSrc('accept')"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import textToHtml from '@/utils/textToHtml';
import AppButton from '@/components/common/AppButton';
import NoDragImage from '@/components/common/NoDragImage';
import {
  DATA_TYPE_ECONOMIC_GROWTH, DATA_TYPE_ENVIRONMENTAL_SUSTAINABILITY,
  DATA_TYPE_FUNCTIONAL_ANALYSIS, DATA_TYPE_INCLUSIVENESS,
  DATA_TYPE_SOCIAL_SUSTAINABILITY,
} from '@/enum/DataTypes';

export default {
  name: 'ChosenArgument',
  components: {
    NoDragImage,
    AppButton,
  },
  props: {
    chosenDataName: {
      type: String,
      required: true,
    },
    useArgumentPanelOpen: {
      type: Boolean,
      required: true,
    },
    dataType: {
      type: String,
      required: true,
    },
  },
  emits: ['removeChosenArgument', 'acceptChosenArgument'],
  data() {
    return {
      bonusInfoShown: false,
      forceCloseModule: false,
    };
  },
  computed: {
    hasChosenArgument() {
      return this.chosenDataName !== '';
    },
    containerClasses() {
      const containerClasses = [];
      if (this.useArgumentPanelOpen) {
        containerClasses.push('chosen-argument-container-open');
      }
      if (!this.useArgumentPanelOpen) {
        containerClasses.push('chosen-argument-container-closed');
      }
      return containerClasses;
    },
    typeClass() {
      let buttonClass = '';

      if (this.dataType === DATA_TYPE_SOCIAL_SUSTAINABILITY) {
        buttonClass = 'data-type-social-sustain';
      } else if (this.dataType === DATA_TYPE_ECONOMIC_GROWTH) {
        buttonClass = 'data-type-economic-growth';
      } else if (this.dataType === DATA_TYPE_FUNCTIONAL_ANALYSIS) {
        buttonClass = 'data-type-functional-analysis';
      } else if (this.dataType === DATA_TYPE_ENVIRONMENTAL_SUSTAINABILITY) {
        buttonClass = 'data-type-environ-sustain';
      } else if (this.dataType === DATA_TYPE_INCLUSIVENESS) {
        buttonClass = 'data-type-inclusiveness';
      } else {
        buttonClass = 'data-type-none';
      }

      return buttonClass;
    },
  },
  methods: {
    dataTextToHtml(text) {
      return textToHtml(text, this.$t);
    },
    removeChosenArgument() {
      this.$emit('removeChosenArgument');
    },
    acceptChosenArgument() {
      this.$emit('acceptChosenArgument');
    },
    getImageSrc(key) {
      const images = require.context('../../assets/img/ui');
      const imgSrc = images('./' + key + '.png');
      return imgSrc;
    },

  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/variables.scss";

$tickSize: 25px;
$tickSizeSmall: 15px;
$tickDistanceRight: 45px;

.chosen-argument-container {
  width: 300px;
  position: absolute;
  right: 23vw;
  bottom: 0;
  padding: 10px 20px 25px 20px;
  background: linear-gradient(170deg, rgba(0,92,92,1) 8%, rgba(44,44,44,1) 100%);
  border-radius: 15px 15px 0 0;
  transition-property: transform;
  transition-duration: 350ms;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.105);

  &.chosen-argument-container-open {
    transform: translateY(0%);
  }

  &.chosen-argument-container-closed {
    transform: translateY(100%) translateX(-20vw);
  }

  .title-container {
    color: #fff;
    font-size: 22px;
    line-height: 1.2;
    width: 100%;
    padding: 10px 30px;
    position: relative;
    font-weight: 700;
    z-index: 1;
    text-align: center;
    font-family: 'Fira Sans','Fira Sans', sans-serif;

    p {
      margin: 0;
    }
  }

  .data-wrapper {
    position: relative;
    z-index: 1;
    max-height: calc(100vh - 180px);

    &.low-opacity {
      opacity: 25%;
    }

    .data-title-container {
      color: #fff;
      background-color: #263250;
      border-radius: 7px 7px 0 0;
      font-size: 16px;
      line-height: 1.2;
      width: 100%;
      margin-top: 15px;
      padding: 10px 10px;
      position: relative;
      z-index: 1;
      text-align: center;
      font-family: 'Fira Sans Condensed','Fira Sans', sans-serif;
      min-height: 40px;
      p {
        margin: 0;
      }

      &.data-type-social-sustain {
        background-color: $colorDataTypeSocSus;
      }
      &.data-type-economic-growth {
        background-color: $colorDataTypeEcoGrow;
      }
      &.data-type-functional-analysis {
        background-color: $colorDataTypeFuncAna;
      }
      &.data-type-environ-sustain {
        background-color: $colorDataTypeEnvSus;
      }
      &.data-type-inclusiveness {
        background-color: $colorDataTypeInclu;
      }
      &.data-type-none {
        background-color: #fff;
      }
    }

    .data-content-container {
      color: #5B5B5B;
      background-color: #F8F8F8;
      padding: 12px 10px 17px 10px;
      border-radius: 0 0 7px 7px;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.2;
      min-height: 125px;

      .app-icon-button-wrapper {
        position: relative;
        height: fit-content;
        width: 100%;

        .app-icon-button-image {
          position: absolute;
          z-index: 100;
          top: 50%;
          left: 5px;
          transform: translate(0, -68%);
          height: 60%;
        }
      }
    }
  }
}
</style>
