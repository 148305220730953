<template>
  <div class="final-scores-view-container d-flex">
    <div class="personal-score-wrapper">
      <h3 class="title">{{ $t('finalScoreView.title') }}</h3>
      <div class="personal-score-wrapper-inner">
        <div class="personal-score-item">
          <div class="d-flex justify-content-between">
            <h3 class="score-title">{{ $t('finalScoreView.evidenceTitle') }}</h3>
            <h3 class="score-grade">{{ $t('finalScoreView.score') }} {{ evidenceBasedScoreObject.argumentsScore }}</h3>
          </div>
          <hr>
          <p class="fat-text">{{ $t('finalScoreView.evidenceText1') }}</p>
          <ul>
            <li>{{ $t('finalScoreView.evidenceText2') }}</li>
            <li>{{ $t('finalScoreView.evidenceText3') }}</li>
          </ul>
          <p class="fat-text">{{ $t('finalScoreView.evidenceText4') }}</p>

          <div class="personal-score-border">
            <p class="fat-text">{{ evidenceBasedScoreObject.scoreArgumentsTitle }}</p>
            <p class="">{{ evidenceBasedScoreObject.scoreArgumentsText }}</p>
          </div>
        </div>
        <div class="personal-score-item">
          <div class="d-flex justify-content-between">
            <h3 class="score-title">{{ $t('finalScoreView.negotiatingTitle') }}</h3>
            <h3 class="score-grade">{{ $t('finalScoreView.score') }} {{ evidenceBasedScoreObject.negotiationScore }}</h3>
          </div>
          <hr>
          <p class="fat-text">{{ $t('finalScoreView.negotiatingText') }}</p>

          <div class="personal-score-border">
            <p class="">{{ evidenceBasedScoreObject.scoreNegotiationText }}</p>
          </div>
        </div>
        <div class="personal-score-item">
          <div class="d-flex justify-content-between">
            <h3 class="score-title">{{ $t('finalScoreView.sustainTitle') }}</h3>
            <h3 class="score-grade">{{ $t('finalScoreView.score') }} {{ sustainabilityAppreciationObject.score }}</h3>
          </div>
          <hr>
          <p class="quote-text">
            “{{ $t('finalScoreView.sustainQuote') }}”
          </p>
          <div class="ml-2 mt-1 mb-4">
            <a class="blue" href="https://policy.trade.ec.europa.eu/development-and-sustainability/sustainable-development_en#:~:text=Sustainable%20development%20means%20meeting%20the,together%20and%20support%20each%20other" target="_blank">{{ $t('finalScoreView.sustainQuoteSource') }}</a>
          </div>
          <p class="fat-text">{{ $t('finalScoreView.sustainText') }}
          </p>
          <div class="personal-score-border">
            <p class="" v-html="sustainabilityAppreciationObject.scoreTextHtml"></p>
          </div>
        </div>
        <div class="personal-score-item">
          <div class="d-flex justify-content-between">
            <h3 class="score-title">{{ $t('finalScoreView.onboardingTitle') }}</h3>
            <h3 class="score-grade">{{ $t('finalScoreView.score') }} {{ onboardingScoreTextObject.score }}</h3>
          </div>
          <hr>
          <div class="personal-score-border">
            <p>{{ onboardingScoreTextObject.scoreText }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import { createEvidenceBasedScoreFinalEvalObject, createSustainabilityFinalEvalObject, createOnboardingPointFinalEvalText } from '../../utils/finalScoreUtil';
import {
  SET_DYNAMIC_STORE_VALUE,
} from '../../store/mutation-types';
import NoDragImage from '../../components/common/NoDragImage';

export default {
  name: 'FinalScoresView',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    NoDragImage,
  },
  emits: [],
  data() {
    return {
      text: '',
      revealStarted: false,
      revealed: false,
    };
  },
  computed: {
    ...mapState([
      'evidencePointsHarvestData',
      'evidencePointsTransformData',
      'evidencePointsBossFight',
      'dealAccepted',
      'stakeholderOnboardStatus',
      'selectedScenarioIndex',
      'evidencePointsBossFight',
    ]),
    evidenceBasedScoreObject() {
      return createEvidenceBasedScoreFinalEvalObject(this.evidencePointsHarvestData, this.evidencePointsTransformData, this.evidencePointsBossFight, this.$t);
    },
    onboardingScoreTextObject() {
      return createOnboardingPointFinalEvalText(this.accumulatedStakeholderPoints, this.selectedScenarioIndex, this.dealAccepted, this.$t);
    },
    sustainabilityAppreciationObject() {
      return createSustainabilityFinalEvalObject(this.selectedScenarioIndex, this.$t);
    },
    accumulatedStakeholderPoints() {
      let accumulatedStakeholderPoints = 0;

      for (const stakeholder in this.stakeholderOnboardStatus) {
        if (this.stakeholderOnboardStatus?.[stakeholder]) {
          accumulatedStakeholderPoints += this.stakeholderOnboardStatus[stakeholder];
        }
      }

      return accumulatedStakeholderPoints;
    },
  },
  mounted() {

  },
  methods: {
    inputValueChanged() {
      const newStoreData = {
        key: this.inputStoreId,
        value: this.text,
      };
      this.$store.commit(SET_DYNAMIC_STORE_VALUE, newStoreData);
    },
  },
};
</script>

<style lang="scss" scoped>
.final-scores-view-container {
  color: #ffffff;
  font-weight: 700;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% - 110px);
  padding: 5%;

  .score-title {
    width: 70%;
  }

  .score-grade {
    width: 25%;
    text-align: right;
  }

  .evaluated-on-wrapper {
    position: relative;
    width: 30vw;
    height: 100%;
    margin-right: 15px;

    .evaluated-on-wrapper-inner {
      position: relative;
      height: 100%;

      .evaluated-on-content {
        position: relative;
        z-index: 1;
        padding: 15px;
        height: 100%;

        .evaluated-on-content-item {
          border: 1px solid #FFFFFF;
          border-radius: 12.6071px;
          margin: 15px 0;
          padding: 15px;
        }

        .evaluated-on-content-item:first-of-type {
          margin-top: 0;
        }

        .evaluated-on-content-item:last-of-type {
          margin-bottom: 0;
        }
      }

      .background-color {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-color: #0A282F;
        opacity: 0.25;
        border-radius: 12.6071px;
        z-index: 0;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }


  }

  .personal-score-wrapper {
    width: 80vw;
    margin-left: auto;
    margin-right: auto;

    .title {
      text-align: center;
    }

    .personal-score-border {
      border: #0A282F 1px solid;
      border-radius: 15px;
      padding: 18px 15px;
    }

    .personal-score-wrapper-inner {
      background: #fff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 12.6071px;
      color: #283F46;
      width: 100%;
      padding: 25px 50px;

      .personal-score-item {
        margin-bottom: 55px;

        p {
          font-weight: 400;
        }
      }

      .personal-score-item:last-of-type {
        margin-bottom: 100px;
      }
    }
  }

}

</style>
