<template>
  <div class="document-modal-wrapper">
    <div class="blackness" @click="onClickBackButton" />
    <div v-if="preventScrollAndCLick" class="prevent-scroll-and-click"/>
    <div class="document-modal" :class="shouldShiftLeft ? 'shift-left' : ''">
      <div class="back-button" @click="onClickBackButton">
        ✖
      </div>
      <div v-if="numberOfChildPages > 1" id="progress-bar-wrapper" class="progress-bar-wrapper d-flex">
        <div class="arrow-wrapper left" :class="numberOfChildPages > 1 && !isFirstChildPage ? '' : 'invisible'" @click="onClickPrevChildPage">
          <i class="arrow"/>
        </div>
        <div v-for="i in numberOfChildPages" :key="i"
             class="progress-dot"
             :class="i - 1 === currentPageIndex ? 'active' : ''"
             @click="setChildPageIndex(i -1)"
        />
        <div class="arrow-wrapper right" :class="numberOfChildPages > 1 && !isLastChildPage ? '' : 'invisible'" @click="onClickNextChildPage">
          <i class="arrow"/>
        </div>
      </div>
      <div class="document-title">
        {{ title }}
        <div v-if="isDocument6Pager" class="persistent-assistant-wrapper">
          <PersistentAssistantButton assistant-message-id="persistentStudyBriefMessage" />
        </div>
      </div>
      <div class="text-wrapper" ref="textWrapper" :class="smoothScroll ? 'smooth-scroll' : ''">
        <DocumentNewsfeed v-if="isDocumentNewsFeed" ref="documentNewsFeed" />
        <Document6Pager v-else-if="isDocument6Pager"
                        ref="document6Pager"
                        :current-page-index="currentPageIndex"
                        :is-tutorial="isTutorial"
                        @setNoScroll="setPreventScrollAndCLick(true)"
                        @scrollTextToPosition="scrollTextToPosition"/>
        <DocumentGondwanaPresentation v-else-if="isDocumentGondwanaPresentation" ref="documentGondwanaPresentation" />
        <DocumentVCA4DIntro v-else-if="isDocumentVCA4DIntro" ref="documentVCA4DIntro" :current-page-index="currentPageIndex" />
        <DocumentScenarioExplanation v-else-if="isDocumentScenarioExplanation"/>
        <DocumentGlossary v-else-if="isDocumentGlossary"/>
        <DocumentMission v-else-if="isDocumentMission" :current-page-index="currentPageIndex"/>
        <div v-else>
          Coming soon
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DOCUMENT_NEWSFEED,
  DOCUMENT_GONDWANA_PRESENTATION,
  DOCUMENT_6_PAGER,
  DOCUMENT_VCA4D_INTRO,
  DOCUMENT_GLOSSARY,
  DOCUMENT_MISSION, DOCUMENT_SCENARIO_EXPLANATION,
} from '@/enum/DocumentIds';
import DocumentNewsfeed from '@/components/DocumentModal/Documents/DocumentNewsfeed';
import Document6Pager from '@/components/DocumentModal/Documents/Document6Pager';
import DocumentGondwanaPresentation from '@/components/DocumentModal/Documents/DocumentGondwanaPresentation';
import DocumentVCA4DIntro from '@/components/DocumentModal/Documents/DocumentVCA4DIntro';
import { mapState } from 'vuex';
import DocumentMission from '@/components/DocumentModal/Documents/DocumentMission';
import DocumentScenarioExplanation from '@/components/DocumentModal/Documents/DocumentScenarioExplanation';
import { nextTick } from 'vue';
import PersistentAssistantButton from '@/components/PersistentAssistantButton/PersistentAssistantButton';
import DocumentGlossary from './Documents/DocumentGlossary';

export default {
  name: 'DocumentModal',
  components: {
    PersistentAssistantButton,
    DocumentScenarioExplanation,
    DocumentMission,
    DocumentGlossary,
    DocumentVCA4DIntro,
    DocumentGondwanaPresentation,
    Document6Pager,
    DocumentNewsfeed,
  },
  props: {
    documentId: {
      type: String,
      default: '',
    },
  },
  emits: ['closeModal'],
  data() {
    return {
      currentPageIndex: 0,
      smoothScroll: false,
      preventScrollAndCLick: false,
    };
  },
  computed: {
    ...mapState([
      'currentDilemmaId',
    ]),
    isTutorial() {
      return this.currentDilemmaId === 'INTRO2';
    },
    shouldShiftLeft() {
      return this.isDocument6Pager;
      // return this.isTutorial && this.isDocument6Pager && this.currentPageIndex !== 0;
    },
    isDocumentNewsFeed() {
      return this.documentId === DOCUMENT_NEWSFEED;
    },
    isDocument6Pager() {
      return this.documentId === DOCUMENT_6_PAGER;
    },
    isDocumentScenarioExplanation() {
      return this.documentId === DOCUMENT_SCENARIO_EXPLANATION;
    },
    isDocumentGondwanaPresentation() {
      return this.documentId === DOCUMENT_GONDWANA_PRESENTATION;
    },
    isDocumentVCA4DIntro() {
      return this.documentId === DOCUMENT_VCA4D_INTRO;
    },
    isDocumentGlossary() {
      return this.documentId === DOCUMENT_GLOSSARY;
    },
    isDocumentMission() {
      return this.documentId === DOCUMENT_MISSION;
    },
    isFirstChildPage() {
      return this.currentPageIndex === 0;
    },
    isLastChildPage() {
      return this.currentPageIndex === (this.numberOfChildPages - 1);
    },
    title() {
      let title = '';
      if (this.isDocument6Pager) {
        title = 'document.titles.studyBrief';
      } else if (this.isDocumentNewsFeed) {
        title = 'document.titles.newsfeed';
      } else if (this.isDocumentGondwanaPresentation) {
        title = 'document.titles.gondwanaPresentation';
      } else if (this.isDocumentVCA4DIntro) {
        title = 'document.titles.vca4dMethodology';
      } else if (this.isDocumentGlossary) {
        title = 'document.titles.technicalInsights';
      } else if (this.isDocumentMission) {
        title = 'document.titles.mission';
      }
      return this.$t(title);
    },
    numberOfChildPages() {
      let numberOfChildPages = 1;
      if (this.isDocumentVCA4DIntro) {
        numberOfChildPages = 2;
      } else if (this.isDocument6Pager) {
        numberOfChildPages = 6;
      } else if (this.isDocumentMission) {
        numberOfChildPages = 7;
      }
      return numberOfChildPages;
    },
  },
  methods: {
    onClickBackButton() {
      if (this.isDocument6Pager) {
        this.$refs.document6Pager.closeOpenDataPoint();
      }
      this.scrollToTop();
      this.$emit('closeModal');
      this.currentPageIndex = 0;
    },
    setPreventScrollAndCLick(val) {
      this.preventScrollAndCLick = val;
    },
    scrollTextToPosition(position) {
      this.preventScrollAndCLick = true;
      this.smoothScroll = true;

      nextTick(() => {
        const textWrapperRef = this.$refs.textWrapper;
        const textWrapperRefY = textWrapperRef.getBoundingClientRect().y;

        textWrapperRef.scrollTop = position - textWrapperRefY - 40 + textWrapperRef.scrollTop;

        nextTick(() => {
          this.smoothScroll = false;

          setTimeout(() => {
            this.preventScrollAndCLick = false;
          }, 400);
        });
      });
    },
    onClickNextChildPage() {
      if (this.isLastChildPage) {
        return;
      }
      this.currentPageIndex += 1;
      this.scrollToTop();
      this.onChildPageChange();
    },
    onClickPrevChildPage() {
      if (this.isFirstChildPage) {
        return;
      }
      this.scrollToTop();
      this.currentPageIndex -= 1;
      this.onChildPageChange();
    },
    onChildPageChange() {
      if (this.isDocument6Pager) {
        this.$refs.document6Pager.closeOpenDataPoint();
      }
    },
    setChildPageIndex(i) {
      this.currentPageIndex = i;
      this.onChildPageChange();
    },
    scrollToTop() {
      this.$refs.textWrapper.scrollTop = 0;
    },
  },
};
</script>
<style lang="scss" scoped>
$arrow-indent: 15px;

  .prevent-scroll-and-click {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 120000;
    // background-color: rgba(0, 250, 200, 0.8);
  }

  .document-modal-wrapper {
    position: absolute;
    z-index: 100000;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100vw;

    .shift-left {
      left: calc(50% - (260px / 2) - 7px) !important;

      transform: translate(-50%, -50%) !important;
    }

    .document-modal {
      position: absolute;
      transform: translate(-50%, -50%);
      z-index: 99999;
      top: 50%;
      left: 50%;
      height: fit-content;
      max-height: 90%;
      background-color: #F0F0F0;
      border-radius: 15px;
      color: #000;
      width: fit-content;
      max-width: 80%;

      transition-property: left;
      transition-duration: 350ms;
      transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.175);

      display: flex;
      flex-direction: column;

      .document-title {
        font-weight: bold;
        margin-bottom: 35px;
        padding: 26px calc(#{$arrow-indent} * 2) 0px;
        display: inline-flex;

        .persistent-assistant-wrapper {
          margin-left: 15px;
          transform: translateY(-5px);
        }
      }

      .text-wrapper {
        padding: 0px calc(#{$arrow-indent} * 2) 55px;
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;
        height: fit-content;

        &.smooth-scroll {
          scroll-behavior: smooth;
        }
      }

      .back-button {
        position: absolute;
        font-size: 1.5em;
        color: #777;
        right: 20px;
        top: 10px;
        cursor: pointer;
      }

      .progress-bar-wrapper {
        position: absolute;
        left: 50%;
        top: 30px;
        transform: translateX(-50%);
        .progress-dot {
          border: solid #2B5156 1px;
          height: 15px;
          width: 15px;
          margin: 7px 5px 5px;
          background-color: #fff;
          border-radius: 50%;
          cursor: pointer;
          &.active {
            background-color: #2B5156;
          }
        }
      }

      .arrow-wrapper {
        // background-color: #3B6166;
        top: 40%;
        cursor: pointer;
        z-index: 9999999;
        transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);

        &.invisible {
          pointer-events: none;
          cursor: default;
          opacity: 0;
        }
      }

      .arrow-wrapper:hover {
        // background-color: #2B5156;
        transform: scale(1.15);
        .arrow {
          border: solid #1B3136;
          border-width: 0 6px 6px 0;
        }
      }

      .arrow {
        border: solid #2B5156;
        border-width: 0 6px 6px 0;
        display: inline-block;
        padding: 7px;
        margin-top: 5px;
      }

      .arrow:hover {
        border: solid #2B5156;
        border-width: 0 6px 6px 0;
      }

      .right {
        right: 0;
        margin-left: 30px;
        padding: 0 10px 10px 10px;
        .arrow {
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          right: $arrow-indent;
        }
      }

      .left {
        left: 0;
        margin-right: 30px;
        padding: 0 10px 10px 10px;
        .arrow {
          transform: rotate(135deg);
          -webkit-transform: rotate(135deg);
          left: $arrow-indent;
        }

      }
    }
  }

</style>
