const intro1 = require('@/data/dilemmaINTRO1/conversation.json');
const intro2 = require('@/data/dilemmaINTRO2/conversation-person-1.json');

const dilemma1person1 = require('@/data/dilemma1/conversation-person-1.json');
const dilemma1person2 = require('@/data/dilemma1/conversation-person-2.json');
const dilemma1person3 = require('@/data/dilemma1/conversation-person-3.json');
const dilemma2person1 = require('@/data/dilemma2/conversation-person-1.json');
const dilemma2person2 = require('@/data/dilemma2/conversation-person-2.json');
const dilemma2person3 = require('@/data/dilemma2/conversation-person-3.json');
const dilemma3person1 = require('@/data/dilemma3/conversation-person-1.json');
const dilemma3person2 = require('@/data/dilemma3/conversation-person-2.json');
const dilemma3person3 = require('@/data/dilemma3/conversation-person-3.json');
const dilemma4person1 = require('@/data/dilemma4/conversation-person-1.json');
const dilemma4person2 = require('@/data/dilemma4/conversation-person-2.json');
const dilemma4person3 = require('@/data/dilemma4/conversation-person-3.json');

const ipdLars = require('@/data/dilemmaINFORMALLARS/conversation.json');
const ipdSecretary = require('@/data/dilemmaINFORMALSECRETARY/conversation.json');
const ipdRuralDG = require('@/data/dilemmaINFORMALRURALDG/conversation.json');
const phase4step1 = require('@/data/dilemmaPHASE4STEP1/conversation.json');
const phase5step1 = require('@/data/dilemmaPHASE5STEP1/conversation.json');
const phase5step2option1 = require('@/data/dilemmaPHASE5STEP2OPTION1/conversation.json');
const phase5step2option2 = require('@/data/dilemmaPHASE5STEP2OPTION2/conversation.json');
const phase5step3 = require('@/data/dilemmaPHASE5STEP3/conversation.json');

export const CONVERSATIONS = {
  INTRO1: intro1,
  INTRO2: intro2,
  ipdLars: ipdLars,
  ipdSecretary: ipdSecretary,
  ipdRuralDG: ipdRuralDG,
  phase4step1: phase4step1,
  phase5step1: phase5step1,
  phase5step2option1: phase5step2option1,
  phase5step2option2: phase5step2option2,
  phase5step3: phase5step3,
  dilemmas: {
    1: {
      stakeholders: {
        0: dilemma1person1,
        1: dilemma1person2,
        2: dilemma1person3,
      },
    },
    2: {
      stakeholders: {
        0: dilemma2person1,
        1: dilemma2person2,
        2: dilemma2person3,
      },
    },
    3: {
      stakeholders: {
        0: dilemma3person1,
        1: dilemma3person2,
        2: dilemma3person3,
      },
    },
    4: {
      stakeholders: {
        0: dilemma4person1,
        1: dilemma4person2,
        2: dilemma4person3,
      },
    },
  },
};
